import { Row, Col } from "react-bootstrap";

const GroupEssentials = ({ group }) => {
  const getValueOrPlaceholder = (value) => {
    return value ? value : "-";
  };

  return (
    <>
      <Row>
        <Col xs={4} md={3} className="information-card-type">
          <p>Name:</p>
        </Col>
        <Col xs={8} md={9}>
          <p>{group.name}</p>
        </Col>
      </Row>

      <Row>
        <Col xs={4} md={3} className="information-card-type">
          <p>Description:</p>
        </Col>
        <Col xs={8} md={9}>
          <p>{getValueOrPlaceholder(group.description)}</p>
        </Col>
      </Row>

      <Row>
        <Col xs={4} md={3} className="information-card-type">
          <p>Group Size:</p>
        </Col>
        <Col xs={8} md={9}>
          <p>{getValueOrPlaceholder(group.size)}</p>
        </Col>
      </Row>

      <Row>
        <Col xs={4} md={3} className="information-card-type">
          <p>Location:</p>
        </Col>
        <Col xs={8} md={9}>
          <p>{getValueOrPlaceholder(group.location)}</p>
        </Col>
      </Row>
    </>
  );
};

export default GroupEssentials;

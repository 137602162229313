import { Row, Col, Table } from "react-bootstrap";
import { DateTime } from "luxon";
import { getAllSessionDates } from "../../utils/utils";

const getSessionStatus = (sessionDate) => {
  const now = DateTime.now();
  return DateTime.fromISO(sessionDate) < now ? "Past" : "Upcoming";
};

const formatSessionDate = (sessionDate) => {
  const startDateTime = DateTime.fromISO(sessionDate).setZone("UTC");
  return startDateTime.toFormat("cccc, LLLL dd");
};

const formatSessionTime = (sessionDate, duration) => {
  const startDateTime = DateTime.fromISO(sessionDate).setZone("UTC");
  const endDateTime = startDateTime.plus({ minutes: duration });
  return `${startDateTime.toFormat("h:mm a")} - ${endDateTime.toFormat("h:mm a")}`;
};

const SessionInformation = ({ group }) => {
  const groupReadyForDisplay =
    group.firstSessionDate &&
    group.recurrence.days.length >= 1 &&
    group.sessionCount > 0 &&
    group.sessionTime;
  let allSessionDates = [];

  if (groupReadyForDisplay) {
    allSessionDates = getAllSessionDates(
      group.firstSessionDate,
      group.sessionTime,
      group.recurrence,
      group.sessionCount,
    );
  }

  return (
    <>
      {groupReadyForDisplay ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Session #</th>
              <th>Date</th>
              <th>Time</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {allSessionDates.map((sessionDate, index) => (
              <tr key={index}>
                <td>{`Session ${index + 1}`}</td>
                <td>{formatSessionDate(sessionDate)}</td>
                <td>{formatSessionTime(sessionDate, group.duration)}</td>
                <td>{getSessionStatus(sessionDate)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Session #</th>
              <th>Date</th>
              <th>Time</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
          </tbody>
        </Table>
      )}
      <Row>
        <Col xs={4} className="information-card-type">
          <p>Session Fee (per Member):</p>
        </Col>
        <Col xs={8}>
          <p>{group.pricePerSession ? `$${group.pricePerSession}` : "-"}</p>
        </Col>
      </Row>
    </>
  );
};

export default SessionInformation;

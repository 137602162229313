import { useState } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import StatusLabel from "../shared/StatusLabel";
import ApprovalModal from "./modals/ApprovalModal";

const LocationCard = ({ location, onEdit, onDelete, compactView = false }) => {
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [error, setError] = useState(null);

  const handleDelete = async () => {
    try {
      await onDelete(location);
      setShowApprovalModal(false);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Col xs={9}>
              <Card.Title>{location.label}</Card.Title>
              {!compactView && <p>{location.address}</p>}
              <StatusLabel status={location.type} viewType="grid" />
            </Col>
            {onEdit && (
              <Col
                xs={3}
                className="d-flex align-items-center justify-content-end"
              >
                <Button
                  className="secondary-button pill-button"
                  onClick={() => setShowApprovalModal(true)}
                >
                  Delete
                </Button>
                {location.label !== "Zoom" && (
                  <Button
                    className="primary-button pill-button"
                    style={{ marginLeft: "10px" }}
                    onClick={() => onEdit(location)}
                  >
                    Edit
                  </Button>
                )}
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
      <ApprovalModal
        show={showApprovalModal}
        onHide={() => setShowApprovalModal(false)}
        header="Confirm Delete"
        body={`You are about to delete the location "${location.label}".`}
        handleAction={handleDelete}
        action="Delete"
        error={error}
      />
    </>
  );
};

export default LocationCard;

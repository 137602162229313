import {
  Container,
  Button,
  Modal,
  Spinner,
  Dropdown,
  DropdownButton,
  Form,
  Alert,
  Row,
  Col,
  Badge,
} from "react-bootstrap";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import MoveModal from "./MoveModal.js";
import {
  requestRejectionMessagesTR,
  requestRejectionMessagesEN,
} from "../../utils/requestRejectionMessages.js";
import {
  rescheduleConsultation,
  approveRequest,
  rejectRequest,
  sendAvailabilityCalendar,
  confirmConsultation,
} from "../../services/api.js";
import {
  formatTimeFromDateToHMMaa,
  formatDateToAmerican,
} from "../../utils/utils.js";

const RequestDetail = ({
  selectedRequest,
  selectedGroup,
  therapist,
  timeSlot,
  questionnaire,
}) => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [showConsultationModal, setShowConsultationModal] = useState(false);
  const [showRescheduleConsultationModal, setShowRescheduleConsultationModal] =
    useState(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("Select a reason");
  const [rejectionReasonText, setRejectionReasonText] = useState("");
  const [showMoveModal, setShowMoveModal] = useState(false);

  const [showActionButton, setShowActionButton] = useState(false);
  const [subStatus, setSubStatus] = useState("");
  const [subStatusClass, setSubStatusClass] = useState("warning");
  const [canMove, setCanMove] = useState(false);
  const [canDecide, setCanDecide] = useState(false);
  const [canScheduleConsultation, setCanScheduleConsultation] = useState(false);
  const [canResendConsultation, setCanResendConsultation] = useState(false);
  const [canReScheduleConsultation, setCanReScheduleConsultation] =
    useState(false);
  const [isManuallyCreated, setIsManuallyCreated] = useState(false);
  const [consultationMessage, setConsultationMessage] = useState("");

  useEffect(() => {
    if (!selectedRequest) return;

    const newState = {
      showActionButton: false,
      showConsultationInfo: false,
      subStatus: "",
      subStatusClass: "white",
      canMove: false,
      canDecide: false,
      canScheduleConsultation: false,
      canResendConsultation: false,
      canReScheduleConsultation: false,
      isManuallyCreated: false,
      consultationMessage: "",
    };

    const formatDateTime = (date) => {
      return `${formatDateToAmerican(date)} at ${formatTimeFromDateToHMMaa(date)}`;
    };

    switch (selectedRequest.status) {
      case "PENDING":
        newState.showActionButton = true;
        newState.canMove = true;
        newState.canDecide = true;
        switch (selectedRequest.subStatus) {
          case "AWAITING_THERAPIST_RESPONSE":
            newState.canScheduleConsultation = true;
            newState.subStatusClass = "dark";
            newState.subStatus = "AWAITING YOUR RESPONSE";
            break;
          case "AWAITING_CONSULTATION_RESPONSE":
            newState.subStatusClass = "dark";
            newState.subStatus = "AWAITING YOUR RESPONSE";
            newState.consultationMessage = `The Consultation session was scheduled for ${formatDateTime(timeSlot.startTime)}`;
            break;
          case "AWAITING_THERAPIST_DECISION":
            newState.subStatusClass = "secondary";
            newState.subStatus = "AWAITING YOUR DECISION";
            if (timeSlot) {
              switch (timeSlot.status) {
                case "COMPLETED":
                  newState.consultationMessage = `Consultation session completed on ${formatDateTime(timeSlot.startTime)}`;
                  break;
                case "NOT_COMPLETED":
                  newState.canScheduleConsultation = true;
                  newState.consultationMessage = `The consultation session was supposed to take place on ${formatDateTime(timeSlot.startTime)} but it did not.`;
                  break;
                default:
                  newState.consultationMessage =
                    "Status of the consultation session is unknown.";
              }
            } else {
              newState.consultationMessage =
                "No consultation session data available.";
            }
            break;
          case "AWAITING_CLIENT_RESPONSE":
            newState.canResendConsultation = true;
            newState.subStatusClass = "warning";
            newState.subStatus = "AWAITING CLIENT RESPONSE";
            if (selectedRequest.availabilityCalendarLastSent) {
              newState.consultationMessage = `Sent consultation availability calendar to the client on ${formatDateTime(selectedRequest.availabilityCalendarLastSent)}`;
            } else {
              newState.consultationMessage =
                "Availability calendar has not been sent yet.";
            }
            break;
          case "CONSULTATION_SCHEDULED":
            newState.canReScheduleConsultation = true;
            newState.subStatusClass = "success";
            newState.subStatus = "CONSULTATION SCHEDULED";
            newState.consultationMessage = `Consultation session scheduled for ${formatDateTime(timeSlot.startTime)}`;
            break;
          case "AWAITING_CLIENT_DECISION":
            newState.canDecide = false;
            newState.subStatusClass = "info";
            newState.subStatus = "AWAITING CLIENT DECISION";
            break;
          default:
            newState.subStatusClass = "white";
            newState.subStatus = "N/A";
        }
        break;
      case "WAITLISTED":
        newState.showActionButton = true;
        newState.canMove = true;
        newState.subStatusClass = "secondary";
        newState.subStatus = "WAITLISTED";
        break;
      case "APPROVED":
        newState.isManuallyCreated = !selectedRequest.birthDate;
        newState.subStatusClass = "success";
        newState.subStatus = "APPROVED";
        break;
      case "REJECTED":
        newState.subStatusClass = "danger";
        newState.subStatus = "REJECTED";
        break;
      case "WITHDRAWN":
        newState.subStatusClass = "secondary";
        newState.subStatus = "WITHDRAWN";
        break;
      default:
        newState.subStatusClass = "white";
        newState.subStatus = "N/A";
    }

    setShowActionButton(newState.showActionButton);
    setCanScheduleConsultation(newState.canScheduleConsultation);
    setCanResendConsultation(newState.canResendConsultation);
    setCanReScheduleConsultation(newState.canReScheduleConsultation);
    setCanMove(newState.canMove);
    setCanDecide(newState.canDecide);
    setSubStatusClass(newState.subStatusClass);
    setSubStatus(newState.subStatus);
    setIsManuallyCreated(newState.isManuallyCreated);
    setConsultationMessage(newState.consultationMessage);
  }, [selectedRequest, timeSlot]);

  const getStatusMessage = (status, updatedAt) => {
    const formattedDate = formatDateToAmerican(updatedAt);
    const formattedTime = formatTimeFromDateToHMMaa(updatedAt);

    switch (status) {
      case "APPROVED":
        return `You approved this request on ${formattedDate} ${formattedTime}`;
      case "REJECTED":
        return `You rejected this request on ${formattedDate} ${formattedTime}`;
      default:
        return `You added this member to the group manually on ${formattedDate} ${formattedTime}`;
    }
  };

  const renderAnswers = () => {
    return questionnaire.map((item) => {
      return (
        <div key={item.questionText}>
          <h6 className="mt-3" style={{ marginBottom: "0px" }}>
            {item.questionText}
          </h6>
          <p className="text-secondary">{item.answer}</p>
        </div>
      );
    });
  };

  const handleBackButton = () => {
    navigate(`/admin/groups/${selectedGroup._id}`);
  };

  /* WAITLIST / MOVE */

  const handleMoveButton = () => {
    setShowMoveModal(true);
  };

  const handleCloseMoveModal = () => {
    setShowMoveModal(false);
  };

  /* CONSULTATION */

  const handleScheduleButton = () => {
    setShowConsultationModal(true);
  };

  const handleCloseConsultationModal = () => {
    setShowConsultationModal(false);
  };

  const handleSendAvailabilityButton = async () => {
    setIsProcessing(true);
    try {
      await sendAvailabilityCalendar(selectedRequest._id);
    } catch (error) {
      console.log("Error sending availability calendar to the client");
    }
    navigate(0);
  };

  const handleSaveAvailabilityButton = async () => {
    setShowConsultationModal(false);
  };

  const handleRescheduleButton = () => {
    setShowRescheduleConsultationModal(true);
  };

  const handleCloseRescheduleConsultationModal = () => {
    setShowRescheduleConsultationModal(false);
  };

  const handleRescheduleConsultation = async () => {
    setIsProcessing(true);
    let newRequestId = selectedRequest._id;
    try {
      const response = await rescheduleConsultation(selectedRequest._id);
      newRequestId = response.newRequestId;
    } catch (error) {
      console.log("Error canceling consultation");
    }
    setIsProcessing(false);
    setShowRescheduleConsultationModal(false);
    navigate(`/admin/groups/${selectedGroup._id}/request/${newRequestId}`);
  };

  const handleYesButton = async () => {
    try {
      await confirmConsultation(selectedRequest._id, true);
    } catch (error) {
      console.log("Error confirming consultation");
    }
    navigate(0);
  };

  const handleNoButton = async () => {
    try {
      await confirmConsultation(selectedRequest._id, false);
    } catch (error) {
      console.log("Error disputing consultation");
    }
    navigate(0);
  };

  /* APPROVAL */

  const handleApproveButton = () => {
    setShowApprovalModal(true);
  };

  const handleCloseApprovalModal = () => {
    setShowApprovalModal(false);
  };

  const handleApproval = async () => {
    setIsProcessing(true);
    try {
      await approveRequest(selectedRequest._id);
      navigate(
        `/admin/groups/${selectedGroup._id}/request/${selectedRequest._id}`,
      );
    } catch {
      console.log("Error updating request status");
    }
    setIsProcessing(false);
    setShowApprovalModal(false);
  };

  /* REJECTION */

  const handleRejectButton = () => {
    setShowRejectionModal(true);
  };

  const handleCloseRejectionModal = () => {
    setShowRejectionModal(false);
  };

  const handleRejectionDropdown = (eventKey) => {
    setRejectionReason(eventKey);
    setError("");
    let requestRejectionMessages;

    if (selectedGroup.language === `tr_TR`) {
      requestRejectionMessages = requestRejectionMessagesTR;
    } else {
      requestRejectionMessages = requestRejectionMessagesEN;
    }

    if (requestRejectionMessages[eventKey]) {
      const messageFunction = requestRejectionMessages[eventKey];
      const message = messageFunction({
        selectedRequest,
        therapist,
        selectedGroup,
      });
      setRejectionReasonText(message);
    }
  };

  const handleRejection = async () => {
    if (!rejectionReasonText) {
      setError("Please provide a message");
      return;
    }

    setIsProcessing(true);
    let newRequestId = selectedRequest._id;
    try {
      const response = await rejectRequest(
        selectedRequest._id,
        rejectionReasonText,
        rejectionReason === "Fake Request",
      );
      newRequestId = response.newRequestId;
      navigate(`/admin/groups/${selectedGroup._id}/request/${newRequestId}`);
    } catch {
      console.log("Error updating request status");
    }
    setIsProcessing(false);
    setShowRejectionModal(false);
  };

  return (
    <>
      <Container className="settings-header-container" fluid>
        <Row
          className="d-flex justify-content-between align-items-center px-4"
          style={{ height: "7rem" }}
        >
          <Col xs={8}>
            <div className="d-flex">
              <h4 style={{ marginBottom: "0px" }}>
                {selectedRequest.firstName} {selectedRequest.lastName}
              </h4>
              <Badge
                bg={`${subStatusClass}`}
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  height: "20px",
                  marginLeft: "10px",
                }}
              >
                {subStatus}
              </Badge>
            </div>
            <p style={{ marginBottom: "0px" }} className="text-secondary">
              {selectedRequest.emailAddress}
            </p>
            <p style={{ marginBottom: "0px" }} className="text-secondary">
              {formatPhoneNumberIntl(selectedRequest.phoneNumber)}
            </p>
          </Col>
          <Col
            xs={4}
            className="d-flex align-items-center justify-content-end"
            style={{ paddingRight: "0" }}
          >
            <Button
              className="secondary-button pill-button"
              onClick={handleBackButton}
            >
              Back
            </Button>
            {showActionButton && (
              <DropdownButton
                id="request-action-dropdown"
                variant="dark"
                title="Actions"
              >
                {canMove && (
                  <Dropdown.Item onClick={handleMoveButton}>
                    Move to another group or waitlist
                  </Dropdown.Item>
                )}
                {canScheduleConsultation && (
                  <Dropdown.Item onClick={handleScheduleButton}>
                    Invite for a 15-min Consultation
                  </Dropdown.Item>
                )}
                {canResendConsultation && (
                  <Dropdown.Item onClick={handleScheduleButton}>
                    Resend the invitation link
                  </Dropdown.Item>
                )}
                {canReScheduleConsultation && (
                  <Dropdown.Item onClick={handleRescheduleButton}>
                    Reschedule consultation
                  </Dropdown.Item>
                )}
                {canDecide && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      onClick={handleApproveButton}
                      style={{ fontWeight: "500", color: "var(--bs-success)" }}
                    >
                      Approve Request
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={handleRejectButton}
                      style={{ fontWeight: "500", color: "var(--bs-danger)" }}
                    >
                      Reject Request
                    </Dropdown.Item>
                  </>
                )}
              </DropdownButton>
            )}
          </Col>
        </Row>
        <hr style={{ marginTop: "0px" }} />
      </Container>
      <Container className="main-container">
        {therapist.timeZone !== selectedRequest.timeZone && (
          <div>
            <h6 className="mt-3" style={{ marginBottom: "0px" }}>
              Client Time Zone
            </h6>
            <p className="text-secondary">{selectedRequest.timeZone}</p>
          </div>
        )}
        {isManuallyCreated ? (
          <div>
            <h6 className="mt-5">
              {getStatusMessage("MANUALLY_CREATED", selectedRequest.updatedAt)}
            </h6>
          </div>
        ) : (
          <>
            <div>
              <h6 className="mt-3" style={{ marginBottom: "0px" }}>
                Client Date of Birth
              </h6>
              <p className="text-secondary">{selectedRequest.birthDate}</p>
            </div>
            {renderAnswers()}
            {["APPROVED", "REJECTED"].includes(selectedRequest.status) && (
              <div>
                <h6 className="mt-5">
                  {getStatusMessage(
                    selectedRequest.status,
                    selectedRequest.updatedAt,
                  )}
                </h6>
              </div>
            )}
          </>
        )}

        {selectedRequest.status === "PENDING" && (
          <div className="mt-5">
            {consultationMessage && (
              <Alert variant="light">
                {consultationMessage}
                {selectedRequest.subStatus ===
                  "AWAITING_CONSULTATION_RESPONSE" && (
                  <>
                    <div className="d-flex align-items-center mt-3">
                      <p style={{ margin: "auto 0" }}>
                        Did the consultation session take place?
                      </p>
                      <Button
                        variant="dark"
                        onClick={handleYesButton}
                        style={{ width: "80px", marginLeft: "20px" }}
                      >
                        Yes
                      </Button>
                      <Button
                        variant="outline-dark"
                        onClick={handleNoButton}
                        style={{ width: "80px", marginLeft: "20px" }}
                      >
                        No
                      </Button>
                    </div>
                    <p style={{ marginTop: "5px", fontSize: "12px" }}>
                      Note that if your consultation session did not take place,
                      you will have the opportunity to schedule a new one after
                      making your selection.
                    </p>
                  </>
                )}
              </Alert>
            )}
          </div>
        )}
      </Container>
      {showMoveModal && (
        <MoveModal
          show={showMoveModal}
          onHide={handleCloseMoveModal}
          request={selectedRequest}
          currentGroupId={selectedGroup._id}
        />
      )}
      {showApprovalModal && (
        <Modal show={showApprovalModal} onHide={handleCloseApprovalModal}>
          <Modal.Header closeButton>
            <Modal.Title>Approve Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Upon approval of this request, the client will receive a welcome
            email along with a calendar invitation containing the location of
            this group.
            <br />
            <br />
            Are you sure you want to approve this request?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseApprovalModal}>
              Cancel
            </Button>
            <Button variant="success" onClick={handleApproval}>
              {isProcessing ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {" Approving"}
                </>
              ) : (
                "Approve"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {showRejectionModal && (
        <Modal show={showRejectionModal} onHide={handleCloseRejectionModal}>
          <Modal.Header closeButton>
            <Modal.Title>Reject Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Please select a rejection reason this request:
            <DropdownButton
              size="sm"
              variant="secondary"
              className="mt-3"
              id="rejection-reason-dropdown"
              title={rejectionReason}
              onSelect={handleRejectionDropdown}
            >
              <Dropdown.Item eventKey="Group Full">Group Full</Dropdown.Item>
              <Dropdown.Item eventKey="Client not compatible">
                Client not compatible
              </Dropdown.Item>
              <Dropdown.Item eventKey="Fake Request">
                Fake Request
              </Dropdown.Item>
              <Dropdown.Item eventKey="Other">Other</Dropdown.Item>
            </DropdownButton>
            {rejectionReason !== "Select a reason" && (
              <>
                <Form>
                  <Form.Group className="mb-3 mt-3">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={15}
                      value={`${rejectionReasonText.replace(/<br\/>/g, "\n")}`}
                      onChange={(e) => setRejectionReasonText(e.target.value)}
                      disabled={rejectionReason === "Fake Request"}
                    />
                  </Form.Group>
                </Form>
                {error && (
                  <Alert className="mt-2" variant="danger">
                    {error}
                  </Alert>
                )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseRejectionModal}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={handleRejection}
              disabled={rejectionReason === "Select a reason"}
            >
              {isProcessing ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {" Rejecting"}
                </>
              ) : (
                "Reject"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {showConsultationModal &&
        (therapist.availability ? (
          <Modal
            show={showConsultationModal}
            onHide={handleCloseConsultationModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Send Consultation Invite</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              When you send the client a consultation invitation, they will
              receive a unique link, using which they will be able to view your
              availability calendar and book one of your available 15-min time
              slots.
              <br />
              <br />
              Are you sure you want to continue with sending a consultation
              invite to this client?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleCloseConsultationModal}
              >
                Cancel
              </Button>
              <Button variant="dark" onClick={handleSendAvailabilityButton}>
                {isProcessing ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    {" Sending"}
                  </>
                ) : (
                  "Send"
                )}
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          <Modal
            show={showConsultationModal}
            onHide={handleCloseConsultationModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Set Availability</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              You need to set up your 15-min consultation session availability
              calendar before sending an invitation link to the client.
              <br />
              <br />
              You can{" "}
              <Link to="/admin/settings/availability">
                visit your setup page
              </Link>{" "}
              at any time to edit your availability.
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleCloseConsultationModal}
              >
                Cancel
              </Button>
              <Button variant="dark" onClick={handleSaveAvailabilityButton}>
                {isProcessing ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    {" Saving"}
                  </>
                ) : (
                  "Save"
                )}
              </Button>
            </Modal.Footer>
          </Modal>
        ))}
      {showRescheduleConsultationModal && (
        <Modal
          show={showRescheduleConsultationModal}
          onHide={handleCloseRescheduleConsultationModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Reschedule Consultation</Modal.Title>
          </Modal.Header>
          {timeSlot.status === "NOT_COMPLETED" ? (
            <Modal.Body>
              An invitation email will be sent to the client to select a time
              slot for a new consultation session.
              <br />
              <br />
              View your current availability calendar{" "}
              <Link to="/admin/settings/availability">here</Link>.
            </Modal.Body>
          ) : (
            <Modal.Body>
              Your consultation session with the client will be canceled, and
              this time slot will be blocked to prevent further bookings. An
              invitation email will be resent to the client, allowing them to
              select a new time slot.
              <br />
              <br />
              View your current availability calendar{" "}
              <Link to="/admin/settings/availability">here</Link>.
            </Modal.Body>
          )}

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleCloseRescheduleConsultationModal}
            >
              Cancel
            </Button>
            <Button variant="dark" onClick={handleRescheduleConsultation}>
              {isProcessing ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {" Rescheduling"}
                </>
              ) : (
                "Reschedule"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default RequestDetail;

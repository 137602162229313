import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000/api"
    : "https://app.chaircircles.com/api";

const api = axios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use(
  async (config) => {
    const user = firebase.auth().currentUser;
    if (user) {
      const token = await user.getIdToken(true);
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers.Accept = "application/json";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

/***** V2 */

/***** AUTH Functions */

export const checkTherapistExists = async () => {
  try {
    const response = await api.get("/auth/therapists");
    return response.data;
  } catch (error) {
    console.error(
      "Error checking if the therapist exists",
      error.response?.data?.error,
    );
    throw error;
  }
};

export const verifyEmail = async (token) => {
  try {
    const response = await api.get(`auth/verify-email?token=${token}`);
    return response.data;
  } catch (error) {
    console.error("Error validating email", error.response?.data?.error);
    throw error;
  }
};

export const sendVerificationEmail = async () => {
  try {
    const response = await api.post(`auth/send-verification-email`);
    return response.data;
  } catch (error) {
    console.error(
      "Error sending verification email",
      error.response?.data?.error,
    );
    throw error;
  }
};

/***** ZOOM Functions */

export const initiateZoomOAuth = async () => {
  try {
    const response = await api.get("/admin/zoom/oauth/initiate");
    return response.data;
  } catch (error) {
    console.error(
      "Error initiating zoom oauth flow",
      error.response?.data?.error,
    );
    throw error;
  }
};

export const completeZoomOAuth = async (code, state) => {
  try {
    const response = await api.get(
      `admin/zoom/oauth/callback?code=${code}&state=${state}`,
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error initiating zoom oauth flow",
      error.response?.data?.error,
    );
    throw error;
  }
};

export const deleteZoomConnection = async () => {
  try {
    const response = await api.delete(`admin/zoom/disconnect`);
    return response.data;
  } catch (error) {
    console.error("Error disconnecting from Zoom", error.response?.data?.error);
    throw error;
  }
};

/***** ADMIN Functions */

export const getTherapistDetails = async ({
  includeQuestions = false,
} = {}) => {
  try {
    const queryParams = includeQuestions ? "?includeQuestions=true" : "";
    const response = await api.get(`/admin/therapists${queryParams}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching details of the therapist",
      error.response?.data?.error,
    );
    throw error;
  }
};

export const getAllGroupsOfTherapist = async () => {
  try {
    const response = await api.get("/admin/groups");
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching groups of the therapist",
      error.response?.data?.error,
    );
    throw error;
  }
};

export const postDraft = async (data) => {
  try {
    const response = await api.post("/admin/groups/draft", data);
    return response.data;
  } catch (error) {
    console.error("Error creating draft", error.response?.data?.error);
    throw error;
  }
};

export const updateDraft = async (groupId, data) => {
  try {
    const response = await api.put(`/admin/groups/${groupId}/draft`, data);
    return response.data;
  } catch (error) {
    console.error("Error creating draft", error.response?.data?.error);
    throw error;
  }
};

export const getGroupDetails = async ({
  groupId,
  includeRequests = false,
  includeQuestions = false,
} = {}) => {
  try {
    const queryParams = new URLSearchParams();

    if (includeRequests) {
      queryParams.append("includeRequests", "true");
    }

    if (includeQuestions) {
      queryParams.append("includeQuestions", "true");
    }

    const queryString = queryParams.toString()
      ? `?${queryParams.toString()}`
      : "";
    const response = await api.get(`/admin/groups/${groupId}${queryString}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching group details", error.response?.data?.error);
    throw error;
  }
};

export const getRequestDetails = async (requestId) => {
  try {
    const response = await api.get(`/admin/requests/${requestId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching request details",
      error.response?.data?.error,
    );
    throw error;
  }
};

export const sendAvailabilityCalendar = async (requestId) => {
  try {
    const response = await api.post(
      `/admin/requests/${requestId}/consultation`,
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error sending availability calendar to the request",
      error.response?.data?.error,
    );
    throw error;
  }
};

export const rescheduleConsultation = async (requestId) => {
  try {
    const response = await api.post(
      `/admin/requests/${requestId}/consultation/reschedule`,
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching request details",
      error.response?.data?.error,
    );
    throw error;
  }
};

export const confirmConsultation = async (requestId, isConfirmed) => {
  try {
    const data = {
      confirmed: isConfirmed,
    };
    const response = await api.post(
      `/admin/requests/${requestId}/consultation/confirmation`,
      data,
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching request details",
      error.response?.data?.error,
    );
    throw error;
  }
};

export const approveRequest = async (requestId) => {
  try {
    const response = await api.post(`/admin/requests/${requestId}/approve`);
    return response.data;
  } catch (error) {
    console.error(
      "Error approving selected request",
      error.response?.data?.error,
    );
    throw error;
  }
};

export const rejectRequest = async (requestId, text, isFake) => {
  try {
    const data = {
      text,
      isFake,
    };
    const response = await api.post(
      `/admin/requests/${requestId}/reject`,
      data,
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error rejecting selected request",
      error.response?.data?.error,
    );
    throw error;
  }
};

export const sendEmailToSelectedRequest = async (requestId, message) => {
  try {
    const data = {
      message,
    };
    const response = await api.post(`/admin/requests/${requestId}/email`, data);
    return response.data;
  } catch (error) {
    console.error("Error sending email", error.response?.data?.error);
    throw error;
  }
};

export const sendEmailToAllRequestsByStatus = async (
  groupId,
  status,
  message,
) => {
  try {
    const data = {
      message,
    };
    const response = await api.post(
      `/admin/requests/groups/${groupId}/email?status=${status}`,
      data,
    );
    return response.data;
  } catch (error) {
    console.error("Error sending email", error.response?.data?.error);
    throw error;
  }
};

export const addNewApprovedMember = async (data) => {
  try {
    const response = await api.post(`/admin/requests/approved`, data);
    return response.data;
  } catch (error) {
    console.error(
      "Error adding new member to group",
      error.response?.data?.error,
    );
    throw error;
  }
};

export const getMoveableGroups = async () => {
  try {
    const response = await api.get(`/admin/groups/moveable`);
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching moveable groups details",
      error.response?.data?.error,
    );
    throw error;
  }
};

export const moveToWaitlist = async (
  requestId,
  groupId,
  skipSendingNotification = false,
) => {
  try {
    const queryParams = skipSendingNotification
      ? `?skipNotification=${skipSendingNotification}`
      : "";
    const data = {
      newGroupId: groupId,
    };
    const response = await api.post(
      `/admin/requests/${requestId}/move${queryParams}`,
      data,
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching moveable groups details",
      error.response?.data?.error,
    );
    throw error;
  }
};

export const editPublishedGroup = async (groupId, field, value) => {
  try {
    const data = {
      field: field,
      value: value,
    };
    const response = await api.patch(`/admin/groups/${groupId}`, data);
    return response.data;
  } catch (error) {
    console.error(
      "Error updating published group",
      error.response?.data?.error,
    );
    throw error;
  }
};

export const deleteTimeSlot = async (timeSlotId) => {
  try {
    const response = await api.delete(`/admin/timeslots/${timeSlotId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting selected Time Slot",
      error.response?.data?.error,
    );
    throw error;
  }
};

export const getTimeSlots = async () => {
  try {
    const response = await api.get(`/admin/timeslots`);
    return response.data;
  } catch (error) {
    console.error("Error fetching timeslots", error.response?.data?.error);
    throw error;
  }
};

export const getGroupEventsForCalendar = async () => {
  try {
    const response = await api.get(`/admin/groups/calendar`);
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching groups for calendar",
      error.response?.data?.error,
    );
    throw error;
  }
};

export const setTherapistAvailability = async (availability, dailyLimit) => {
  try {
    const data = {
      availability: availability,
      dailyConsultationLimit: dailyLimit,
    };
    const response = await api.post(`/admin/therapists/availability`, data);
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching groups for calendar",
      error.response?.data?.error,
    );
    throw error;
  }
};

export const deleteMultipleTimeSlots = async (type = "", date = "") => {
  try {
    const response = await api.delete(
      `/admin/timeslots?removalType=${type}&date=${date}`,
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting multiple timeslots",
      error.response?.data?.error,
    );
    throw error;
  }
};

export const addLocation = async (type, label, address) => {
  try {
    const data = {
      type,
      label,
      address,
    };
    const response = await api.post(`/admin/therapists/locations`, data);
    return response.data;
  } catch (error) {
    console.error("Error adding location", error.response?.data?.error);
    throw error;
  }
};

export const removeLocation = async (locationId) => {
  try {
    const response = await api.delete(
      `/admin/therapists/locations/${locationId}`,
    );
    return response.data;
  } catch (error) {
    console.error("Error removing location", error.response?.data?.error);
    throw error;
  }
};

export const updateLocation = async (locationId, label, address) => {
  try {
    const data = { label, address };
    const response = await api.put(
      `/admin/therapists/locations/${locationId}`,
      data,
    );
    return response.data;
  } catch (error) {
    console.error("Error updating location", error.response?.data?.error);
    throw error;
  }
};

export const updateZoomLocation = async () => {
  try {
    const response = await api.post(`/admin/therapists/locations/zoom`);
    return response.data;
  } catch (error) {
    console.error("Error updating zoom", error.response?.data?.error);
    throw error;
  }
};

export const upsertTherapistQualifications = async (qualifications) => {
  try {
    const response = await api.put(`/admin/therapists/qualifications`, {
      qualifications,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error upserting qualifications",
      error.response?.data?.error,
    );
    throw error;
  }
};

// v2
export const createGroup = async (group) => {
  try {
    const response = await api.post(`/admin/groups`, group);
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.error ||
      "An unexpected error occurred while creating the group.";
    console.error("Error creating group:", errorMessage);
    throw new Error(errorMessage);
  }
};

//v2
export const updateGroupEssentials = async (
  groupId,
  name,
  description,
  size,
  location,
) => {
  try {
    const response = await api.put(`/admin/groups/${groupId}/essentials`, {
      name,
      description,
      size,
      location,
    });
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.error ||
      "An unexpected error occurred while updating group essentials.";
    console.error("Error updating group:", errorMessage);
    throw new Error(errorMessage);
  }
};

//v2
export const updateSessionInformation = async (
  groupId,
  firstSessionDate,
  sessionTime,
  recurrence,
  duration,
  sessionCount,
  pricePerSession,
  language,
) => {
  try {
    const response = await api.put(`/admin/groups/${groupId}/sessions`, {
      firstSessionDate,
      sessionTime,
      recurrence,
      duration,
      sessionCount,
      pricePerSession,
      language,
    });
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.error ||
      "An unexpected error occurred while updating session information.";
    console.error("Error updating group:", errorMessage);
    throw new Error(errorMessage);
  }
};

//v2
export const publishDraft = async (groupId) => {
  try {
    const response = await api.post(`/admin/groups/${groupId}/publish`);
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.error ||
      "An unexpected error occurred while publishing the group.";
    console.error("Error publishing group:", errorMessage);
    throw new Error(errorMessage);
  }
};

/***** PUBLIC Functions */

export const getAllGroupDetails = async (groupId) => {
  try {
    const response = await api.get(`/public/groups/${groupId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching group details", error.response?.data?.error);
    throw error;
  }
};

export const getAllGroups = async () => {
  try {
    const response = await api.get(`/public/groups`);
    return response.data;
  } catch (error) {
    console.error("Error fetching all groups", error.response?.data?.error);
    throw error;
  }
};

export const getTherapistDetailsByHandle = async (handle) => {
  try {
    const response = await api.get(`/public/therapists/${handle}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching therapist details by handle",
      error.response?.data?.error,
    );
    throw error;
  }
};

export const getAllEnrollableGroupsByHandle = async (handle) => {
  try {
    const response = await api.get(`/public/groups/therapists/${handle}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching group therapies by handle",
      error.response?.data?.error,
    );
    throw error;
  }
};

export const createRequest = async (data) => {
  try {
    const response = await api.post(`/public/requests`, data);
    return response.data;
  } catch (error) {
    console.error("Error creating request", error.response?.data?.error);
    throw error;
  }
};

export const getTimeSlotsByEncryptedRequestId = async (
  encodedEncryptedRequestId,
  encodedWeekOf,
) => {
  try {
    let url = `/public/requests/${encodedEncryptedRequestId}/timeslots`;
    if (encodedWeekOf !== "none") {
      url += `?weekOf=${encodedWeekOf}`;
    }

    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error(
      "Error retrieving timeslots for the request",
      error.response?.data?.error,
    );
    throw error;
  }
};

export const confirmTimeSlot = async (
  encodedEncryptedRequestId,
  encodedEncryptedTimeSlotId,
) => {
  try {
    const response = await api.post(
      `/public/requests/${encodedEncryptedRequestId}/timeslots/${encodedEncryptedTimeSlotId}/confirm`,
    );
    return response.data;
  } catch (error) {
    console.error("Error confirming timeslot", error);
    throw error;
  }
};

export const acceptMove = async (encodedEncryptedRequestId) => {
  try {
    const response = await api.post(
      `/public/requests/${encodedEncryptedRequestId}/accept`,
    );
    return response.data;
  } catch (error) {
    console.error("Error accepting group move", error);
    throw error;
  }
};

export const withdrawRequest = async (encodedEncryptedRequestId) => {
  try {
    const response = await api.post(
      `/public/requests/${encodedEncryptedRequestId}/withdraw`,
    );
    return response.data;
  } catch (error) {
    console.error("Error accepting group move", error);
    throw error;
  }
};

export const getRequestStatus = async (encodedEncryptedRequestId) => {
  try {
    const response = await api.get(
      `/public/requests/${encodedEncryptedRequestId}/status`,
    );
    return response.data;
  } catch (error) {
    console.error("Error accepting group move", error);
    throw error;
  }
};

export const getAllQuestionsOfTherapist = async () => {
  try {
    const response = await api.get("/admin/therapists/questions");
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching questions of the therapist",
      error.response?.data?.error,
    );
    throw error;
  }
};

export const saveQuestion = async (
  questionType,
  questionText,
  questionOptions,
  questionId = null,
) => {
  try {
    const data = {
      type: questionType,
      text: questionText,
      options: questionOptions,
    };
    let response;
    if (questionId) {
      // Updating a quesiton
      response = await api.put(
        `/admin/therapists/questions/${questionId}`,
        data,
      );
    } else {
      // Creating a new question
      response = await api.post("/admin/therapists/questions", data);
    }

    return response.data;
  } catch (error) {
    console.error("Error saving question", error.response?.data?.error);
    throw error;
  }
};

export const addQuestionsToGroup = async (groupId, questions = []) => {
  try {
    const response = await api.post(
      `/admin/therapists/group-questions/${groupId}`,
      { questions },
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error adding questions to the group",
      error.response?.data?.error,
    );
    throw error;
  }
};

export const updateQuestionsInUse = async () => {
  try {
    const response = await api.post(`/admin/therapists/questions/in-use`);
    return response.data;
  } catch (error) {
    console.error(
      "Error updating questions inUse information",
      error.response?.data?.error,
    );
    throw error;
  }
};

export const createTherapist = async (data) => {
  try {
    const response = await api.post(`/admin/therapists`, data);
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.error ||
      "An unexpected error occurred while updating session information.";
    console.error("Error creating group:", errorMessage);
    throw new Error(errorMessage);
  }
};

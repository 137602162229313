import { Card, Stack } from "react-bootstrap";
import { EnvelopeAt } from "react-bootstrap-icons";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import StatusLabel from "../shared/StatusLabel";

const RequestListItem = ({
  firstName,
  lastName,
  emailAddress,
  phoneNumber,
  onCardClick,
  onEmailClick,
  subStatus,
}) => {
  return (
    <Card
      style={{
        width: "20rem",
        height: "8rem",
        margin: "auto",
        cursor: "pointer",
      }}
      onClick={onCardClick}
    >
      <Card.Body>
        <Stack direction="horizontal">
          <Card.Title>
            {firstName} {lastName}
          </Card.Title>
          {onEmailClick && (
            <EnvelopeAt
              size={20}
              className="ms-auto action-icon"
              style={{ marginBottom: "10px" }}
              onClick={(e) => {
                e.stopPropagation();
                onEmailClick();
              }}
            />
          )}
        </Stack>
        <div style={{ marginTop: "-10px" }}>
          <p style={{ marginBottom: "0px" }}>
            <small>{emailAddress}</small>
          </p>
          <p style={{ marginTop: "-5px", marginBottom: "0px" }}>
            <small>{formatPhoneNumberIntl(phoneNumber)}</small>
          </p>
        </div>
        {subStatus !== "NOT_APPLICABLE" && (
          <StatusLabel status={subStatus} viewType="grid" />
        )}
      </Card.Body>
    </Card>
  );
};

export default RequestListItem;

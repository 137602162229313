import { useState, useEffect } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import {
  getGroupDetails,
  getTherapistDetails,
  addQuestionsToGroup,
} from "../../../services/api.js";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Alert,
  Spinner,
  Card,
} from "react-bootstrap";
import { ArrowUpCircle, ArrowDownCircle } from "react-bootstrap-icons";
import StatusLabel from "../../../components/shared/StatusLabel.js";
import SelectQuestionsModal from "../../../components/admin/SelectQuestionsModal.js";
import QuestionListItem from "../../../components/admin/QuestionListItem.js";

export async function loader({ params }) {
  let group = {};
  let therapist = {};

  try {
    therapist = await getTherapistDetails({ includeQuestions: true });
    group = await getGroupDetails({
      groupId: params.groupId,
      includeRequests: false,
      includeQuestions: true,
    });
  } catch (error) {
    console.log("An error occurred while fetching the group details");
  }

  return { group, therapist };
}

const EditGroupQuestionsPage = () => {
  const { group, therapist } = useLoaderData();
  const navigate = useNavigate();

  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [selectedQuestionIds, setSelectedQuestionIds] = useState([]);
  const [showSelectQuestionsModal, setShowSelectQuestionsModal] =
    useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState("");
  const [allowUpdate, setAllowUpdate] = useState("NONE"); // NONE, FULL

  const handleSelectQuestionsButton = () => {
    setShowSelectQuestionsModal(true);
  };

  const moveQuestion = (index, direction) => {
    const newSelectedQuestions = [...selectedQuestions];
    const newPosition = index + direction;

    if (newPosition >= 0 && newPosition < newSelectedQuestions.length) {
      const temp = newSelectedQuestions[index];
      newSelectedQuestions[index] = newSelectedQuestions[newPosition];
      newSelectedQuestions[newPosition] = temp;
      setSelectedQuestions(newSelectedQuestions);
    }
  };

  const handleSaveSelectedQuestions = async (newSelectedQuestionIds) => {
    setSelectedQuestionIds(newSelectedQuestionIds);
    const updatedSelectedQuestions = therapist.questions.filter((question) =>
      newSelectedQuestionIds.includes(question._id),
    );
    setSelectedQuestions(updatedSelectedQuestions);
  };

  const handleRequiredChange = (index, isChecked) => {
    const updatedQuestions = [...selectedQuestions];
    updatedQuestions[index].isRequired = isChecked;
    setSelectedQuestions(updatedQuestions);
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = selectedQuestions.filter(
      (question, idx) => idx !== index,
    );
    setSelectedQuestions(updatedQuestions);
    const updatedQuestionIds = updatedQuestions.map((question) => question._id);
    setSelectedQuestionIds(updatedQuestionIds);
  };

  useEffect(() => {
    if (group) {
      if (group.questions) {
        setSelectedQuestions(group.questions);
        setSelectedQuestionIds(group.questions.map((gq) => gq._id));
      }

      if (group.status !== "COMPLETED") {
        setAllowUpdate("FULL");
      } else {
        setAllowUpdate("NONE");
      }
    }
  }, [group]);

  const handleSaveButton = async () => {
    setIsProcessing(true);

    try {
      await addQuestionsToGroup(group._id, selectedQuestions);
      setError("");
      navigate(`/admin/groups/${group._id}`);
    } catch (error) {
      console.error(error);
      setError(error.message || "An unexpected error occurred.");
    } finally {
      setIsProcessing(false);
    }
  };

  const handleBackButton = () => {
    navigate(`/admin/groups/${group._id}`);
  };

  return (
    <Container className="settings-header-container" fluid>
      <Row
        className="d-flex justify-content-between align-items-center px-4"
        style={{ height: "7rem" }}
      >
        <Col>
          <h4 style={{ marginBottom: "0px" }}>
            {group.name} | Pre-Screening Questionnaire
          </h4>
          <StatusLabel status={group.status} />
        </Col>
        <Col
          className="d-flex align-items-center justify-content-end"
          style={{ paddingRight: "0" }}
        >
          <Button
            className="secondary-button pill-button"
            onClick={handleBackButton}
          >
            Back
          </Button>
          {allowUpdate !== "NONE" && (
            <>
              <Button
                className="primary-button pill-button"
                onClick={handleSaveButton}
                style={{ marginLeft: "10px" }}
              >
                {isProcessing && (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                  </>
                )}
                Save
              </Button>
            </>
          )}
        </Col>
      </Row>
      <hr style={{ marginTop: "0px" }} />
      <Container className="main-container">
        {allowUpdate !== "NONE" && (
          <div className="d-flex justify-content-center">
            <Button
              className="tertiary-button pill-button mb-5"
              onClick={handleSelectQuestionsButton}
            >
              {selectedQuestions.length > 0
                ? "Change Selected Questions"
                : "Add Questions to Group"}
            </Button>
          </div>
        )}
        {selectedQuestions.map((question, idx) => (
          <Row key={idx}>
            <Col>
              <Card className="mb-3">
                {allowUpdate === "FULL" && (
                  <Card.Header className="d-flex justify-content-between align-items-center">
                    <Form.Check
                      type="checkbox"
                      label="Required"
                      checked={question.isRequired || false}
                      onChange={(e) =>
                        handleRequiredChange(idx, e.target.checked)
                      }
                      className="me-5"
                    />
                    <div>
                      <ArrowUpCircle
                        onClick={idx !== 0 ? () => moveQuestion(idx, -1) : null}
                        size={22}
                        style={{
                          cursor: idx !== 0 && "pointer",
                          opacity: idx !== 0 ? 1 : 0.5,
                          marginRight: "8px",
                        }}
                      />
                      <ArrowDownCircle
                        onClick={
                          idx !== selectedQuestions.length - 1
                            ? () => moveQuestion(idx, 1)
                            : null
                        }
                        size={22}
                        style={{
                          cursor:
                            idx !== selectedQuestions.length - 1 && "pointer",
                          opacity:
                            idx !== selectedQuestions.length - 1 ? 1 : 0.5,
                        }}
                      />
                    </div>
                  </Card.Header>
                )}
                <Card.Body>
                  <QuestionListItem
                    questionText={question.text}
                    questionType={question.type}
                    questionOptions={question.options}
                    questionInUse={question.inUse}
                    editAllowed={false}
                  />
                </Card.Body>
                {allowUpdate === "FULL" && (
                  <Card.Footer className="d-flex justify-content-end py-1">
                    <Button
                      variant="link"
                      onClick={() => handleRemoveQuestion(idx)}
                      style={{ color: "var(--bs-gray-800)" }}
                    >
                      Remove
                    </Button>
                  </Card.Footer>
                )}
              </Card>
            </Col>
          </Row>
        ))}
        {error && (
          <Alert className="mt-2" variant="danger">
            {error}
          </Alert>
        )}
      </Container>
      {showSelectQuestionsModal && (
        <SelectQuestionsModal
          show={showSelectQuestionsModal}
          onHide={() => setShowSelectQuestionsModal(false)}
          allQuestions={therapist.questions}
          selectedQuestionIds={selectedQuestionIds}
          onSelect={handleSaveSelectedQuestions}
          groupId={group._id}
        />
      )}
    </Container>
  );
};

export default EditGroupQuestionsPage;

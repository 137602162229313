import { useLoaderData } from "react-router-dom";
import { Container, Row } from "react-bootstrap";

import { getAllGroupsOfTherapist } from "../../../services/api.js";
import GroupGrid from "../../../components/admin/GroupGrid.js";

export async function loader() {
  let groups = [];
  try {
    groups = await getAllGroupsOfTherapist();
  } catch (error) {
    console.log("An error occurred while fetching the groups of the therapist");
  }

  return { groups };
}

const GroupGridPage = () => {
  const { groups } = useLoaderData();

  if (groups.length === 0) {
    return (
      <Container className="main-container">
        <Row className="px-3">
          You don't have any groups yet. Use the "Create Group" button above to
          create your first group.
        </Row>
      </Container>
    );
  } else {
    return <GroupGrid allGroups={groups} />;
  }
};

export default GroupGridPage;

import { Container, Row, Col } from "react-bootstrap";
import { Outlet } from "react-router-dom";

import PublicTopNav from "./public/PublicTopNav";

const PublicLayout = () => {
  return (
    <Container fluid>
      <Row>
        <Col xs={12} id="main-content-wrapper">
          <PublicTopNav />
          <div id="main-content">
            <Outlet />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PublicLayout;

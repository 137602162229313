import { Navbar, Nav, Offcanvas, Button } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const AdminSideNav = ({ show, handleClose }) => {
  const location = useLocation();
  const navigate = useNavigate();
  let calendarNavlinkClass = "admin-side-nav-btn";

  if (location.pathname === "/admin" || location.pathname === "/admin/") {
    calendarNavlinkClass += " active";
  }

  const signOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        console.log("User signed out successfully");
        navigate("/");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  return (
    <>
      {/* Offcanvas for mobile view */}
      {show && (
        <Offcanvas
          show={show}
          onHide={handleClose}
          responsive="lg"
          id="admin-side-navbar"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Menu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="flex-column" id="admin-side-nav">
              <NavLink
                className="admin-side-nav-btn"
                to="/admin/calendar"
                onClick={handleClose}
              >
                Calendar
              </NavLink>
              <NavLink
                className="admin-side-nav-btn"
                to="/admin/groups"
                onClick={handleClose}
              >
                Groups
              </NavLink>
              <NavLink
                className="admin-side-nav-btn"
                to="/admin/settings"
                onClick={handleClose}
              >
                Settings
              </NavLink>
              <hr />
              <Button
                variant="link"
                className="logout-btn"
                onClick={() => {
                  handleClose();
                  signOut();
                }}
              >
                Logout
              </Button>
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>
      )}

      {/* Navbar for desktop view */}
      <Navbar bg="light" expand="lg" id="admin-side-navbar">
        <Nav className="flex-column" id="admin-side-nav">
          <NavLink className={calendarNavlinkClass} to="/admin/calendar">
            Calendar
          </NavLink>
          <NavLink className="admin-side-nav-btn" to="/admin/groups">
            Groups
          </NavLink>
          <NavLink className="admin-side-nav-btn" to="/admin/settings">
            Settings
          </NavLink>
          <hr />
          <Button variant="link" className="logout-btn" onClick={signOut}>
            Logout
          </Button>
        </Nav>
      </Navbar>
    </>
  );
};

export default AdminSideNav;

import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { checkTherapistExists } from "../services/api";
import AdminTopNav from "./admin/AdminTopNav";
import AdminSideNav from "./admin/AdminSideNav";
import { Outlet } from "react-router-dom";

const AdminLayout = () => {
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const [loading, setLoading] = useState(true); // New loading state
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const checkAccess = async () => {
      if (!currentUser) {
        // If not authenticated, redirect to auth page
        navigate("/auth");
      } else {
        try {
          const therapist = await checkTherapistExists();
          if (!therapist.exists) {
            // If the user hasn't completed signup, redirect to signup page
            navigate("/signup", {
              state: { phoneNumber: currentUser.phoneNumber },
            });
          } else {
            // User is authenticated and has completed signup
            setLoading(false); // Set loading to false to render the content
          }
        } catch (error) {
          console.error("An error occurred:", error);
        }
      }
    };

    checkAccess();
  }, [currentUser, navigate]);

  const toggleSideNav = () => {
    setSideNavOpen(!sideNavOpen);
  };

  const closeSideNav = () => {
    setSideNavOpen(false);
  };

  if (loading) {
    return null;
  }

  return (
    <Container id="admin-app" fluid>
      <Row id="admin-header">
        <AdminTopNav toggleSideNav={toggleSideNav} />
      </Row>
      <Row id="admin-body">
        <Col xs={2} className="d-none d-lg-block" id="admin-side-nav-wrapper">
          <AdminSideNav show={false} handleClose={closeSideNav} />
        </Col>
        {sideNavOpen && (
          <AdminSideNav show={sideNavOpen} handleClose={closeSideNav} />
        )}
        <Col xs={12} lg={10} id="admin-content-wrapper">
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};

export default AdminLayout;

import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  redirect,
} from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

// Context
import { AuthProvider } from "./contexts/AuthContext.js";
import { TherapistProvider } from "./contexts/TherapistContext.js";

//Layouts
import AdminLayout from "./components/AdminLayout.js";
import PublicLayout from "./components/PublicLayout.js";

// Public Pages
import AuthPage from "./pages/public/AuthPage.jsx";
import HomePage from "./pages/public/HomePage.jsx";
import EnrollmentPage, {
  loader as enrollmentPageLoader,
} from "./pages/public/EnrollmentPage.jsx";
import TherapistGroupListPage, {
  loader as therapistGroupListPageLoader,
} from "./pages/public/TherapistGroupListPage.jsx";
import SelectTimeSlotPage, {
  loader as selectTimeSlotPageLoader,
} from "./pages/public/SelectTimeSlotPage.jsx";
import MoveConsentPage, {
  loader as moveConsentPageLoader,
} from "./pages/public/MoveConsentPage.jsx";

// Admin Pages
import Calendar, {
  loader as calendarLoader,
} from "./pages/admin/calendar/CalendarPage.jsx";

import GroupGridPage, {
  loader as groupGridPageLoader,
} from "./pages/admin/groups/GroupGridPage.jsx";
import GroupDetailsPage, {
  loader as groupDetailsPageLoader,
} from "./pages/admin/groups/GroupDetailsPage.jsx";
import EditGroupEssentialsPage, {
  loader as editGroupEssentialsPageLoader,
} from "./pages/admin/groups/EditGroupEssentialsPage.jsx";
import EditSessionInformationPage, {
  loader as editSessionInformationPageLoader,
} from "./pages/admin/groups/EditSessionInformationPage.jsx";
import EditGroupQuestionsPage, {
  loader as editGroupQuestionsPageLoader,
} from "./pages/admin/groups/EditGroupQuestionsPage.jsx";
import GroupRequestDetailPage, {
  loader as groupRequestDetailPageLoader,
} from "./pages/admin/groups/GroupRequestDetailPage.jsx";

import SettingsPage, {
  loader as settingsPageLoader,
} from "./pages/admin/settings/SettingsPage.jsx";
import SetAvailabilityPage, {
  loader as setAvailabilityPageLoader,
} from "./pages/admin/settings/SetAvailabilityPage.jsx";
import SetQuestionsPage, {
  loader as setQuestionsPageLoader,
} from "./pages/admin/settings/SetQuestionsPage.jsx";
import SetLocationsPage, {
  loader as setLocationsPageLoader,
} from "./pages/admin/settings/SetLocationsPage.jsx";
import SetProfilePage, {
  loader as setProfilePageLoader,
} from "./pages/admin/settings/SetProfilePage.jsx";

import SignupPage, {
  loader as signupPageLoader,
} from "./pages/public/SignupPage.jsx";
import OAuthCallback, {
  loader as callbackLoader,
} from "./pages/admin/OAuthCallback.jsx";
import VerifyEmailPage, {
  loader as verifyEmailPageLoader,
} from "./pages/admin/VerifyEmailPage.jsx";

const checkAuth = async () => {
  return new Promise((resolve) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  });
};

const protectedLoader = async () => {
  const isAuthenticated = await checkAuth();
  if (!isAuthenticated) {
    throw redirect("/auth");
  }
};

const router = createBrowserRouter([
  {
    element: <PublicLayout />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "auth",
        element: <AuthPage />,
      },
      {
        path: "signup",
        loader: async () => {
          await protectedLoader();
          return signupPageLoader();
        },
        element: <SignupPage />,
      },
      {
        path: "therapists/:therapistHandle",
        loader: therapistGroupListPageLoader,
        element: <TherapistGroupListPage />,
      },
      {
        path: "enroll/:groupId",
        loader: enrollmentPageLoader,
        element: <EnrollmentPage />,
      },
      {
        path: "select-timeslot/:requestId",
        loader: selectTimeSlotPageLoader,
        element: <SelectTimeSlotPage />,
      },
      {
        path: "/move-consent/:requestId",
        loader: moveConsentPageLoader,
        element: <MoveConsentPage />,
      },
    ],
  },
  {
    path: "oauth/callback",
    loader: async ({ request }) => {
      return callbackLoader({ request });
    },
    element: <OAuthCallback />,
  },
  {
    path: "verify-email",
    loader: async ({ request }) => {
      return verifyEmailPageLoader({ request });
    },
    element: <VerifyEmailPage />,
  },
  {
    path: "/admin",
    element: <AdminLayout />,
    children: [
      {
        path: "",
        loader: async ({ request }) => {
          await protectedLoader();
          return calendarLoader({ request });
        },
        element: <Calendar />,
      },
      {
        path: "calendar",
        loader: async ({ request }) => {
          await protectedLoader();
          return calendarLoader({ request });
        },
        element: <Calendar />,
      },
      {
        path: "groups",
        loader: async () => {
          await protectedLoader();
          return groupGridPageLoader();
        },
        element: <GroupGridPage />,
      },
      {
        path: "groups/:groupId",
        loader: async ({ params }) => {
          await protectedLoader();
          return groupDetailsPageLoader({ params });
        },
        element: <GroupDetailsPage />,
      },
      {
        path: "groups/:groupId/essentials",
        loader: async ({ params }) => {
          await protectedLoader();
          return editGroupEssentialsPageLoader({ params });
        },
        element: <EditGroupEssentialsPage />,
      },
      {
        path: "groups/:groupId/sessions",
        loader: async ({ params }) => {
          await protectedLoader();
          return editSessionInformationPageLoader({ params });
        },
        element: <EditSessionInformationPage />,
      },
      {
        path: "groups/:groupId/questions",
        loader: async ({ params }) => {
          await protectedLoader();
          return editGroupQuestionsPageLoader({ params });
        },
        element: <EditGroupQuestionsPage />,
      },
      {
        path: "groups/:groupId/request/:requestId",
        loader: async ({ params }) => {
          await protectedLoader();
          return groupRequestDetailPageLoader({ params });
        },
        element: <GroupRequestDetailPage />,
      },
      {
        path: "settings",
        loader: async () => {
          await protectedLoader();
          return settingsPageLoader();
        },
        element: <SettingsPage />,
      },
      {
        path: "settings/availability",
        loader: async ({ request }) => {
          await protectedLoader();
          return setAvailabilityPageLoader({ request });
        },
        element: <SetAvailabilityPage />,
      },
      {
        path: "settings/questions",
        loader: async () => {
          await protectedLoader();
          return setQuestionsPageLoader();
        },
        element: <SetQuestionsPage />,
      },
      {
        path: "settings/locations",
        loader: async () => {
          await protectedLoader();
          return setLocationsPageLoader();
        },
        element: <SetLocationsPage />,
      },
      {
        path: "settings/profile",
        loader: async () => {
          await protectedLoader();
          return setProfilePageLoader();
        },
        element: <SetProfilePage />,
      },
    ],
  },
]);

function App() {
  return (
    <AuthProvider>
      <TherapistProvider>
        <RouterProvider router={router} />
      </TherapistProvider>
    </AuthProvider>
  );
}

export default App;

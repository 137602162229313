import { useState } from "react";
import { Modal, Button, Form, Spinner, Alert, Row, Col } from "react-bootstrap";
import PhoneInput from "react-phone-number-input/input";
import { addNewApprovedMember } from "../../services/api";

// TODO - Add PhoneNumber here!

const AddMemberModal = ({
  show,
  onHide,
  groupId,
  onAddMemberResult,
  emailList,
}) => {
  const [modalStep, setModalStep] = useState("1");
  const [isProcessing, setIsProcessing] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");

  const handleNextButton = () => {
    if (!firstName || !lastName || !emailAddress || !phoneNumber) {
      setError("All fields are required");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(emailAddress)) {
      setError("Please provide a valid email address");
      return;
    }

    if (emailList.includes(emailAddress)) {
      setError(
        "This email address is already associated with an existing request for this group.",
      );
      return;
    }

    setError("");
    setModalStep("2");
  };

  const handleBackButton = () => {
    setModalStep("1");
  };

  const handleApproveButton = async () => {
    setIsProcessing(true);

    try {
      const requestData = {
        groupId: groupId,
        firstName: firstName,
        lastName: lastName,
        emailAddress: emailAddress,
        phoneNumber: phoneNumber,
      };
      await addNewApprovedMember(requestData);
      onHide();
      onAddMemberResult(true);
    } catch (error) {
      console.error(`Failed to send`, error);
      onHide();
      onAddMemberResult(false);
    }

    setIsProcessing(false);
  };

  if (modalStep === "1") {
    return (
      <Modal show={show} onHide={onHide} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mb-3">
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Client First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Client Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Form.Control
                  type="email"
                  placeholder="Client Email Address"
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                  required
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <div className="bootstrap-phone-input">
                  <PhoneInput
                    placeholder="Client Phone Number"
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    className="PhoneInputInput"
                  />
                </div>
              </Col>
            </Row>
          </Form>
          {error && (
            <Alert className="mt-2" variant="danger">
              {error}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
          <Button variant="dark" onClick={handleNextButton}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>
    );
  } else {
    return (
      <Modal show={show} onHide={onHide} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Approved Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          By confirming, a new member will be added to the group, and a link to
          the recurring group meetings will be sent to the provided email
          address.
          <br />
          <br />
          Do you agree to proceed?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleBackButton}>
            Back
          </Button>
          <Button variant="dark" onClick={handleApproveButton}>
            {isProcessing && (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
              </>
            )}
            Add Member
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};

AddMemberModal.defaultProps = {
  onAddMemberResult: () => {},
};

export default AddMemberModal;

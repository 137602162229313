import { Container, Card, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import GroupDetailSummary from "../admin/GroupDetailSummary.js";

const GroupList = ({ groups, backTo, timeZone }) => {
  const navigate = useNavigate();
  const handleSelectGroup = (groupId) => {
    const baseUrl = `/enroll/${groupId}`;
    const backToParam = backTo === "homepage" ? "homepage" : "therapist";
    navigate(`${baseUrl}?backTo=${backToParam}`);
  };

  return (
    <Container className="main-container">
      <Row xs={1} md={1} xxl={1} className="g-3">
        {groups.map((group, idx) => (
          <Col key={idx}>
            <Card>
              <Card.Body>
                <Card.Title>{group.name}</Card.Title>
                <Card.Subtitle className="mb-2 muted">
                  {group.description}
                </Card.Subtitle>
                <hr />
                <GroupDetailSummary group={group} timeZone={timeZone} />
                <Button
                  className="select-group-button"
                  variant="success"
                  onClick={() => handleSelectGroup(group._id)}
                >
                  {group.language === "tr_TR" ? "Seç" : "Select"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default GroupList;

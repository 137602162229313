import RequestDetail from "../../../components/admin/RequestDetail.js";
import { getRequestDetails } from "../../../services/api.js";
import { useLoaderData } from "react-router-dom";

export async function loader({ params }) {
  let request;
  let therapist;
  let group;
  let timeSlot;
  let questionnaire;

  try {
    let enhancedRequest = await getRequestDetails(params.requestId);

    request = enhancedRequest.request;
    therapist = enhancedRequest.therapist;
    group = enhancedRequest.group;
    questionnaire = enhancedRequest.questionnaire;

    if (enhancedRequest.timeSlot) {
      timeSlot = enhancedRequest.timeSlot;
    }
  } catch (error) {
    console.log("An error occurred while fetching the request details");
  }

  return { request, therapist, group, timeSlot, questionnaire };
}

const GroupRequestDetailPage = () => {
  const { request, therapist, group, timeSlot, questionnaire } =
    useLoaderData();

  return (
    <RequestDetail
      therapist={therapist}
      selectedGroup={group}
      selectedRequest={request}
      timeSlot={timeSlot}
      questionnaire={questionnaire}
    />
  );
};

export default GroupRequestDetailPage;

import { Row, Col } from "react-bootstrap";

const AvailabilitySummary = ({ availabilityArray }) => {
  if (availabilityArray.length === 0) {
    return (
      <Row className="px-3 flex-column">
        You have not yet set your availability. Please use the Manage button to
        set it up.
      </Row>
    );
  }

  return (
    <Row className="px-3 flex-column">
      {availabilityArray.map((slot, index) => (
        <Row key={index} style={{ maxWidth: "500px" }}>
          <Col xs={4}>
            <p>{slot.day}</p>
          </Col>
          <Col xs={1}>
            <p>| </p>
          </Col>
          <Col xs={7}>
            <p>
              {slot.startTime} to {slot.endTime}
            </p>
          </Col>
        </Row>
      ))}
    </Row>
  );
};

export default AvailabilitySummary;

import { useState } from "react";
import {
  Modal,
  Button,
  InputGroup,
  Row,
  Form,
  Alert,
  Spinner,
} from "react-bootstrap";

const CreateGroupModal = ({ show, onHide, onCreate, error }) => {
  const [selectedName, setSelectedName] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const onCreateButton = async () => {
    setIsProcessing(true);

    const group = {
      name: selectedName,
    };

    try {
      await onCreate(group);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create a New Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <p>
            Please enter a name for your new group. Don't worry, you can always
            change it later.
          </p>
        </Row>
        <Row xs={1}>
          <InputGroup className="mb-3">
            <InputGroup.Text>Group Name</InputGroup.Text>
            <Form.Control
              value={selectedName}
              onChange={(e) => setSelectedName(e.currentTarget.value)}
            />
          </InputGroup>
          {error && (
            <Alert className="mt-2" variant="danger">
              {error}
            </Alert>
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="secondary-button pill-button"
          onClick={onHide}
          disabled={isProcessing}
        >
          Cancel
        </Button>
        <Button
          className="primary-button pill-button"
          onClick={onCreateButton}
          disabled={isProcessing}
        >
          {isProcessing && (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
            </>
          )}
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateGroupModal;

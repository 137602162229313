import {
  formatDateToTurkish,
  calculateWeekCount,
  formatRecurrenceAmerican,
  formatRecurrenceTurkish,
} from "../../utils/utils.js";

import { DateTime } from "luxon";

const GroupDetailSummary = ({ group, timeZone }) => {
  let groupDetail, groupSessionDetail;

  if (group.language === "tr_TR") {
    const turkeySessionTime = DateTime.fromFormat(group.sessionTime, "HH:mm", {
      zone: timeZone,
    })
      .setZone("Europe/Istanbul")
      .toFormat("HH:mm");

    groupDetail = `Bu grup, ${formatDateToTurkish(group.firstSessionDate)} günü başlayacak ve ${calculateWeekCount(group.sessionCount, group.recurrence.interval)} hafta boyunca ${formatRecurrenceTurkish(group.recurrence)} Türkiye saati ile ${turkeySessionTime}'de, her biri ${group.duration} dakika sürecek şekilde toplanacaktır`;

    groupSessionDetail = `Toplamda ${group.sessionCount} seans yapılacak ve her seansın ücreti ${group.pricePerSession} TL olacaktır.`;
  } else {
    const timeZoneAbbreviation = DateTime.now()
      .setZone(timeZone)
      .toFormat("ZZZZ");

    const firstSessionDay = DateTime.fromISO(group.firstSessionDate).setZone(
      "utc",
    );
    const day = firstSessionDay.day;

    const ordinal = (day) => {
      const suffixes = ["th", "st", "nd", "rd"];
      const v = day % 100;
      return suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0];
    };

    const formattedDate =
      firstSessionDay.toFormat("cccc LLLL d") +
      ordinal(day) +
      firstSessionDay.toFormat(", yyyy");

    groupDetail = `This group will begin on ${formattedDate}, and will meet ${formatRecurrenceAmerican(group.recurrence)} at ${DateTime.fromFormat(group.sessionTime, "HH:mm").toFormat("h:mm a")} ${timeZoneAbbreviation} for ${group.duration} minutes each session, continuing for ${calculateWeekCount(group.sessionCount, group.recurrence.interval)} weeks.`;

    groupSessionDetail = `A total of ${group.sessionCount} sessions will be conducted, with a fee of ${group.pricePerSession} USD per session.`;
  }

  return (
    <div>
      <p>{groupDetail}</p>
      <p>{groupSessionDetail}</p>
    </div>
  );
};

export default GroupDetailSummary;

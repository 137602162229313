import {
  getTherapistDetailsByHandle,
  getAllEnrollableGroupsByHandle,
} from "../../services/api.js";
import { useEffect } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import GroupList from "../../components/public/GroupList.js";
import { useTherapist } from "../../contexts/TherapistContext.js";

export async function loader({ params }) {
  let therapist;
  let therapistFullName;
  let enrollableGroups;
  let therapistHandle;
  let therapistTimeZone;

  try {
    therapist = await getTherapistDetailsByHandle(params.therapistHandle);
    enrollableGroups = await getAllEnrollableGroupsByHandle(
      params.therapistHandle,
    );
    therapistFullName = `${therapist.firstName} ${therapist.lastName}`;
    therapistHandle = params.therapistHandle;
    therapistTimeZone = therapist.timeZone;
  } catch (error) {
    console.log("An error occurred while fetching enrollable groups");
    if (error.response && error.response.status === 404) {
      return { statusCode: 404 };
    }
  }

  return {
    therapistFullName,
    therapistHandle,
    enrollableGroups,
    therapistTimeZone,
  };
}

const TherapistGroupListPage = () => {
  const navigate = useNavigate();
  const { setTherapistName, setTherapistHandle } = useTherapist();
  const {
    therapistFullName,
    enrollableGroups,
    statusCode,
    therapistHandle,
    therapistTimeZone,
  } = useLoaderData();

  setTherapistName(therapistFullName);
  setTherapistHandle(therapistHandle);

  useEffect(() => {
    if (statusCode) {
      if (statusCode === 404) {
        navigate("/not-found");
      }
    }
  }, [statusCode, navigate]);

  if (statusCode) return null;

  return (
    <>
      <Container className="main-container">
        {enrollableGroups.length === 0 ? (
          <p style={{ textAlign: "center" }}>
            {therapistFullName} does not have groups open for enrollment at the
            moment.
          </p>
        ) : (
          <GroupList groups={enrollableGroups} timeZone={therapistTimeZone} />
        )}
      </Container>
    </>
  );
};

export default TherapistGroupListPage;

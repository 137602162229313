import { getTimeSlotsByEncryptedRequestId } from "../../services/api.js";
import { useLoaderData } from "react-router-dom";
import TimeSlotList from "../../components/public/TimeSlotList.js";
import { DateTime } from "luxon";
import { Container } from "react-bootstrap";
import { useTherapist } from "../../contexts/TherapistContext.js";

export async function loader({ params, request }) {
  const requestId = params.requestId;

  let therapist = {
    _id: "404404404404404404404404",
    firstName: "Chair",
    lastName: "Circles",
  };
  let group = {
    name: "Not Found",
    language: "en_EN",
  };

  let availabilities = {
    previousWeekHasAvailability: false,
    nextWeekHasAvailability: false,
  };

  const url = new URL(request.url);
  const queryParams = new URLSearchParams(url.search);

  // If selectedWeekOf is empty, it means that the user clicked the email link and opened the timeslot selection page for the first time
  // If not, it means that the user is clicking between next/previous buttons
  let selectedWeekOf = queryParams.get("weekOf");
  let timeSlots = [];

  try {
    if (!selectedWeekOf) {
      selectedWeekOf = "none";
    }
    const response = await getTimeSlotsByEncryptedRequestId(
      requestId,
      encodeURIComponent(selectedWeekOf),
    );
    therapist = response.therapist;
    group = response.group;
    timeSlots = response.timeSlots;

    const firstAvailableWeekOf = response.firstAvailableWeekOf;
    const lastAvailableWeekOf = response.lastAvailableWeekOf;
    if (selectedWeekOf === "none") {
      selectedWeekOf = firstAvailableWeekOf;
    } else {
      selectedWeekOf = DateTime.fromISO(selectedWeekOf, {
        zone: "UTC",
      }).toISO();
    }

    if (selectedWeekOf > firstAvailableWeekOf) {
      availabilities.previousWeekHasAvailability = true;
    }

    if (selectedWeekOf < lastAvailableWeekOf) {
      availabilities.nextWeekHasAvailability = true;
    }
  } catch (error) {
    console.log("An error occurred while retrieving timeslots");
  }

  return {
    therapist,
    group,
    timeSlots,
    requestId,
    selectedWeekOf,
    availabilities,
  };
}

const SelectTimeSlotPage = () => {
  const {
    therapist,
    group,
    timeSlots,
    requestId,
    selectedWeekOf,
    availabilities,
  } = useLoaderData();

  const { setTherapistName, setTherapistHandle } = useTherapist();

  const therapistFullName = `${therapist.firstName} ${therapist.lastName}`;
  setTherapistName(therapistFullName);
  setTherapistHandle(therapist.handle);

  return (
    <Container className="main-container">
      <TimeSlotList
        timeSlots={timeSlots}
        group={group}
        encodedEncryptedRequestId={requestId}
        weekOf={selectedWeekOf}
        availabilities={availabilities}
      />
    </Container>
  );
};

export default SelectTimeSlotPage;

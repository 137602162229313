import { Card } from "react-bootstrap";
import StatusLabel from "../shared/StatusLabel.js";

const GroupGridItem = ({
  name,
  status,
  onClick,
  size,
  approvedRequestCount,
  pendingRequestCount,
  waitlistedRequestCount,
}) => {
  return (
    <Card style={{ cursor: "pointer" }} onClick={onClick}>
      <Card.Body>
        <Card.Title>{name}</Card.Title>
        <div className="vertical-spacer-3" />
        <div style={{ height: "3rem" }} className="d-flex flex-column">
          {status === "OPEN_FOR_ENROLLMENT" && (
            <>
              <p className="group-card-detail">
                Approved Members: {approvedRequestCount}/{size}
              </p>
              <p className="group-card-detail">
                Pending Requests: {pendingRequestCount}
              </p>
            </>
          )}
          {status === "DRAFT" && (
            <p className="group-card-detail">
              Waitlisted Requests: {waitlistedRequestCount}
            </p>
          )}
        </div>
        <StatusLabel status={status} viewType="grid" />
      </Card.Body>
    </Card>
  );
};

export default GroupGridItem;

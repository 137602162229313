import { useState, useEffect } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import {
  getGroupDetails,
  getTherapistDetails,
  updateGroupEssentials,
} from "../../../services/api.js";
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  InputGroup,
  Form,
  Alert,
  Dropdown,
  Spinner,
} from "react-bootstrap";
import StatusLabel from "../../../components/shared/StatusLabel.js";

export async function loader({ params }) {
  let group = {};
  let therapist;

  try {
    therapist = await getTherapistDetails({ includeQuestions: true });
    group = await getGroupDetails({
      groupId: params.groupId,
      includeRequests: false,
      includeQuestions: false,
    });
  } catch (error) {
    console.log("An error occurred while fetching the group details");
  }

  return { group, therapist };
}

const EditGroupEssentialsPage = () => {
  const { group, therapist } = useLoaderData();
  const navigate = useNavigate();

  const [selectedName, setSelectedName] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedGroupSize, setSelectedGroupSize] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [locationsArray, setLocationsArray] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState("");
  const [allowUpdate, setAllowUpdate] = useState("NONE"); // NONE, BASIC or FULL

  useEffect(() => {
    if (group) {
      setSelectedName(group.name);
      setSelectedDescription(group.description);
      setSelectedGroupSize(group.size);
      setSelectedLocation(group.location);
      const selectedAddress = therapist.location.find(
        (loc) => loc.label === group.location,
      )?.address;
      setSelectedAddress(selectedAddress);
      if (group.status === "DRAFT") {
        setAllowUpdate("FULL");
      } else if (group.status === "COMPLETED") {
        setAllowUpdate("NONE");
      } else {
        setAllowUpdate("BASIC");
      }
    }
    if (therapist) {
      setLocationsArray(therapist.location);
    }
  }, [group, therapist]);

  const handleSaveButton = async () => {
    setIsProcessing(true);
    try {
      await updateGroupEssentials(
        group._id,
        selectedName,
        selectedDescription,
        selectedGroupSize,
        selectedLocation,
      );
      navigate(`/admin/groups/${group._id}`);
    } catch (error) {
      console.error(error);
      setError(error.message || "An unexpected error occurred.");
    } finally {
      setIsProcessing(false);
    }
  };

  const handleBackButton = () => {
    navigate(`/admin/groups/${group._id}`);
  };

  const handleLocationSelect = (locationLabel) => {
    const selectedLoc = locationsArray.find(
      (location) => location.label === locationLabel,
    );
    setSelectedLocation(selectedLoc.label);
    setSelectedAddress(selectedLoc.address);
  };

  return (
    <Container className="settings-header-container" fluid>
      <Row
        className="d-flex justify-content-between align-items-center px-4"
        style={{ height: "7rem" }}
      >
        <Col>
          <h4 style={{ marginBottom: "0px" }}>
            {group.name} | Group Essentials
          </h4>
          <StatusLabel status={group.status} />
        </Col>
        <Col
          className="d-flex align-items-center justify-content-end"
          style={{ paddingRight: "0" }}
        >
          <Button
            className="secondary-button pill-button"
            onClick={handleBackButton}
          >
            Back
          </Button>
          {allowUpdate !== "NONE" && (
            <Button
              className="primary-button pill-button"
              onClick={handleSaveButton}
              style={{ marginLeft: "10px" }}
            >
              {isProcessing && (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                </>
              )}
              Save
            </Button>
          )}
        </Col>
      </Row>
      <hr style={{ marginTop: "0px" }} />
      <Container className="main-container">
        <Row xs={1}>
          <InputGroup className="mb-3">
            <InputGroup.Text style={{ minWidth: "110px" }}>
              Name
            </InputGroup.Text>
            <Form.Control
              value={selectedName}
              onChange={(e) => setSelectedName(e.currentTarget.value)}
              type="text"
              disabled={allowUpdate === "NONE"}
            />
          </InputGroup>
        </Row>
        <Row xs={1}>
          <InputGroup className="mb-3">
            <InputGroup.Text style={{ minWidth: "110px" }}>
              Description
            </InputGroup.Text>
            <Form.Control
              value={selectedDescription}
              onChange={(e) => setSelectedDescription(e.currentTarget.value)}
              as="textarea"
              rows={1}
              disabled={allowUpdate === "NONE"}
            />
          </InputGroup>
        </Row>
        <Row xs={1}>
          <InputGroup className="mb-3">
            <InputGroup.Text style={{ minWidth: "110px" }}>
              Group Size
            </InputGroup.Text>
            <Form.Control
              value={selectedGroupSize}
              onChange={(e) => setSelectedGroupSize(e.currentTarget.value)}
              type="number"
              style={{ maxWidth: "70px" }}
              disabled={allowUpdate !== "FULL"}
            />
          </InputGroup>
        </Row>
        <Row xs={1}>
          <InputGroup className="mb-3">
            <Dropdown as={ButtonGroup} onSelect={handleLocationSelect}>
              <Button
                style={{
                  minWidth: "110px",
                  textAlign: "left",
                  backgroundColor: "var(--bs-tertiary-bg)",
                  borderColor: "var(--bs-border-color)",
                  color: "var(--bs-body-color)",
                  cursor: "text",
                }}
                variant="outline-secondary"
              >
                Location
              </Button>
              {selectedLocation && (
                <div
                  className={`form-control ${allowUpdate !== "FULL" ? "disabled-input-div" : ""}`}
                >
                  {selectedLocation} - {selectedAddress}
                </div>
              )}
              <Dropdown.Toggle
                variant="outline-secondary"
                id="dropdown-basic"
                split
                style={{
                  backgroundColor: "var(--bs-white)",
                  borderColor: "var(--bs-border-color)",
                  color: "var(--bs-body-color)",
                  borderLeft: "none",
                  marginLeft: "0.5px",
                }}
                disabled={allowUpdate !== "FULL"}
              />
              <Dropdown.Menu>
                {locationsArray.map((location, index) => (
                  <Dropdown.Item key={index} eventKey={location.label}>
                    <span style={{ fontWeight: "500" }}>{location.label}</span>{" "}
                    - {location.address}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </InputGroup>
        </Row>
        {error && (
          <Alert className="mt-2" variant="danger">
            {error}
          </Alert>
        )}
      </Container>
    </Container>
  );
};

export default EditGroupEssentialsPage;

import { getAllGroupDetails, getRequestStatus } from "../../services/api.js";
import { useLoaderData } from "react-router-dom";
import ClientMoveConsent from "../../components/public/ClientMoveConsent.js";
import { Container } from "react-bootstrap";

export async function loader({ params, request }) {
  const encodedEncryptedRequestId = params.requestId;

  const url = new URL(request.url);
  const queryParams = new URLSearchParams(url.search);
  const groupId = queryParams.get("groupId");

  let group;
  let requestSubStatus;

  try {
    const request = await getRequestStatus(encodedEncryptedRequestId);

    // Check if the client is using an up to date url to access the Move page
    // It would be out of date if the therapist moves them multiple times
    if (groupId === request.groupId) {
      requestSubStatus = request.subStatus;
    }

    const response = await getAllGroupDetails(groupId);
    group = response.group;
  } catch (error) {
    console.log("An error occurred while fetching group");
  }

  return {
    group,
    encodedEncryptedRequestId,
    requestSubStatus,
  };
}

const MoveConsentPage = () => {
  const { group, encodedEncryptedRequestId, requestSubStatus } =
    useLoaderData();
  return (
    <Container className="main-container">
      <ClientMoveConsent
        group={group}
        requestId={encodedEncryptedRequestId}
        subStatus={requestSubStatus}
      />
    </Container>
  );
};

export default MoveConsentPage;

import { useEffect } from "react";
import { useLoaderData, useNavigate, NavLink } from "react-router-dom";
import { getGroupDetails, publishDraft } from "../../../services/api.js";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Spinner,
  Alert,
} from "react-bootstrap";
import StatusLabel from "../../../components/shared/StatusLabel.js";
import GroupEssentials from "../../../components/admin/GroupEssentials.js";
import SessionInformation from "../../../components/admin/SessionInformation.js";
import QuestionForm from "../../../components/shared/QuestionForm.js";
import RequestListItem from "../../../components/admin/RequestListItem.js";
import AddMemberModal from "../../../components/admin/AddMemberModal.js";
import { useState } from "react";

export async function loader({ params }) {
  let group = {};
  let requests = [];

  try {
    const detailedGroupResponse = await getGroupDetails({
      groupId: params.groupId,
      includeRequests: true,
      includeQuestions: true,
    });
    const { requests: detailedRequests, ...detailedGroup } =
      detailedGroupResponse;

    group = detailedGroup;
    requests = detailedRequests;
  } catch (error) {
    console.log("An error occurred while fetching the group details");
  }

  return { group, requests };
}

const GroupDetailsPage = () => {
  const { group, requests } = useLoaderData();
  const navigate = useNavigate();

  const filterRequestsByStatus = (status) => {
    return requests?.filter((request) => request.status === status) || [];
  };

  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [requestEmailList, setRequestEmailList] = useState([]);

  const approvedRequests = filterRequestsByStatus("APPROVED");
  const pendingRequests = filterRequestsByStatus("PENDING");
  const rejectedRequests = filterRequestsByStatus("REJECTED");
  const waitlistedRequests = filterRequestsByStatus("WAITLISTED");
  const withdrawnRequests = filterRequestsByStatus("WITHDRAWN");

  const hasApprovedRequest = approvedRequests.length > 0;
  const hasPendingRequest = pendingRequests.length > 0;
  const hasRejectedRequest = rejectedRequests.length > 0;
  const hasWaitlistedRequests = waitlistedRequests.length > 0;
  const hasWithdrawnRequest = withdrawnRequests.length > 0;

  const [showWaitlist, setShowWaitlist] = useState(false);
  const [showApproved, setShowApproved] = useState(false);
  const [showPending, setShowPending] = useState(false);
  const [showRejected, setShowRejected] = useState(false);
  const [showWithdrawn, setShowWithdrawn] = useState(false);

  const [showEssentialDetails, setShowEssentialDetails] = useState(false);
  const [allowEssentialsUpdate, setAllowEssentialsUpdate] = useState(false);
  const [showSessionDetails, setShowSessionDetails] = useState(false);
  const [allowSessionUpdate, setAllowSessionUpdate] = useState(false);
  const [showQuestionDetails, setShowQuestionDetails] = useState(false);
  const [allowQuestionsUpdate, setAllowQuestionsUpdate] = useState(false);

  const [error, setError] = useState("");
  const [isProcessing, setIsProcessing] = useState("");

  useEffect(() => {
    if (group) {
      const newState = {
        showWaitlist: false,
        showApproved: false,
        showPending: false,
        showRejected: false,
        showWithdrawn: false,
        showEssentialDetails: false,
        allowEssentialsUpdate: false,
        showSessionDetails: false,
        allowSessionUpdate: false,
        showQuestionDetails: false,
        allowQuestionsUpdate: false,
      };

      switch (group.status) {
        case "DRAFT":
          newState.showWaitlist = true;
          newState.showEssentialDetails = true;
          newState.allowEssentialsUpdate = true;
          newState.showSessionDetails = true;
          newState.allowSessionUpdate = true;
          newState.showQuestionDetails = true;
          newState.allowQuestionsUpdate = true;
          break;
        case "OPEN_FOR_ENROLLMENT":
          newState.showPending = true;
          newState.showApproved = true;
          newState.allowEssentialsUpdate = true;
          newState.allowQuestionsUpdate = true;
          break;
        case "ENROLLMENT_COMPLETE":
        case "IN_PROGRESS":
          newState.showApproved = true;
          newState.allowEssentialsUpdate = true;
          break;
        case "COMPLETED":
          newState.showApproved = true;
          break;
        default:
          break;
      }

      setShowWaitlist(newState.showWaitlist);
      setShowApproved(newState.showApproved);
      setShowPending(newState.showPending);
      setShowRejected(newState.showRejected);
      setShowWithdrawn(newState.showWithdrawn);
      setShowEssentialDetails(newState.showEssentialDetails);
      setAllowEssentialsUpdate(newState.allowEssentialsUpdate);
      setShowSessionDetails(newState.showSessionDetails);
      setAllowSessionUpdate(newState.allowSessionUpdate);
      setShowQuestionDetails(newState.showQuestionDetails);
      setAllowQuestionsUpdate(newState.allowQuestionsUpdate);
    }
  }, [group, hasApprovedRequest]);

  const handleRequestCardClick = (request) => {
    navigate(`/admin/groups/${group._id}/request/${request._id}`);
  };

  const renderRequests = (requestsList) => {
    return (
      <Row xs={1} md={2} className="g-4">
        {requestsList.map((request, idx) => (
          <Col key={idx}>
            <RequestListItem
              firstName={request.firstName}
              lastName={request.lastName}
              emailAddress={request.emailAddress}
              phoneNumber={request.phoneNumber}
              onCardClick={() => handleRequestCardClick(request)}
              subStatus={request.subStatus}
            />
          </Col>
        ))}
      </Row>
    );
  };

  const handleShowDetailChange = (section) => {
    if (section === "Essential") {
      setShowEssentialDetails(!showEssentialDetails);
    } else if (section === "Session") {
      setShowSessionDetails(!showSessionDetails);
    } else if (section === "Question") {
      setShowQuestionDetails(!showQuestionDetails);
    } else if (section === "Waitlist") {
      setShowWaitlist(!showWaitlist);
    } else if (section === "Approved") {
      setShowApproved(!showApproved);
    } else if (section === "Pending") {
      setShowPending(!showPending);
    } else if (section === "Rejected") {
      setShowRejected(!showRejected);
    } else if (section === "Withdrawn") {
      setShowWithdrawn(!showWithdrawn);
    }
  };

  const handleAddMemberButton = () => {
    const emailList = requests.map((request) => request.emailAddress);
    setRequestEmailList(emailList);
    setShowAddMemberModal(true);
  };

  const handleCloseAddMemberModal = () => {
    setShowAddMemberModal(false);
  };

  const handleAddMemberResult = (isSuccess) => {
    if (isSuccess) {
      navigate(0);
    } else {
      setError("An unexpected error occurred.");
    }
  };

  const handlePublishButton = async () => {
    setIsProcessing(true);
    try {
      await publishDraft(group._id);
      navigate(0);
    } catch (error) {
      console.error(error);
      setError(error.message || "An unexpected error occurred.");
    } finally {
      setIsProcessing(false);
    }
  };

  const handleBackButton = () => {
    navigate("/admin/groups");
  };

  return (
    <>
      <Container className="settings-header-container" fluid>
        <Row
          className="d-flex justify-content-between align-items-center px-4"
          style={{ height: "7rem" }}
        >
          <Col>
            <h4 style={{ marginBottom: "0px" }}>{group.name}</h4>
            <StatusLabel status={group.status} />
          </Col>
          <Col
            className="d-flex align-items-center justify-content-end"
            style={{ paddingRight: "0" }}
          >
            <Button
              className="secondary-button pill-button"
              onClick={handleBackButton}
            >
              Back
            </Button>
            {group.status === "DRAFT" && (
              <Button
                className="primary-button pill-button"
                onClick={handlePublishButton}
                style={{ marginLeft: "10px" }}
              >
                {isProcessing && (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                  </>
                )}
                Publish
              </Button>
            )}
          </Col>
        </Row>
        <hr style={{ marginTop: "0px" }} />
      </Container>
      <Container className="main-container">
        {error && (
          <Alert className="mb-4" variant="danger">
            {error}
          </Alert>
        )}
        {showAddMemberModal && (
          <AddMemberModal
            show={showAddMemberModal}
            onHide={handleCloseAddMemberModal}
            groupId={group._id}
            emailList={requestEmailList}
            onAddMemberResult={handleAddMemberResult}
          />
        )}
        {hasWaitlistedRequests && (
          <Card className="mb-5">
            <Card.Header className="d-flex align-items-center justify-content-between">
              <Card.Title style={{ marginBottom: "0px" }}>
                Waitlisted Requests
              </Card.Title>
              <Button
                className="secondary-button"
                onClick={() => handleShowDetailChange("Waitlist")}
              >
                {!showWaitlist ? "Show Details" : "Hide Details"}
              </Button>
            </Card.Header>
            {showWaitlist && (
              <Card.Body>{renderRequests(waitlistedRequests)}</Card.Body>
            )}
          </Card>
        )}

        {group.status !== "DRAFT" && (
          <Card className="mb-5">
            <Card.Header className="d-flex align-items-center justify-content-between">
              <Card.Title style={{ marginBottom: "0px" }}>
                Approved Members
              </Card.Title>
              <Button
                className="primary-button ms-auto"
                style={{ marginRight: "10px" }}
                onClick={handleAddMemberButton}
              >
                Add Member
              </Button>
              <Button
                className="secondary-button"
                onClick={() => handleShowDetailChange("Approved")}
              >
                {!showApproved ? "Show Details" : "Hide Details"}
              </Button>
            </Card.Header>
            {showApproved && (
              <Card.Body>
                {hasApprovedRequest ? (
                  <>{renderRequests(approvedRequests)}</>
                ) : (
                  <p>
                    There are currently no approved members. They will appear
                    here once approved, or you can manually add a member using
                    the "Add Member" button.
                  </p>
                )}
              </Card.Body>
            )}
          </Card>
        )}

        {group.status !== "DRAFT" && (
          <Card className="mb-5">
            <Card.Header className="d-flex align-items-center justify-content-between">
              <Card.Title style={{ marginBottom: "0px" }}>
                Pending Requests
              </Card.Title>
              <Button
                className="secondary-button"
                onClick={() => handleShowDetailChange("Pending")}
              >
                {!showPending ? "Show Details" : "Hide Details"}
              </Button>
            </Card.Header>
            {showPending && (
              <Card.Body>
                {hasPendingRequest ? (
                  <>{renderRequests(pendingRequests)}</>
                ) : (
                  <p>
                    There are currently no pending requests. New requests will
                    appear here.
                  </p>
                )}
              </Card.Body>
            )}
          </Card>
        )}

        {hasRejectedRequest && (
          <Card className="mb-5">
            <Card.Header className="d-flex align-items-center justify-content-between">
              <Card.Title style={{ marginBottom: "0px" }}>
                Rejected Requests
              </Card.Title>
              <Button
                className="secondary-button"
                onClick={() => handleShowDetailChange("Rejected")}
              >
                {!showRejected ? "Show Details" : "Hide Details"}
              </Button>
            </Card.Header>
            {showRejected && (
              <Card.Body>{renderRequests(rejectedRequests)}</Card.Body>
            )}
          </Card>
        )}

        {hasWithdrawnRequest && (
          <Card className="mb-5">
            <Card.Header className="d-flex align-items-center justify-content-between">
              <Card.Title style={{ marginBottom: "0px" }}>
                Withdrawn Requests
              </Card.Title>
              <Button
                className="secondary-button"
                onClick={() => handleShowDetailChange("Withdrawn")}
              >
                {!showWithdrawn ? "Show Details" : "Hide Details"}
              </Button>
            </Card.Header>
            {showWithdrawn && (
              <Card.Body>{renderRequests(withdrawnRequests)}</Card.Body>
            )}
          </Card>
        )}

        <Card className="mb-5">
          <Card.Header className="d-flex align-items-center justify-content-between">
            <Card.Title style={{ marginBottom: "0px" }}>
              Group Essentials
            </Card.Title>
            <div>
              <NavLink
                className="primary-button"
                to={`/admin/groups/${group._id}/essentials`}
                style={{ marginRight: "10px" }}
                disabled={!allowEssentialsUpdate}
              >
                Manage
              </NavLink>
              <Button
                className="secondary-button"
                onClick={() => handleShowDetailChange("Essential")}
              >
                {!showEssentialDetails ? "Show Details" : "Hide Details"}
              </Button>
            </div>
          </Card.Header>
          {showEssentialDetails && (
            <Card.Body>
              <GroupEssentials group={group} />
            </Card.Body>
          )}
        </Card>

        <Card className="mb-5">
          <Card.Header className="d-flex align-items-center justify-content-between">
            <Card.Title style={{ marginBottom: "0px" }}>
              Session Information
            </Card.Title>
            <div>
              <NavLink
                className="primary-button"
                to={`/admin/groups/${group._id}/sessions`}
                style={{ marginRight: "10px" }}
                disabled={!allowSessionUpdate}
              >
                Manage
              </NavLink>
              <Button
                className="secondary-button"
                onClick={() => handleShowDetailChange("Session")}
              >
                {!showSessionDetails ? "Show Details" : "Hide Details"}
              </Button>
            </div>
          </Card.Header>
          {showSessionDetails && (
            <Card.Body>
              <SessionInformation group={group} />
            </Card.Body>
          )}
        </Card>

        <Card className="mb-5">
          <Card.Header className="d-flex align-items-center justify-content-between">
            <Card.Title style={{ marginBottom: "0px" }}>
              Pre-Screening Questionnaire
            </Card.Title>
            <div>
              <NavLink
                className="primary-button"
                to={`/admin/groups/${group._id}/questions`}
                style={{ marginRight: "10px" }}
                disabled={!allowQuestionsUpdate}
              >
                Manage
              </NavLink>
              <Button
                className="secondary-button"
                onClick={() => handleShowDetailChange("Question")}
              >
                {!showQuestionDetails ? "Show Details" : "Hide Details"}
              </Button>
            </div>
          </Card.Header>
          {showQuestionDetails && (
            <Card.Body>
              {group.questions.length > 0 ? (
                <QuestionForm
                  questions={group.questions}
                  language={group.language}
                />
              ) : (
                <p>
                  No questions selected for the questionnaire. Use the "Manage"
                  button to create a pre-screening questionnaire.
                </p>
              )}
            </Card.Body>
          )}
        </Card>
      </Container>
    </>
  );
};

export default GroupDetailsPage;

import { useState } from "react";
import { Modal, Button, Alert, Spinner } from "react-bootstrap";

const ApprovalModal = ({
  show,
  onHide,
  header,
  body,
  handleAction,
  action,
  error,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const onActionClick = async () => {
    setIsProcessing(true);
    try {
      await handleAction();
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {body}
        <br />
        <br />
        Do you want to continue with this action?
        {error && (
          <Alert className="mt-2" variant="danger">
            {error}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="secondary-button pill-button"
          onClick={onHide}
          disabled={isProcessing}
        >
          Cancel
        </Button>
        <Button
          className="primary-button pill-button"
          onClick={onActionClick}
          disabled={isProcessing}
        >
          {isProcessing && (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
            </>
          )}
          {action}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ApprovalModal;

import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Stack,
  Spinner,
  Alert,
} from "react-bootstrap";
import { useState } from "react";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-number-input";
import en from "react-phone-number-input/locale/en.json";
import tr from "react-phone-number-input/locale/tr.json";
import { createRequest } from "../../services/api.js";
import { useNavigate } from "react-router-dom";
import { formatDateToAmerican } from "../../utils/utils.js";
import StatusToast from "../shared/StatusToast.js";
import GroupDetailSummary from "../admin/GroupDetailSummary.js";

const EnrollmentForm = ({
  group,
  questions,
  therapistHandle,
  timeZone,
  backTo,
}) => {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [birthDate, setBirthDate] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [error, setError] = useState("");

  const days = ["Pt", "Sa", "Ça", "Pe", "Cu", "Ct", "Pz"];
  const months = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ];

  const localeTR = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => "mm/dd/yyyy",
    },
  };

  const CustomInput = React.forwardRef(
    ({ value, onClick, placeholder }, ref) => (
      <button
        className="form-control datepicker-custom-input"
        onClick={onClick}
        ref={ref}
        type="button"
      >
        {value || placeholder}
      </button>
    ),
  );

  function generateFormRows(questions) {
    return questions.map((question) => {
      const { _id, isRequired, type, text, options } = question;
      const prefixedId = `q-${_id}`;

      const renderQuestion = () => {
        switch (type) {
          case "LONG_TEXT":
            return (
              <Form.Control
                as="textarea"
                id={prefixedId}
                placeholder={
                  group.language === "tr_TR"
                    ? "Cevabınızı yazınız..."
                    : "Your answer here..."
                }
                name={prefixedId}
                rows={3}
              />
            );
          case "SHORT_TEXT":
            return (
              <Form.Control
                type="text"
                id={prefixedId}
                placeholder={
                  group.language === "tr_TR"
                    ? "Cevabınızı yazınız..."
                    : "Your answer here..."
                }
                name={prefixedId}
              />
            );
          case "YES_NO":
            return (
              <div>
                <Form.Check
                  type="radio"
                  label="Yes"
                  name={prefixedId}
                  id={`${prefixedId}-yes`}
                  value={group.language === "tr_TR" ? "Evet" : "Yes"}
                />
                <Form.Check
                  type="radio"
                  label="No"
                  name={prefixedId}
                  id={`${prefixedId}-no`}
                  value={group.language === "tr_TR" ? "Hayır" : "No"}
                />
              </div>
            );
          case "NUMBER":
            return (
              <Form.Control
                type="number"
                id={prefixedId}
                placeholder="0"
                name={prefixedId}
              />
            );
          case "CONSENT":
            return (
              <Form.Check
                type="checkbox"
                id={prefixedId}
                label={
                  <>
                    {text}
                    {isRequired && (
                      <small>
                        <strong>*</strong>
                      </small>
                    )}
                  </>
                }
                name={prefixedId}
                value="Checked"
              />
            );
          case "SINGLE_CHOICE":
            return (
              <div>
                {options.map((option, index) => (
                  <Form.Check
                    key={index}
                    type="radio"
                    label={option}
                    name={prefixedId}
                    id={`${prefixedId}-${index}`}
                    value={option}
                  />
                ))}
              </div>
            );
          case "MULTI_CHOICE":
            return (
              <div>
                {options.map((option, index) => (
                  <Form.Check
                    key={index}
                    type="checkbox"
                    label={option}
                    id={`${prefixedId}-${index}`}
                    name={prefixedId}
                    value={option}
                  />
                ))}
              </div>
            );
          default:
            return null;
        }
      };

      return (
        <>
          <hr style={{ width: "40%", margin: "2rem auto" }} />
          <Row className="mt-2" key={_id}>
            <Form.Group as={Col}>
              {type !== "CONSENT" && (
                <Form.Label>
                  {text}
                  {isRequired && (
                    <small>
                      <strong>*</strong>
                    </small>
                  )}
                </Form.Label>
              )}
              {renderQuestion()}
            </Form.Group>
          </Row>
        </>
      );
    });
  }

  const handleBackButton = () => {
    if (backTo === "homepage") {
      navigate("/");
    } else if (backTo === "therapist") {
      navigate(`/therapists/${therapistHandle}`);
    }
  };

  function processFormData(questions, formData) {
    let data = {};
    let isRequiredQuestionUnanswered = false;

    questions.forEach((question) => {
      const prefixedId = `q-${question._id}`;
      let formValue = formData.get(prefixedId);

      switch (question.type) {
        case "YES_NO":
        case "SINGLE_CHOICE":
          // These types will have single values directly from formData.get
          break;
        case "CONSENT":
          formValue = formData.get(prefixedId) ? "Checked" : "";
          break;
        case "MULTI_CHOICE":
          // Get all checked values and join them with a comma
          formValue = formData.getAll(prefixedId).join(", ");
          break;
        default:
          break;
      }

      if (question.isRequired && !formValue) {
        isRequiredQuestionUnanswered = true;
      }

      if (!question.isRequired && (formValue === "" || formValue === null)) {
        data[prefixedId] = "No answer provided";
      } else {
        data[prefixedId] = formValue ? formValue : "";
      }
    });

    if (isRequiredQuestionUnanswered) {
      return null;
    }

    return data;
  }

  const handleSubmit = async (event) => {
    setError("");
    event.preventDefault();
    const form = event.currentTarget;
    const formData = new FormData(form);

    const errorMessage =
      group.language === "tr_TR"
        ? "Tüm * ile işaretlenmiş alanların doldurulması zorunludur."
        : "All fields marked with * are required.";

    if (
      !birthDate ||
      !phoneNumber ||
      !formData.get("firstName") ||
      !formData.get("lastName") ||
      !formData.get("emailAddress") ||
      !processFormData(questions, formData)
    ) {
      setError(errorMessage);
      return;
    }

    const data = processFormData(questions, formData);
    data.firstName = formData.get("firstName");
    data.lastName = formData.get("lastName");
    data.emailAddress = formData.get("emailAddress");

    data.groupId = group._id;
    data.status = "PENDING";
    data.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    data.birthDate = formatDateToAmerican(birthDate.toISOString());
    data.phoneNumber = phoneNumber;

    try {
      await createRequest(data);
      setSuccess(true);
    } catch (error) {
      if (error.response.status === 409) {
        const conflictMessage =
          group.language === "tr_TR"
            ? "Bu gruba daha önce başvurdunuz. Terapistiniz en kısa sürede size dönüş yapacaktır."
            : "You have already applied to this group. Your therapist will get back to you as soon as possible.";

        setError(conflictMessage);
        setIsSending(false);
        setIsSent(true);
        return;
      }
      console.error("Error submitting answers:", error);
    }

    setShowToast(true);
    setIsSending(false);
    setIsSent(true);
  };

  if (isSent) {
    return (
      <Container className="main-container">
        <p>
          {group.language === "tr_TR"
            ? `${group.name} için yaptığınız başvuru terapistinize gönderilmiştir. Kendisi size en yakın zamanda geri dönüş yapacaktır.`
            : `Your application for ${group.name} has been sent to your therapist. They will get back to you as soon as possible.`}
        </p>
        <div className="d-flex justify-content-center">
          <Button variant="dark" onClick={handleBackButton}>
            Close
          </Button>
        </div>
      </Container>
    );
  }

  return (
    <Container className="main-container">
      <h3 className="mb-3">{group.name}</h3>
      <GroupDetailSummary group={group} timeZone={timeZone} />
      <hr className="my-4" />

      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>
              {group.language === "tr_TR" ? "Ad" : "First Name"}
              <small>
                <strong>*</strong>
              </small>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={
                group.language === "tr_TR" ? "Adınız" : "Your First Name"
              }
              name="firstName"
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>
              {group.language === "tr_TR" ? "Soyad" : "Last Name"}
              <small>
                <strong>*</strong>
              </small>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={
                group.language === "tr_TR" ? "Soyadınız" : "Your Last Name"
              }
              name="lastName"
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} xs={12} sm={12} md={4}>
            <Form.Label>
              {group.language === "tr_TR" ? "Doğum tarihi" : "Birth Date"}
              <small>
                <strong>*</strong>
              </small>
            </Form.Label>
            <br />
            <DatePicker
              placeholderText={
                group.language === "tr_TR" ? "gg/aa/yyyy" : "mm/dd/yyyy"
              }
              selected={birthDate}
              onChange={(date) => setBirthDate(date)}
              dateFormat={
                group.language === "tr_TR" ? "dd/MM/yyyy" : "MM/dd/yyyy"
              }
              showMonthDropdown
              showYearDropdown
              popperPlacement="bottom-start"
              {...(group.language === "tr_TR" ? { locale: localeTR } : {})}
              dropdownMode="select"
              showPopperArrow={false}
              fixedHeight
              maxDate={new Date()}
              customInput={
                <CustomInput
                  placeholder={
                    group.language === "tr_TR" ? "gg/aa/yyyy" : "mm/dd/yyyy"
                  }
                />
              }
            />
          </Form.Group>

          <Form.Group as={Col} xs={12} sm={6} md={4}>
            <Form.Label>
              {group.language === "tr_TR" ? "E-Posta" : "Email"}
              <small>
                <strong>*</strong>
              </small>
            </Form.Label>
            <Form.Control
              type="email"
              placeholder={
                group.language === "tr_TR"
                  ? "E-Posta Adresiniz"
                  : "Your Email Address"
              }
              name="emailAddress"
              style={{ marginBottom: "10px" }}
            />
          </Form.Group>

          <Form.Group as={Col} xs={12} sm={6} md={4}>
            <Form.Label>
              {group.language === "tr_TR" ? "Telefon" : "Phone"}
              <small>
                <strong>*</strong>
              </small>
            </Form.Label>
            <PhoneInput
              placeholder={
                group.language === "tr_TR"
                  ? "Telefon Numaranız"
                  : "Your Phone Number"
              }
              value={phoneNumber}
              onChange={setPhoneNumber}
              defaultCountry={group.language === "tr_TR" ? "TR" : "US"}
              labels={group.language === "tr_TR" ? tr : en}
              className="bootstrap-phone-input"
            />
          </Form.Group>
        </Row>

        {/* TODO: See if QuestionForm would work here*/}
        {/*<QuestionForm questions={questions} language={group.language}/>*/}
        {generateFormRows(questions)}

        {error && (
          <Alert className="mt-3" variant="danger">
            {error}
          </Alert>
        )}

        <Stack className="mt-4" direction="horizontal" gap={3}>
          <Button
            className="ms-auto"
            variant="secondary"
            onClick={handleBackButton}
          >
            {group.language === "tr_TR" ? "Geri" : "Back"}
          </Button>
          <Button variant="success" type="submit" disabled={isSending}>
            {isSending ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                {group.language === "tr_TR"
                  ? " Gönderiliyor..."
                  : " Sending..."}
              </>
            ) : group.language === "tr_TR" ? (
              "Gönder"
            ) : (
              "Send"
            )}
          </Button>
        </Stack>
        <div className="vertical-spacer-2" />

        <StatusToast
          variant={success ? "success" : "danger"}
          text={
            success
              ? group.language === "tr_TR"
                ? "Başvurunuz alınmıştır"
                : "Your application has been received"
              : group.language === "tr_TR"
                ? "Bir hata oluştu, lütfen tekrar deneyiniz"
                : "An error occurred, please try again"
          }
          show={showToast}
          onClose={() => setShowToast(false)}
        />
      </Form>
    </Container>
  );
};

export default EnrollmentForm;

import { useState, useEffect } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import {
  getGroupDetails,
  getTherapistDetails,
  updateSessionInformation,
} from "../../../services/api.js";
import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  Form,
  Alert,
  Spinner,
} from "react-bootstrap";
import StatusLabel from "../../../components/shared/StatusLabel.js";
import { DateTime } from "luxon";

function getTimeZoneAbbreviation(timeZone) {
  const dt = DateTime.now().setZone(timeZone);
  return dt.toFormat("ZZZZ");
}

export async function loader({ params }) {
  let group = {};
  let therapist = {};

  try {
    group = await getGroupDetails({
      groupId: params.groupId,
      includeRequests: false,
      includeQuestions: false,
    });
    therapist = await getTherapistDetails();
  } catch (error) {
    console.log("An error occurred while fetching the group details");
  }

  return { group, therapist };
}

const EditSessionInformationPage = () => {
  const { group, therapist } = useLoaderData();
  const navigate = useNavigate();

  const [selectedFirstSessionDate, setSelectedFirstSessionDate] = useState("");
  const [selectedSessionTime, setSelectedSessionTime] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState(0);
  const [selectedSessionCount, setSelectedSessionCount] = useState(0);
  const [selectedPricePerSession, setSelectedPricePerSession] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState("en_EN");
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState("");
  const [allowUpdate, setAllowUpdate] = useState("NONE"); // NONE, FULL
  const [weeks, setWeeks] = useState(0); // To keep track of the weeks entered by the user

  useEffect(() => {
    if (group) {
      setSelectedFirstSessionDate(
        DateTime.fromISO(group.firstSessionDate, { zone: "utc" }).toISODate(),
      );
      setSelectedSessionTime(group.sessionTime);
      setSelectedSessionCount(group.sessionCount);
      setWeeks(group.sessionCount / group.recurrence.interval);
      setSelectedDuration(group.duration);
      setSelectedPricePerSession(group.pricePerSession);
      setSelectedLanguage(group.language || "en_EN");
      setSelectedDays(group.recurrence.days || []);
      if (group.status === "DRAFT") {
        setAllowUpdate("FULL");
      } else {
        setAllowUpdate("NONE");
      }
    }
  }, [group]);

  const handleSaveButton = async () => {
    setIsProcessing(true);

    // Set Recurrence;

    const selectedRecurrence = {
      type: "WEEKLY",
      days: selectedDays,
      interval: selectedDays.length,
    };

    // Nedense string olarak gonderiyor. Integer'a ceviriyorum
    const numericDuration = selectedDuration * 1;

    try {
      await updateSessionInformation(
        group._id,
        selectedFirstSessionDate,
        selectedSessionTime,
        selectedRecurrence,
        numericDuration,
        selectedSessionCount,
        selectedPricePerSession,
        selectedLanguage,
      );
      setError("");
      navigate(`/admin/groups/${group._id}`);
    } catch (error) {
      console.error(error);
      setError(error.message || "An unexpected error occurred.");
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDayChange = (day) => {
    let updatedDays = [...selectedDays];
    if (updatedDays.includes(day)) {
      updatedDays = updatedDays.filter((d) => d !== day);
    } else {
      updatedDays.push(day);
    }
    setSelectedDays(updatedDays);
    updateSessionCount(weeks, updatedDays.length);
  };

  const handleWeeksChange = (e) => {
    const newWeeks = parseInt(e.currentTarget.value, 10) || 0;
    setWeeks(newWeeks);
    updateSessionCount(newWeeks, selectedDays.length);
  };

  const updateSessionCount = (weeks, daysCount) => {
    const totalSessions = weeks * daysCount;
    setSelectedSessionCount(totalSessions);
  };

  const handleBackButton = () => {
    navigate(`/admin/groups/${group._id}`);
  };

  return (
    <Container className="settings-header-container" fluid>
      <Row
        className="d-flex justify-content-between align-items-center px-4"
        style={{ height: "7rem" }}
      >
        <Col>
          <h4 style={{ marginBottom: "0px" }}>
            {group.name} | Session Information
          </h4>
          <StatusLabel status={group.status} />
        </Col>
        <Col
          className="d-flex align-items-center justify-content-end"
          style={{ paddingRight: "0" }}
        >
          <Button
            className="secondary-button pill-button"
            onClick={handleBackButton}
          >
            Back
          </Button>
          {allowUpdate !== "NONE" && (
            <Button
              className="primary-button pill-button"
              onClick={handleSaveButton}
              style={{ marginLeft: "10px" }}
            >
              {isProcessing && (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                </>
              )}
              Save
            </Button>
          )}
        </Col>
      </Row>
      <hr style={{ marginTop: "0px" }} />
      <Container className="main-container">
        <Row xs={1}>
          <InputGroup className="mb-3">
            <InputGroup.Text style={{ minWidth: "217px" }}>
              First Session Date
            </InputGroup.Text>
            <Form.Control
              value={selectedFirstSessionDate}
              onChange={(e) =>
                setSelectedFirstSessionDate(e.currentTarget.value)
              }
              type="date"
              disabled={allowUpdate === "NONE"}
              style={{ maxWidth: "164px" }}
            />
          </InputGroup>
        </Row>
        <Row xs={1}>
          <InputGroup className="mb-3">
            <InputGroup.Text style={{ minWidth: "217px" }}>
              Sessions Start Time
            </InputGroup.Text>
            <Form.Control
              value={selectedSessionTime}
              onChange={(e) => setSelectedSessionTime(e.currentTarget.value)}
              type="time"
              disabled={allowUpdate === "NONE"}
              style={{ maxWidth: "164px" }}
            />
            <InputGroup.Text>
              {getTimeZoneAbbreviation(therapist.TimeZone)}
            </InputGroup.Text>
          </InputGroup>
        </Row>
        <Row xs={1}>
          <div className="d-flex">
            <InputGroup className="mb-3 align-items-center">
              <InputGroup.Text
                style={{ minWidth: "217px", marginRight: "10px", gap: "9px" }}
              >
                Weekly Session Days
              </InputGroup.Text>
              <Form.Check
                inline
                label="Mon"
                type="checkbox"
                id="inline-checkbox-mon"
                onChange={() => handleDayChange("Monday")}
                checked={selectedDays.includes("Monday")}
                disabled={allowUpdate === "NONE"}
              />
              <Form.Check
                inline
                label="Tue"
                type="checkbox"
                id="inline-checkbox-tue"
                onChange={() => handleDayChange("Tuesday")}
                checked={selectedDays.includes("Tuesday")}
                disabled={allowUpdate === "NONE"}
              />
              <Form.Check
                inline
                label="Wed"
                type="checkbox"
                id="inline-checkbox-wed"
                onChange={() => handleDayChange("Wednesday")}
                checked={selectedDays.includes("Wednesday")}
                disabled={allowUpdate === "NONE"}
              />
              <Form.Check
                inline
                label="Thu"
                type="checkbox"
                id="inline-checkbox-thu"
                onChange={() => handleDayChange("Thursday")}
                checked={selectedDays.includes("Thursday")}
                disabled={allowUpdate === "NONE"}
              />
              <Form.Check
                inline
                label="Fri"
                type="checkbox"
                id="inline-checkbox-fri"
                onChange={() => handleDayChange("Friday")}
                checked={selectedDays.includes("Friday")}
                disabled={allowUpdate === "NONE"}
              />
              <Form.Check
                inline
                label="Sat"
                type="checkbox"
                id="inline-checkbox-sat"
                onChange={() => handleDayChange("Saturday")}
                checked={selectedDays.includes("Saturday")}
                disabled={allowUpdate === "NONE"}
              />
              <Form.Check
                inline
                label="Sun"
                type="checkbox"
                id="inline-checkbox-sun"
                onChange={() => handleDayChange("Sunday")}
                checked={selectedDays.includes("Sunday")}
                disabled={allowUpdate === "NONE"}
              />
            </InputGroup>
          </div>
        </Row>

        <Row xs={1}>
          <InputGroup className="mb-3">
            <InputGroup.Text style={{ minWidth: "217px" }}>
              Repeat Sessions for
            </InputGroup.Text>
            <Form.Control
              value={weeks}
              onChange={handleWeeksChange}
              type="number"
              disabled={allowUpdate === "NONE"}
              style={{ maxWidth: "64px" }}
            />
            <InputGroup.Text>weeks</InputGroup.Text>
          </InputGroup>
        </Row>
        <Row xs={1}>
          <InputGroup className="mb-3">
            <InputGroup.Text style={{ minWidth: "217px" }}>
              Session Duration
            </InputGroup.Text>
            <Form.Control
              value={selectedDuration}
              onChange={(e) => setSelectedDuration(e.currentTarget.value)}
              type="number"
              style={{ maxWidth: "78px" }}
              disabled={allowUpdate === "NONE"}
            />
            <InputGroup.Text style={{ maxWidth: "60px" }}>mins</InputGroup.Text>
          </InputGroup>
        </Row>
        <Row xs={1}>
          <InputGroup className="mb-3">
            <InputGroup.Text style={{ minWidth: "217px" }}>
              Session Fee (per Member)
            </InputGroup.Text>
            <Form.Control
              value={selectedPricePerSession}
              onChange={(e) =>
                setSelectedPricePerSession(e.currentTarget.value)
              }
              type="number"
              style={{ maxWidth: "78px" }}
              disabled={allowUpdate === "NONE"}
            />
            <InputGroup.Text style={{ minWidth: "60px" }}>USD</InputGroup.Text>
          </InputGroup>
        </Row>
        {error && (
          <Alert className="mt-2" variant="danger">
            {error}
          </Alert>
        )}
      </Container>
    </Container>
  );
};

export default EditSessionInformationPage;

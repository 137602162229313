import { DateTime } from "luxon";

export const getMonthNameInTurkish = (monthNumber) => {
  switch (monthNumber) {
    case 1:
      return "Ocak";
    case 2:
      return "Şubat";
    case 3:
      return "Mart";
    case 4:
      return "Nisan";
    case 5:
      return "Mayıs";
    case 6:
      return "Haziran";
    case 7:
      return "Temmuz";
    case 8:
      return "Ağustos";
    case 9:
      return "Eylül";
    case 10:
      return "Ekim";
    case 11:
      return "Kasım";
    case 12:
      return "Aralık";
    default:
      return "Geçersiz ay";
  }
};

export const getMonthNameInEnglish = (monthNumber) => {
  switch (monthNumber) {
    case 1:
      return "January";
    case 2:
      return "February";
    case 3:
      return "March";
    case 4:
      return "April";
    case 5:
      return "May";
    case 6:
      return "June";
    case 7:
      return "July";
    case 8:
      return "August";
    case 9:
      return "September";
    case 10:
      return "October";
    case 11:
      return "November";
    case 12:
      return "December";
    default:
      return "Invalid month";
  }
};

export const formatDateToAmerican = (date) => {
  const dateTime = DateTime.fromISO(date);
  const dayOfWeek = dateTime.toFormat("cccc"); // Full name of the day
  const formattedMonth = dateTime.toFormat("LLLL"); // Full name of the month
  const formattedDay = dateTime.toFormat("d"); // Day of the month
  const year = dateTime.toFormat("yyyy"); // Year
  const daySuffix = (n) => {
    const s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  return `${dayOfWeek} ${formattedMonth} ${daySuffix(formattedDay)}, ${year}`;
};

export const formatDateToTurkish = (date) => {
  const dateTime = new Date(date);

  const utcYear = dateTime.getUTCFullYear();
  const utcMonth = dateTime.getUTCMonth() + 1;
  const utcDay = dateTime.getUTCDate();

  const formattedMonth = getMonthNameInTurkish(utcMonth);
  const formattedDay = utcDay < 10 ? `0${utcDay}` : utcDay;

  return `${formattedDay} ${formattedMonth} ${utcYear}`;
};

export const formatTimeFromDateToHMMaa = (date) => {
  const dateTime = new Date(date);
  let hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedDateTime = `${hours}:${formattedMinutes} ${ampm}`;

  return formattedDateTime;
};

export const formatTimeFromDateToHHMM = (date) => {
  const dateTime = new Date(date);
  const hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedDateTime = `${formattedHours}:${formattedMinutes}`;

  return `${formattedDateTime}`;
};

export const formatCurrency = (currency) => {
  return currency === "TRY" ? "TL" : currency;
};

export const formatRecurrenceTurkish = (recurrence) => {
  const daysInTurkish = {
    Sunday: "Pazar",
    Monday: "Pazartesi",
    Tuesday: "Salı",
    Wednesday: "Çarşamba",
    Thursday: "Perşembe",
    Friday: "Cuma",
    Saturday: "Cumartesi",
  };

  const days = recurrence.days.map((day) => daysInTurkish[day]);
  let recurrenceString = "her ";
  if (recurrence.interval === 1) {
    recurrenceString += days[0] + " günü";
  } else {
    recurrenceString +=
      days.slice(0, -1).join(", ") + " ve " + days[days.length - 1] + " günü";
  }

  return recurrenceString;
};

export const formatRecurrenceAmerican = (recurrence) => {
  if (!recurrence || !recurrence.days || recurrence.days.length === 0) {
    return "-";
  }
  const days = recurrence.days.map((day) => day);
  let recurrenceString = "weekly on ";
  if (recurrence.interval === 1) {
    recurrenceString += days[0];
    recurrenceString += "s";
  } else {
    if (days.length > 1) {
      recurrenceString +=
        days.slice(0, -1).join("s, ") + "s and " + days[days.length - 1] + "s";
    } else {
      recurrenceString += days[0] + "s";
    }
  }

  return recurrenceString;
};

export const calculateWeekCount = (sessionCount, interval) => {
  return Math.floor(sessionCount / interval);
};

export const getAllSessionDates = (
  firstSessionDate,
  sessionTime,
  recurrence,
  sessionCount,
) => {
  const sessionDates = [];
  let currentDateTime = DateTime.fromISO(firstSessionDate, { zone: "utc" }).set(
    {
      hour: parseInt(sessionTime.split(":")[0]),
      minute: parseInt(sessionTime.split(":")[1]),
    },
  );

  const dayMapping = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  };

  const daysOfWeek = recurrence.days.map((day) => dayMapping[day]);

  while (sessionDates.length < sessionCount) {
    if (daysOfWeek.includes(currentDateTime.weekday % 7)) {
      sessionDates.push(currentDateTime.toISO());
    }
    currentDateTime = currentDateTime.plus({ days: 1 });
  }

  return sessionDates.slice(0, sessionCount);
};

export const generateGroupDetails = (group, timeZone) => {
  let groupDetails;

  if (group.language === "tr_TR") {
    const turkeySessionTime = DateTime.fromFormat(group.sessionTime, "HH:mm", {
      zone: timeZone,
    })
      .setZone("Europe/Istanbul")
      .toFormat("HH:mm");

    groupDetails = `${formatDateToTurkish(group.firstSessionDate)} tarihinden itibaren ${calculateWeekCount(group.sessionCount, group.recurrence.interval)} hafta boyunca ${formatRecurrenceTurkish(group.recurrence)} Türkiye saati ile ${turkeySessionTime}'de başlayıp ${group.duration} dakika sürecektir. Toplam ${group.sessionCount} seans yapılacaktır. Seans başı ücret ${group.pricePerSession} TL'dir.`;
  } else {
    const timeZoneAbbreviation = DateTime.now()
      .setZone(timeZone)
      .toFormat("ZZZZ");

    groupDetails = `This group will begin on ${formatDateToAmerican(group.firstSessionDate)}, and will meet ${formatRecurrenceAmerican(group.recurrence)} at ${DateTime.fromFormat(group.sessionTime, "HH:mm").toFormat("h:mm a")} ${timeZoneAbbreviation} for ${group.duration} minutes each session, continuing for ${calculateWeekCount(group.sessionCount, group.recurrence.interval)} weeks. A total of ${group.sessionCount} sessions will be conducted. Session fee is ${group.pricePerSession} USD.`;
  }

  return groupDetails;
};

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { completeZoomOAuth, updateZoomLocation } from "../../services/api";

export async function loader({ request }) {
  const url = new URL(request.url);

  const searchParams = url.searchParams;
  const code = searchParams.get("code");
  const state = searchParams.get("state");

  try {
    await completeZoomOAuth(code, state);
    await updateZoomLocation();
  } catch {
    console.log("An error occurred while completing zoom oauth flow");
  }

  return null;
}

const OAuthCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/admin/settings/locations");
  }, [navigate]);

  return null;
};

export default OAuthCallback;

import { getAllGroupDetails } from "../../services/api.js";
import { useLoaderData } from "react-router-dom";
import EnrollmentForm from "../../components/public/EnrollmentForm.js";
import { useTherapist } from "../../contexts/TherapistContext.js";

export async function loader({ params, request }) {
  let group;
  let therapist;
  let questions;

  const url = new URL(request.url);
  const queryParams = new URLSearchParams(url.search);
  const backTo = queryParams.get("backTo");

  try {
    const response = await getAllGroupDetails(params.groupId);
    group = response.group;
    therapist = response.therapist;
    questions = response.questions;
  } catch (error) {
    console.log("An error occurred while fetching group");
  }

  return { group, therapist, questions, backTo };
}

const EnrollmentPage = () => {
  const { group, therapist, questions, backTo } = useLoaderData();
  const { setTherapistName, setTherapistHandle } = useTherapist();

  const therapistFullName = `${therapist.firstName} ${therapist.lastName}`;

  setTherapistName(therapistFullName);
  setTherapistHandle(therapist.handle);

  return (
    <EnrollmentForm
      group={group}
      questions={questions}
      therapistHandle={therapist.handle}
      timeZone={therapist.timeZone}
      backTo={backTo}
    />
  );
};

export default EnrollmentPage;

import {
  Row,
  Card,
  Col,
  Button,
  ButtonGroup,
  Modal,
  Spinner,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { confirmTimeSlot } from "../../services/api.js";
import {
  formatTimeFromDateToHHMM,
  formatDateToTurkish,
  formatDateToAmerican,
  formatTimeFromDateToHMMaa,
  getMonthNameInEnglish,
  getMonthNameInTurkish,
} from "../../utils/utils.js";

const TimeSlotList = ({
  timeSlots,
  group,
  encodedEncryptedRequestId,
  weekOf,
  availabilities,
}) => {
  const navigate = useNavigate();
  const confirmedConsultation =
    timeSlots.length === 1 && timeSlots[0].status === "CONFIRMED";
  const completedConsultation =
    timeSlots.length === 1 && timeSlots[0].status === "COMPLETED";
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedDay, setSelectedDay] = useState("");

  useEffect(() => {
    if (timeSlots.length > 0) {
      setSelectedDay(timeSlots[0].weekdayName);
    }
  }, [timeSlots]);

  const weekOfDateTime = DateTime.fromISO(weekOf, { zone: "UTC" });

  const monthNumber = weekOfDateTime.month;
  let monthName = "";

  if (group.language === "tr_TR") {
    monthName = getMonthNameInTurkish(monthNumber);
  } else {
    monthName = getMonthNameInEnglish(monthNumber);
  }

  let availableDays = [];
  for (let i = 0; i < 7; i++) {
    const currentDate = weekOfDateTime.plus({ days: i });
    availableDays.push({
      weekdayName: currentDate.toFormat("cccc"),
      dayOfMonth: currentDate.day,
      available: false,
    });
  }

  timeSlots.forEach((ts) => {
    const index = availableDays.findIndex(
      (day) => day.weekdayName === ts.weekdayName,
    );
    if (index !== -1) {
      availableDays[index].available = true;
    }
  });

  const handleDaySelect = (day) => {
    setSelectedDay(day);
  };

  const renderDayButtons = () => {
    const dayNames = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    let displayNames = [];
    if (group.language === "tr_TR") {
      displayNames = ["Pt", "Sa", "Ça", "Pe", "Cu", "Ct", "Pa"];
    } else {
      displayNames = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
    }

    const isDayAvailableAndDayOfMonth = (dayName) => {
      const day = availableDays.find((d) => d.weekdayName === dayName);
      return day
        ? { available: day.available, dayOfMonth: day.dayOfMonth }
        : { available: false, dayOfMonth: null };
    };

    return dayNames.map((name, index) => {
      const { available, dayOfMonth } = isDayAvailableAndDayOfMonth(name);
      return (
        <Button
          variant="outline-secondary"
          disabled={!available}
          active={selectedDay === name}
          onClick={() => handleDaySelect(name)}
          key={index}
          className="day-selection-button"
        >
          {displayNames[index]}
          <hr style={{ margin: "0" }} />
          {dayOfMonth}
        </Button>
      );
    });
  };

  const filteredSlots = timeSlots.filter((slot) => {
    const day = slot.weekdayName;
    return day === selectedDay;
  });

  const renderTimeSlots = () => {
    return filteredSlots.map((slot, idx) => (
      <Col key={idx}>
        <Card style={{ cursor: "pointer" }} onClick={() => handleSelect(slot)}>
          <Card.Body style={{ padding: "1rem 0rem" }}>
            <Card.Text style={{ textAlign: "center" }} className="mb-0">
              {formatTime(slot.startTime)}
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    ));
  };

  const handleSelect = (timeslot) => {
    setSelectedTimeSlot(timeslot);
    setShowApprovalModal(true);
  };

  const handleCloseApprovalModal = () => {
    setShowApprovalModal(false);
    setSelectedTimeSlot(null);
  };

  const handleApproval = async () => {
    setIsProcessing(true);
    try {
      const encodedEncryptedTimeSlotId = selectedTimeSlot._id;
      await confirmTimeSlot(
        encodedEncryptedRequestId,
        encodedEncryptedTimeSlotId,
      );
    } catch (error) {
      console.log("Error confirming timeslot", error);
    }
    setIsProcessing(false);
    setShowApprovalModal(false);
    navigate(0);
  };

  const handleNextWeek = () => {
    const startOfNextWeek = weekOfDateTime.plus({ weeks: 1 }).toISODate();
    navigate(
      `/select-timeslot/${encodedEncryptedRequestId}?weekOf=${startOfNextWeek}`,
    );
  };

  const handlePreviousWeek = () => {
    const startOfPreviousWeek = weekOfDateTime.minus({ weeks: 1 }).toISODate();
    navigate(
      `/select-timeslot/${encodedEncryptedRequestId}?weekOf=${startOfPreviousWeek}`,
    );
  };

  function selectText() {
    if (group.language === "en_EN") {
      return `Please choose the option that best suits you for a 15-minute consultation session from the following:`;
    } else {
      return `15 dakikalık bir ön görüşme için lütfen aşağıdaki seçeneklerden size en uygun olanını seçiniz:`;
    }
  }

  function approvalModalText(timeSlot) {
    if (group.language === "en_EN") {
      return `You have chosen the time slot of ${formatDateToAmerican(timeSlot.startTime)} ${formatTimeFromDateToHMMaa(timeSlot.startTime)} - ${formatTimeFromDateToHMMaa(timeSlot.endTime)} for your consultation session. Do you confirm?`;
    } else {
      return `Ön görüşmeniz için ${formatDateToTurkish(timeSlot.startTime)} ${formatTimeFromDateToHHMM(timeSlot.startTime)} - ${formatTimeFromDateToHHMM(timeSlot.endTime)} aralığını seçtiniz, onaylıyor musunuz?`;
    }
  }

  function formatTime(time) {
    if (group.language === "en_EN") {
      return formatTimeFromDateToHMMaa(time);
    } else {
      return formatTimeFromDateToHHMM(time);
    }
  }

  function confirmedConsultationText() {
    if (group.language === "en_EN") {
      return `Your consultation session has been confirmed for ${formatDateToAmerican(timeSlots[0].startTime)} between ${formatTimeFromDateToHMMaa(timeSlots[0].startTime)} and ${formatTimeFromDateToHMMaa(timeSlots[0].endTime)}. Please check your email inbox for a calendar invitation.`;
    } else {
      return `Ön görüşmeniz ${formatDateToTurkish(timeSlots[0].startTime)} ${formatTimeFromDateToHHMM(timeSlots[0].startTime)} - ${formatTimeFromDateToHHMM(timeSlots[0].endTime)} aralığı için onaylanmıştır. Lütfen gelen kutunuzu bir takvim davetiyesi için kontrol ediniz.`;
    }
  }

  function completedConsultationText() {
    if (group.language === "en_EN") {
      return `Your consultation session has been successfully completed. It took place on ${formatDateToAmerican(timeSlots[0].startTime)} between ${formatTimeFromDateToHMMaa(timeSlots[0].startTime)} and ${formatTimeFromDateToHMMaa(timeSlots[0].endTime)}.`;
    } else {
      return `Ön görüşmeniz ${formatDateToTurkish(timeSlots[0].startTime)} tarihinde ${formatTimeFromDateToHHMM(timeSlots[0].startTime)} ile ${formatTimeFromDateToHHMM(timeSlots[0].endTime)} saatleri arasında tamamlandı.`;
    }
  }

  if (timeSlots.length === 0) {
    return (
      <p style={{ textAlign: "center" }}>
        This therapist has no available time slots at the moment. Please check
        again later.
      </p>
    );
  }

  if (confirmedConsultation) {
    return <p style={{ textAlign: "center" }}>{confirmedConsultationText()}</p>;
  }

  if (completedConsultation) {
    return <p style={{ textAlign: "center" }}>{completedConsultationText()}</p>;
  }

  return (
    <>
      <Row className="mt-3">
        <p>{selectText()}</p>
      </Row>
      <Row className="mt-3">
        <h3 style={{ textAlign: "center" }} className="mb-3">
          {monthName}
        </h3>
        <ButtonGroup style={{ width: "23rem", margin: "auto" }}>
          {renderDayButtons()}
        </ButtonGroup>
      </Row>
      <Row xs={4} md={5} xl={6} xxl={6} className="gx-2 gy-3 mt-3">
        {renderTimeSlots()}
      </Row>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "auto",
          }}
          className="mt-3"
        >
          {availabilities.previousWeekHasAvailability ? (
            <Button
              variant="link"
              onClick={handlePreviousWeek}
              style={{ color: "var(--bs-gray-800)" }}
            >
              {group.language === "tr_TR" ? "Önceki Hafta" : "Previous Week"}
            </Button>
          ) : (
            <div style={{ width: "auto" }}></div>
          )}

          {availabilities.nextWeekHasAvailability ? (
            <Button
              variant="link"
              onClick={handleNextWeek}
              style={{ color: "var(--bs-gray-800)" }}
            >
              {group.language === "tr_TR" ? "Sonraki Hafta" : "Next Week"}
            </Button>
          ) : (
            <div style={{ width: "auto" }}></div>
          )}
        </div>
      </Row>
      {showApprovalModal && (
        <Modal show={showApprovalModal} onHide={handleCloseApprovalModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {group.language === "tr_TR"
                ? "Seçimi Onayla"
                : "Approve Selection"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{approvalModalText(selectedTimeSlot)}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseApprovalModal}>
              {group.language === "tr_TR" ? "İptal" : "Cancel"}
            </Button>
            <Button variant="success" onClick={handleApproval}>
              {isProcessing ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {group.language === "tr_TR" ? " Onaylanıyor" : " Approving"}
                </>
              ) : group.language === "tr_TR" ? (
                "Onayla"
              ) : (
                " Approve"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default TimeSlotList;

import React, { useState } from "react";
import {
  Row,
  Col,
  Dropdown,
  DropdownButton,
  Alert,
  Button,
  Spinner,
  Form,
  Container,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { DashCircle } from "react-bootstrap-icons";
import DatePicker from "react-datepicker";
import { setTherapistAvailability } from "../../services/api";

const AvailabilityForm = ({
  currentAvailability,
  currentDailyLimit,
  backTo,
}) => {
  const navigate = useNavigate();
  const [availabilityArray, setAvailabilityArray] = useState(() => {
    if (currentAvailability && currentAvailability.length === 0) {
      return [{ day: "Mondays", startTime: "09:00 AM", endTime: "09:30 AM" }];
    }
    return currentAvailability;
  });
  const [error, setError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [showLimitInput, setShowLimitInput] = useState(
    currentDailyLimit !== 48 ? true : false,
  );
  const [dailyLimit, setDailyLimit] = useState(
    currentDailyLimit !== 48 ? currentDailyLimit : 0,
  );

  const handleDayChange = (eventKey, index) => {
    const updatedSlots = [...availabilityArray];
    updatedSlots[index].day = eventKey;
    setAvailabilityArray(updatedSlots);
  };

  const createDefaultSlot = () => ({
    day: "",
    startTime: "09:00 AM",
    endTime: "09:30 AM",
  });

  const handleAddSlotButton = () => {
    if (availabilityArray.length > 0) {
      const lastSlot = availabilityArray[availabilityArray.length - 1];
      const { startTime, endTime, day } = lastSlot;
      if (isEndTimeBeforeStartTime(startTime, endTime)) {
        setError(
          "End time of a slot can not be less than or equal to start time",
        );
        return;
      }
      if (day === "") {
        setError("Please select a day for your last slot");
        return;
      }
    }
    if (availabilityArray.length > 1) {
      if (isOverlapping()) {
        setError("Last slot created overlaps with another slot");
        return;
      }
    }
    setError("");
    setAvailabilityArray([...availabilityArray, createDefaultSlot()]);
  };

  const handleRemoveSlotButton = (index) => {
    setError("");
    setAvailabilityArray(availabilityArray.filter((_, idx) => idx !== index));
  };

  const handleSaveButton = async () => {
    if (availabilityArray.length > 0) {
      const lastSlot = availabilityArray[availabilityArray.length - 1];
      if (lastSlot.day === "") {
        const newAvailabilityArray = availabilityArray.slice(0, -1); // Creates a new array without the last item
        setAvailabilityArray(newAvailabilityArray);
      }
    } else {
      setError("Availability array is empty");
      return;
    }

    setIsProcessing(true);
    try {
      await setTherapistAvailability(
        availabilityArray,
        showLimitInput ? dailyLimit : 48,
      );
      navigate("/admin/calendar");
    } catch (error) {
      setError(
        "Something went wrong setting the availability. Please try again later.",
      );
    }

    setIsProcessing(false);
  };

  const handleBackButton = () => {
    navigate(`/admin/${backTo}`);
  };

  const handleStartTimeChange = (date, index) => {
    const updatedSlots = [...availabilityArray];
    updatedSlots[index].startTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    setAvailabilityArray(updatedSlots);
  };

  const handleEndTimeChange = (date, index) => {
    const updatedSlots = [...availabilityArray];
    updatedSlots[index].endTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    setAvailabilityArray(updatedSlots);
  };

  function convertTo24Hour(timeStr) {
    const [time, modifier] = timeStr.split(" ");
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours, 10);

    if (hours === 12 && modifier === "AM") {
      hours = 0;
    } else if (hours === 12 && modifier === "PM") {
      hours = 12;
    } else if (modifier === "PM") {
      hours += 12;
    }

    hours = hours.toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  }

  function timeToMinutes(time) {
    const [hour, minute] = time.split(":");
    return parseInt(hour, 10) * 60 + parseInt(minute, 10);
  }

  function isEndTimeBeforeStartTime(startTime, endTime) {
    const startMinutes = timeToMinutes(convertTo24Hour(startTime));
    const endMinutes = timeToMinutes(convertTo24Hour(endTime));
    return endMinutes <= startMinutes;
  }

  function isOverlapping() {
    const slotsByDay = availabilityArray.reduce((acc, slot) => {
      if (!acc[slot.day]) {
        acc[slot.day] = [];
      }
      acc[slot.day].push(slot);
      return acc;
    }, {});

    for (let day in slotsByDay) {
      const slots = slotsByDay[day];
      for (let i = 0; i < slots.length; i++) {
        const startA = timeToMinutes(convertTo24Hour(slots[i].startTime));
        const endA = timeToMinutes(convertTo24Hour(slots[i].endTime));

        for (let j = i + 1; j < slots.length; j++) {
          const startB = timeToMinutes(convertTo24Hour(slots[j].startTime));
          const endB = timeToMinutes(convertTo24Hour(slots[j].endTime));

          if (startA < endB && startB < endA) {
            return true;
          }
        }
      }
    }
    return false;
  }

  const CustomInput = React.forwardRef(
    ({ value, onClick, placeholder }, ref) => (
      <div style={{ maxWidth: "150px" }}>
        <button
          className="form-control datepicker-custom-input margin-bottom-none"
          onClick={onClick}
          ref={ref}
          type="button"
        >
          {value || placeholder}
        </button>
      </div>
    ),
  );

  return (
    <>
      <Row
        className="d-flex justify-content-between align-items-center px-4"
        style={{ height: "7rem" }}
      >
        <Col xs={8}>
          <h4 style={{ marginBottom: "0px" }}>Availability</h4>
          <p style={{ marginBottom: "0px" }}>
            Days and times you are available for a 15-minute consultation
            session
          </p>
        </Col>
        <Col
          className="d-flex align-items-center justify-content-end"
          style={{ paddingRight: "0" }}
          xs={4}
        >
          <Button
            className="secondary-button pill-button"
            onClick={handleBackButton}
            style={{ width: "100px" }}
          >
            Back
          </Button>
          <Button
            className="primary-button pill-button"
            onClick={handleSaveButton}
            style={{ width: "100px", marginLeft: "10px" }}
          >
            {isProcessing ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                {" Saving"}
              </>
            ) : (
              "Save"
            )}
          </Button>
        </Col>
      </Row>

      <hr style={{ marginTop: "0px" }} />

      <Container className="main-container">
        <Row className="flex-column">
          {availabilityArray.map((slot, index) => (
            <React.Fragment key={index}>
              <Row
                key={index}
                className="align-items-center mb-2"
                style={{ maxWidth: "500px" }}
              >
                <Col xs={12} sm={4}>
                  <DropdownButton
                    variant="outline-secondary"
                    id={`day-dropdown-${index}`}
                    title={slot.day || "Select Day"}
                    onSelect={(eventKey) => handleDayChange(eventKey, index)}
                    style={{ marginBottom: "14px" }}
                  >
                    <Dropdown.Item eventKey="Mondays">Mondays</Dropdown.Item>
                    <Dropdown.Item eventKey="Tuesdays">Tuesdays</Dropdown.Item>
                    <Dropdown.Item eventKey="Wednesdays">
                      Wednesdays
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="Thursdays">
                      Thursdays
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="Fridays">Fridays</Dropdown.Item>
                    <Dropdown.Item eventKey="Saturdays">
                      Saturdays
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="Sundays">Sundays</Dropdown.Item>
                  </DropdownButton>
                </Col>
                <Col xs={4} sm={3}>
                  <DatePicker
                    selected={
                      slot.startTime
                        ? new Date(
                            `2024-01-01T${convertTo24Hour(slot.startTime)}`,
                          )
                        : null
                    }
                    placeholderText="From"
                    onChange={(time) => handleStartTimeChange(time, index)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    dateFormat="h:mm aa"
                    timeCaption="Time"
                    popperPlacement="bottom-start"
                    showPopperArrow={false}
                    fixedHeight
                    customInput={<CustomInput />}
                    filterTime={(time) => {
                      const hours = time.getHours();
                      return hours >= 8 && hours < 20;
                    }}
                  />
                </Col>
                <Col xs={1}>
                  <p style={{ marginBottom: "14px" }}>to</p>
                </Col>
                <Col xs={4} sm={3}>
                  <DatePicker
                    selected={
                      slot.endTime
                        ? new Date(
                            `2024-01-01T${convertTo24Hour(slot.endTime)}`,
                          )
                        : null
                    }
                    placeholderText="To"
                    onChange={(time) => handleEndTimeChange(time, index)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    dateFormat="h:mm aa"
                    timeCaption="Time"
                    popperPlacement="bottom-start"
                    showPopperArrow={false}
                    fixedHeight
                    customInput={<CustomInput />}
                    filterTime={(time) => {
                      const hours = time.getHours();
                      return hours >= 8 && hours < 20;
                    }}
                  />
                </Col>
                <Col xs={1}>
                  {availabilityArray.length > 1 && (
                    <DashCircle
                      size={24}
                      className="remove-icon"
                      onClick={() => handleRemoveSlotButton(index)}
                      style={{ marginBottom: "14px" }}
                    />
                  )}
                </Col>
              </Row>
            </React.Fragment>
          ))}
          <hr style={{ maxWidth: "500px" }} />
          {error && (
            <Alert className="mt-3" variant="danger">
              {error}
            </Alert>
          )}
          <Row style={{ maxWidth: "150px" }}>
            <Button
              variant="link"
              className="add-slot-btn"
              onClick={handleAddSlotButton}
            >
              Add another slot
            </Button>
          </Row>
          <Row className="mt-4">
            <Col className="d-flex align-items-center">
              <Form.Check
                type="checkbox"
                checked={showLimitInput}
                onChange={(e) => setShowLimitInput(e.target.checked)}
                style={{ marginRight: "5px" }}
              />
              <p style={{ marginBottom: "0px", marginRight: "5px" }}>
                Set a daily consultation limit:
              </p>
              <Form.Control
                disabled={!showLimitInput}
                style={{ maxWidth: "70px" }}
                min="1"
                max="48"
                value={dailyLimit === 0 ? "" : dailyLimit}
                type="number"
                onChange={(e) => setDailyLimit(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mt-5">
            <small>
              · Clients will be able to choose a 15-min time slot based on these
              availabilities, up to one month in advance.
            </small>
            <small>
              · Clients will not be permitted to select a time slot for same
              day; bookings can only be made starting from next day.
            </small>
            <small>
              · You can remove any 15-minute time slot using your calendar.
            </small>
            <small>
              · If the number of consultations in a day exceeds your set limit,
              that day will be automatically hidden from the schedule.
            </small>
          </Row>
        </Row>
      </Container>
    </>
  );
};

export default AvailabilityForm;

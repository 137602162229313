import React, { useEffect, useState, useCallback } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { useNavigate } from "react-router-dom";
import { checkTherapistExists } from "../../services/api";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .catch((error) => {
      console.log(error);
    });
} else {
  firebase.app();
}

const AuthPage = () => {
  const [ui, setUi] = useState(null);
  const navigate = useNavigate();

  const handleSignInSuccess = useCallback(
    async (user) => {
      try {
        const therapist = await checkTherapistExists();
        if (therapist.exists) {
          navigate("/admin");
        } else {
          navigate("/signup", { state: { phoneNumber: user.phoneNumber } });
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },
    [navigate],
  );

  useEffect(() => {
    // Check if the user is authenticated
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const therapist = await checkTherapistExists();
          if (therapist.exists) {
            // User has completed signup, redirect to admin page
            navigate("/admin");
          } else {
            // User is authenticated but has not completed signup, redirect to signup page
            navigate("/signup", {
              state: { phoneNumber: user.phoneNumber, userId: user.uid },
            });
          }
        } catch (error) {
          console.error("An error occurred:", error);
        }
      } else {
        // If not authenticated, initialize the Firebase UI
        if (!ui) {
          firebase
            .auth()
            .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
            .then(() => {
              const uiInstance =
                firebaseui.auth.AuthUI.getInstance() ||
                new firebaseui.auth.AuthUI(firebase.auth());
              setUi(uiInstance);
            })
            .catch((error) => {
              console.log(error);
            });
        }

        const uiConfig = {
          signInOptions: [
            {
              provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
            },
          ],
          callbacks: {
            signInSuccessWithAuthResult: (authResult) => {
              handleSignInSuccess(authResult.user);
              return false;
            },
          },
          tosUrl: "https://chaircircles.com/terms-and-conditions",
          privacyPolicyUrl: "https://chaircircles.com/privacy-policy",
        };

        if (ui) {
          ui.start("#firebaseui-auth-container", uiConfig);
        }

        return () => {
          if (ui) {
            ui.delete().then(() => setUi(null));
          }
        };
      }
    });
  }, [ui, navigate, handleSignInSuccess]);

  return (
    <div className="mt-5">
      <div id="firebaseui-auth-container"></div>
    </div>
  );
};

export default AuthPage;

import { createContext, useContext, useState } from "react";

const TherapistContext = createContext();

export function useTherapist() {
  return useContext(TherapistContext);
}

export function TherapistProvider({ children }) {
  const [therapistName, setTherapistName] = useState("");
  const [therapistHandle, setTherapistHandle] = useState("");
  const [therapistTimeZone, setTherapistTimeZone] = useState("");

  const resetTherapist = () => {
    setTherapistName("");
    setTherapistHandle("");
    setTherapistTimeZone("");
  };

  const value = {
    therapistName,
    setTherapistName,
    therapistHandle,
    setTherapistHandle,
    therapistTimeZone,
    setTherapistTimeZone,
    resetTherapist,
  };

  return (
    <TherapistContext.Provider value={value}>
      {children}
    </TherapistContext.Provider>
  );
}

import { Col, Row, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import GroupGridItem from "./GroupGridItem";

const GroupGrid = ({ allGroups }) => {
  const navigate = useNavigate();

  const handleGroupClick = (group) => {
    navigate(`/admin/groups/${group._id}`);
  };

  if (allGroups.length === 0) {
    return (
      <Container className="main-container">
        <Row className="px-3">
          You don't have any groups yet. Use the "Create Group" button above to
          create your first group!
        </Row>
      </Container>
    );
  }

  return (
    <>
      <div className="vertical-spacer-2" />
      <Row xs={1} md={2} xxl={3} className="px-3">
        {allGroups.map((group, idx) => (
          <Col key={idx} className="mb-3">
            <GroupGridItem
              name={group.name}
              status={group.status}
              size={group.size}
              approvedRequestCount={group.approvedRequestCount}
              pendingRequestCount={group.pendingRequestCount}
              waitlistedRequestCount={group.waitlistedRequestCount}
              onClick={() => handleGroupClick(group)}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default GroupGrid;

import React from "react";
import { Form, Row, Col } from "react-bootstrap";

const QuestionForm = ({ questions, language }) => {
  const renderQuestion = (question) => {
    const { _id, isRequired, type, text, options } = question;
    const prefixedId = `q-${_id}`;

    switch (type) {
      case "LONG_TEXT":
        return (
          <Form.Control
            as="textarea"
            id={prefixedId}
            placeholder={
              language === "tr_TR"
                ? "Cevabınızı yazınız..."
                : "Your answer here..."
            }
            name={prefixedId}
            rows={3}
          />
        );
      case "SHORT_TEXT":
        return (
          <Form.Control
            type="text"
            id={prefixedId}
            placeholder={
              language === "tr_TR"
                ? "Cevabınızı yazınız..."
                : "Your answer here..."
            }
            name={prefixedId}
          />
        );
      case "YES_NO":
        return (
          <div>
            <Form.Check
              type="radio"
              label="Yes"
              name={prefixedId}
              id={`${prefixedId}-yes`}
              value={language === "tr_TR" ? "Evet" : "Yes"}
            />
            <Form.Check
              type="radio"
              label="No"
              name={prefixedId}
              id={`${prefixedId}-no`}
              value={language === "tr_TR" ? "Hayır" : "No"}
            />
          </div>
        );
      case "NUMBER":
        return (
          <Form.Control
            type="number"
            id={prefixedId}
            placeholder="0"
            name={prefixedId}
          />
        );
      case "CONSENT":
        return (
          <Form.Check
            type="checkbox"
            id={prefixedId}
            label={
              <>
                {text}
                {isRequired && <>{" *"}</>}
              </>
            }
            name={prefixedId}
            value="Checked"
          />
        );
      case "SINGLE_CHOICE":
        return (
          <div>
            {options.map((option, index) => (
              <Form.Check
                key={index}
                type="radio"
                label={option}
                name={prefixedId}
                id={`${prefixedId}-${index}`}
                value={option}
              />
            ))}
          </div>
        );
      case "MULTI_CHOICE":
        return (
          <div>
            {options.map((option, index) => (
              <Form.Check
                key={index}
                type="checkbox"
                label={option}
                id={`${prefixedId}-${index}`}
                name={prefixedId}
                value={option}
              />
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {questions.map((question) => (
        <React.Fragment key={question._id}>
          <Row className="mt-2">
            <Form.Group as={Col}>
              {question.type !== "CONSENT" && (
                <Form.Label>
                  {question.text}
                  {question.isRequired && <>{" *"}</>}
                </Form.Label>
              )}
              {renderQuestion(question)}
            </Form.Group>
          </Row>
          <div className="vertical-spacer-2" />
        </React.Fragment>
      ))}
    </div>
  );
};

export default QuestionForm;

import { Row, Col, Card, Container } from "react-bootstrap";
import QuestionListItem from "./QuestionListItem";

const QuestionList = ({ questions, onEditButton }) => {
  return (
    <Container className="main-container">
      <Row xs={1}>
        {questions.length === 0 ? (
          <p>
            You don't have any questions yet. Please use "Create Question"
            button to get started.
          </p>
        ) : (
          <>
            {questions.map((question, idx) => (
              <Col key={idx}>
                <Card className="mt-3">
                  <Card.Body className="d-flex flex-column">
                    <QuestionListItem
                      questionText={question.text}
                      questionType={question.type}
                      questionOptions={question.options}
                      questionInUse={question.inUse}
                      onClick={() => onEditButton(question)}
                    />
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </>
        )}
      </Row>
    </Container>
  );
};

export default QuestionList;

const requestRejectionMessagesTR = {
  "Group Full": ({ selectedRequest, therapist, selectedGroup }) =>
    `Sayın ${selectedRequest.firstName} ${selectedRequest.lastName},<br/><br/>Terapistiniz ${therapist.firstName} ${therapist.lastName}, ${selectedGroup.name} grubunda yer kalmadığı için başvurunuzu reddetmek zorunda kaldı. Ancak, bir sonraki açılacak grup için sizi bekleme listesine ekledi. Yeni grup açıldığında sizinle iletişime geçeceğiz.<br/><br/>Saygılarımızla,<br/>destek@chaircircles.com`,
  "Client not compatible": ({ selectedRequest, therapist, selectedGroup }) =>
    `Sayın ${selectedRequest.firstName} ${selectedRequest.lastName},<br/><br/>Terapistiniz ${therapist.firstName} ${therapist.lastName}, verdiğiniz cevaplar ışığında ${selectedGroup.name} grubunun size yeterli faydayı sağlayamayacağını düşünerek başvurunuzu reddetti. Ancak, sizin ve benzer kriterlere sahip diğer danışanlar için özel olarak yeni bir grup oluşturma çalışmalarına başladı. Bu yeni grup hazır olduğunda, sizinle iletişime geçeceğiz.<br/><br/>Saygılarımızla,<br/>destek@chaircircles.com`,
  "Fake Request": () => `Email gönderilmeyecektir.`,
  Other: ({ selectedRequest }) =>
    `Sayın ${selectedRequest.firstName} ${selectedRequest.lastName},<br/><br/>---Lütfen ret mesajınızla birlikte danışanıza gönderilecek mesajı yazınız.---<br/><br/>Saygılarımızla,<br/>destek@chaircircles.com`,
};

const requestRejectionMessagesEN = {
  "Group Full": ({ selectedRequest, therapist, selectedGroup }) =>
    `Dear ${selectedRequest.firstName} ${selectedRequest.lastName},<br/><br/>Your therapist ${therapist.firstName} ${therapist.lastName} had to reject your application because there is no space left in the ${selectedGroup.name} group. However, you have been added to the waitlist for the next group to open. We will contact you when a new group is available.<br/><br/>Warm regards,<br/>support@chaircircles.com`,
  "Client not compatible": ({ selectedRequest, therapist, selectedGroup }) =>
    `Dear ${selectedRequest.firstName} ${selectedRequest.lastName},<br/><br/>Based on your responses, your therapist ${therapist.firstName} ${therapist.lastName} has determined that the ${selectedGroup.name} group may not provide you with sufficient benefits, and therefore has rejected your application. However, they are starting to create a new group specifically for you and others with similar criteria. We will contact you once this new group is ready.<br/><br/>Warm regards,<br/>support@chaircircles.com`,
  "Fake Request": () => `No email will be sent.`,
  Other: ({ selectedRequest }) =>
    `Dear ${selectedRequest.firstName} ${selectedRequest.lastName},<br/><br/>---Please write the rejection message that will be sent to your client along with your message.---<br/><br/>Warm regards,<br/>support@chaircircles.com`,
};

export { requestRejectionMessagesTR, requestRejectionMessagesEN };

import { Toast } from "react-bootstrap";

// variant values should be "success" or "danger"
const StatusToast = ({ variant, text, show, onClose }) => {
  return (
    <Toast
      className="sticky-toast-container"
      show={show}
      delay={3000}
      autohide
      onClose={onClose}
      bg={variant}
    >
      <Toast.Body className="text-white text-center">{text}</Toast.Body>
    </Toast>
  );
};

export default StatusToast;

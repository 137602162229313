import { useState } from "react";
import { useLoaderData, NavLink } from "react-router-dom";
import { Container, Card, Col, Row } from "react-bootstrap";
import { getTherapistDetails } from "../../../services/api.js";
import AvailabilitySummary from "../../../components/admin/AvailabilitySummary.js";
import TherapistDetails from "../../../components/admin/TherapistDetails.js";
import LocationList from "../../../components/admin/LocationList.js";
import QuestionSummary from "../../../components/admin/QuestionSummary.js";

export async function loader() {
  let therapist = {};

  try {
    therapist = await getTherapistDetails({ includeQuestions: true });
  } catch (error) {
    console.log("An error occurred while fetching therapist details");
  }

  return { therapist };
}

const SettingsPage = () => {
  const { therapist } = useLoaderData();
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    const link = `https://app.chaircircles.com/therapists/${therapist.handle}`;
    navigator.clipboard.writeText(link).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <Container className="main-container">
      <Card className="mb-5">
        <Card.Header className="d-flex align-items-center justify-content-between">
          <Card.Title className="mb-0">Profile</Card.Title>
          <NavLink className="secondary-button" to="/admin/settings/profile">
            Manage
          </NavLink>
        </Card.Header>
        <Card.Body>
          <TherapistDetails therapist={therapist} />
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col className="d-flex align-items-center">
              <p
                style={{
                  marginBottom: "0px",
                  fontWeight: "500",
                  marginRight: "15px",
                }}
              >
                Application Link:
              </p>
              <a
                href={`https://app.chaircircles.com/therapists/${therapist.handle}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ wordBreak: "break-all" }}
              >
                https://app.chaircircles.com/therapists/{therapist.handle}
              </a>
              <button
                onClick={handleCopy}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "15px",
                  fontSize: "1rem",
                }}
              >
                {copied ? (
                  <i className="bi bi-check2-circle"></i>
                ) : (
                  <i className="bi bi-copy"></i>
                )}
              </button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      <Card className="mb-5">
        <Card.Header className="d-flex align-items-center justify-content-between">
          <Card.Title className="mb-0">Locations</Card.Title>
          <NavLink className="secondary-button" to="/admin/settings/locations">
            Manage
          </NavLink>
        </Card.Header>
        <Card.Body>
          <LocationList locations={therapist.location} />
        </Card.Body>
      </Card>
      <Card className="mb-5">
        <Card.Header className="d-flex align-items-center justify-content-between">
          <Card.Title className="mb-0">Availability</Card.Title>
          <NavLink
            className="secondary-button"
            to="/admin/settings/availability?backTo=settings"
          >
            Manage
          </NavLink>
        </Card.Header>
        <Card.Body>
          <AvailabilitySummary availabilityArray={therapist.availability} />
        </Card.Body>
      </Card>
      <Card className="mb-5">
        <Card.Header className="d-flex align-items-center justify-content-between">
          <Card.Title className="mb-0">Questions</Card.Title>
          <NavLink className="secondary-button" to="/admin/settings/questions">
            Manage
          </NavLink>
        </Card.Header>
        <Card.Body>
          <QuestionSummary questions={therapist.questions} />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default SettingsPage;

import { Navbar, Row, Col, Button } from "react-bootstrap";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { useTherapist } from "../../contexts/TherapistContext";
import { useAuth } from "../../contexts/AuthContext";

const PublicTopNav = () => {
  const { currentUser } = useAuth();
  const location = useLocation();
  const { therapistName, therapistHandle } = useTherapist();
  const navigate = useNavigate();

  const handleLoginButton = () => {
    navigate("/auth");
  };

  const shouldShowProviderLogin =
    location.pathname === "/" ||
    (!currentUser &&
      location.pathname !== "/auth" &&
      location.pathname !== "/signup");

  return (
    <>
      <Navbar id="public-top-nav" sticky="top">
        <Row className="w-100 justify-content-between align-items-center">
          <Col xs="auto">
            <NavLink to="/" id="logo">
              chaircircles
            </NavLink>
          </Col>
          {shouldShowProviderLogin && (
            <Col xs="auto" className="ms-auto">
              <Button
                variant="outline-dark"
                id="provider-login-button"
                onClick={handleLoginButton}
              >
                Provider Login
              </Button>
            </Col>
          )}
        </Row>
      </Navbar>
      {therapistHandle && (
        <Row
          id="therapist-name-wrapper"
          className="justify-content-center align-items-center"
        >
          <Col xs="auto">
            <NavLink
              to={`/therapists/${therapistHandle}`}
              id="therapist-name-header"
            >
              {therapistName}
            </NavLink>
          </Col>
        </Row>
      )}
    </>
  );
};

export default PublicTopNav;

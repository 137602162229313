import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  Row,
  InputGroup,
  Form,
  Alert,
  Button,
  Spinner,
} from "react-bootstrap";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { createTherapist } from "../../services/api";

export async function loader() {
  return null;
}

const SignupPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userId, setUserId] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [error, setError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (location.state) {
      const { phoneNumber, userId } = location.state;
      if (phoneNumber) {
        setPhoneNumber(phoneNumber);
      }
      if (userId) {
        setUserId(userId);
      }
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      setTimeZone(userTimezone);
    } else {
      console.log("No phone number or user ID found in state.");
    }
  }, [location.state]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const onSubmitButtonClick = async () => {
    if (!firstName || !lastName || !emailAddress) {
      setError("All fields are required.");
      return;
    }

    if (!validateEmail(emailAddress)) {
      setError("Please enter a valid email address.");
      return;
    }
    setError("");

    setIsProcessing(true);
    try {
      const therapist = {
        userId,
        phoneNumber,
        emailAddress,
        firstName,
        lastName,
        timeZone,
      };
      await createTherapist(therapist);
      navigate(`/admin/settings`);
    } catch (error) {
      console.error(error);
      setError(error.message || "An unexpected error occurred.");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Container className="main-container d-flex flex-column align-items-center">
      <h2>Sign Up</h2>
      <hr style={{ width: "347px", color: "var(--bs-dark)", opacity: "1" }} />
      <Row xs={1}>
        <InputGroup className="mb-3 mt-3">
          <InputGroup.Text style={{ minWidth: "137px" }}>
            Phone Number
          </InputGroup.Text>
          <Form.Control
            value={formatPhoneNumberIntl(phoneNumber)}
            type="text"
            disabled
            style={{ maxWidth: "250px" }}
          />
        </InputGroup>
      </Row>
      <Row xs={1}>
        <InputGroup className="mb-3">
          <InputGroup.Text style={{ minWidth: "137px" }}>
            First Name
          </InputGroup.Text>
          <Form.Control
            value={firstName}
            onChange={(e) => setFirstName(e.currentTarget.value)}
            type="text"
            style={{ maxWidth: "250px" }}
          />
        </InputGroup>
      </Row>
      <Row xs={1}>
        <InputGroup className="mb-3">
          <InputGroup.Text style={{ minWidth: "137px" }}>
            Last Name
          </InputGroup.Text>
          <Form.Control
            value={lastName}
            onChange={(e) => setLastName(e.currentTarget.value)}
            type="text"
            style={{ maxWidth: "250px" }}
          />
        </InputGroup>
      </Row>
      <Row xs={1}>
        <InputGroup className="mb-5">
          <InputGroup.Text style={{ minWidth: "137px" }}>
            Email Address
          </InputGroup.Text>
          <Form.Control
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.currentTarget.value)}
            type="text"
            style={{ maxWidth: "250px" }}
          />
        </InputGroup>
      </Row>
      <Row xs={1} style={{ width: "200px" }}>
        <Button className="primary-button" onClick={onSubmitButtonClick}>
          {isProcessing && (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
            </>
          )}
          Submit
        </Button>
      </Row>
      {error && (
        <Alert className="mt-4" variant="danger" style={{ minWidth: "347px" }}>
          {error}
        </Alert>
      )}
    </Container>
  );
};

export default SignupPage;

import { useState } from "react";
import { Container, Row, Col, Button, Alert, Spinner } from "react-bootstrap";
import { acceptMove, withdrawRequest } from "../../services/api.js";
import GroupDetailSummary from "../admin/GroupDetailSummary.js";

const ClientMoveConsent = ({ group, requestId, subStatus }) => {
  const [isAcceptProcessing, setIsAcceptProcessing] = useState(false);
  const [isWithdrawProcessing, setIsWithdrawProcessing] = useState(false);
  const [error, setError] = useState("");
  const [resultMessage, setResultMessage] = useState("");

  // To avoid user re-accessing the page after making a decision
  // POST-MVP-TODO: Butun request recreate logicini silip diger sayfalari da boyle handle et.
  if (subStatus !== "AWAITING_CLIENT_DECISION") {
    return (
      <Container className="main-container">
        <div style={{ height: "44px" }} />
        <Row className="mt-3">
          <p>Invalid Token</p>
        </Row>
      </Container>
    );
  }

  let messageFromTherapist,
    acceptButtonText,
    rejectButtonText,
    acceptResultMessage,
    withdrawResultMessage;

  if (group.language === "tr_TR") {
    messageFromTherapist =
      "Terapistiniz bu grubun size daha uygun olacağını düşünüyor:";
    acceptButtonText = "Bu gruba geçmeyi kabul ediyorum";
    rejectButtonText = "Başvurumu geri çekmek istiyorum";

    acceptResultMessage = `Grup değişikliğiniz başarıyla tamamlandı. Terapistinize durumla ilgili bilgilendirici bir mesaj ilettik. Bu sayfayı kapatabilirsiniz.`;
    withdrawResultMessage = `Başvurunuzun geri çekilme işlemi başarıyla gerçekleştirildi. Terapistinize durum hakkında bilgi verildi. Bu sayfayı kapatabilirsiniz.`;
  } else {
    messageFromTherapist =
      "Your therapist believes you will benefit more from this group:";
    acceptButtonText = "I accept to move to this group";
    rejectButtonText = "I want to withdraw my application";

    acceptResultMessage =
      "Your group transfer has been successfully completed. We have sent an informative message to your therapist. You may now close this page.";
    withdrawResultMessage =
      "Your application withdrawal has been successfully processed. We have informed your therapist about the situation. You may now close this page.";
  }

  const handleAcceptButton = async () => {
    setIsAcceptProcessing(true);
    try {
      await acceptMove(requestId);
      setResultMessage(acceptResultMessage);
    } catch (error) {
      setError("Something went wrong. Please try again later.");
    }
    setIsAcceptProcessing(false);
  };

  const handleWithdrawButton = async () => {
    setIsWithdrawProcessing(true);
    try {
      await withdrawRequest(requestId);
      setResultMessage(withdrawResultMessage);
    } catch (error) {
      setError("Something went wrong. Please try again later.");
    }
    setIsWithdrawProcessing(false);
  };

  if (resultMessage) {
    return (
      <Row className="mt-3">
        <p>{resultMessage}</p>
      </Row>
    );
  }

  return (
    <>
      <Row className="mt-3">
        <p>{messageFromTherapist}</p>
      </Row>
      <Row>
        <h3>{group.name}</h3>
        <h5>{group.description}</h5>
        <GroupDetailSummary group={group} />
      </Row>
      <hr />
      <Row>
        <Col className="d-flex mt-4" style={{ justifyContent: "space-around" }}>
          <Button
            variant="success"
            disabled={isAcceptProcessing || isWithdrawProcessing}
            onClick={handleAcceptButton}
            style={{
              width: "200px",
              height: "62px",
              whiteSpace: "normal",
              marginRight: "10px",
            }}
          >
            {isAcceptProcessing ? (
              <Spinner as="span" animation="border" variant="light" size="lg" />
            ) : (
              acceptButtonText
            )}
          </Button>
          <Button
            variant="outline-danger"
            disabled={isAcceptProcessing || isWithdrawProcessing}
            onClick={handleWithdrawButton}
            style={{
              width: "200px",
              height: "62px",
              whiteSpace: "normal",
              marginLeft: "10px",
            }}
          >
            {isWithdrawProcessing ? (
              <Spinner
                as="span"
                animation="border"
                variant="danger"
                size="lg"
              />
            ) : (
              rejectButtonText
            )}
          </Button>
        </Col>
      </Row>
      {error && <Alert variant="danger">{error}</Alert>}
    </>
  );
};

export default ClientMoveConsent;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Alert, Row, Col, Form, Card } from "react-bootstrap";
import QuestionListItem from "./QuestionListItem";

const SelectQuestionsModal = ({
  show,
  onHide,
  onSelect,
  groupId,
  allQuestions,
  selectedQuestionIds = [],
}) => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [selectedQuestions, setSelectedQuestions] =
    useState(selectedQuestionIds);

  const handleCancelButton = () => {
    setError("");
    onHide();
  };

  const handleCreateNewQuestionButton = () => {
    navigate(`/admin/settings/questions?groupId=${groupId}`);
  };

  const handleSelectButton = () => {
    onSelect(selectedQuestions);
    onHide();
  };

  const handleSwitchChange = (questionId) => {
    setSelectedQuestions((prevSelectedQuestions) => {
      if (prevSelectedQuestions.includes(questionId)) {
        return prevSelectedQuestions.filter((id) => id !== questionId);
      } else {
        return [...prevSelectedQuestions, questionId];
      }
    });
  };

  return (
    <Modal size="lg" show={show} onHide={onHide} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Select Questions for Questionnaire</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row xs={1}>
          {allQuestions.length === 0 && (
            <>
              <p>You have not created any questions yet.</p>
              <p>
                Please use the Create New Question button below to get started.
              </p>
            </>
          )}
          {allQuestions.map((question, idx) => (
            <Col key={idx}>
              <Card className="mb-2">
                <Card.Header className="question-switch-wrapper">
                  <Form.Switch
                    id={`switch-${question._id}`}
                    label="Include this question"
                    checked={selectedQuestions.includes(question._id)}
                    onChange={() => handleSwitchChange(question._id)}
                    className="d-inline-block"
                  />
                </Card.Header>
                <Card.Body>
                  <QuestionListItem
                    questionText={question.text}
                    questionType={question.type}
                    questionOptions={question.options}
                    questionInUse={question.inUse}
                    editAllowed={false}
                  />
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        {error && (
          <Alert className="mt-2" variant="danger">
            {error}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleCreateNewQuestionButton}>
          Create New Question
        </Button>
        <Button
          className="ms-auto"
          variant="outline-dark"
          onClick={handleCancelButton}
        >
          Cancel
        </Button>
        <Button variant="dark" onClick={() => handleSelectButton()}>
          Select
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectQuestionsModal;

import { useEffect } from "react";

const HomePage = () => {
  const HOME_URL =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000/auth"
      : "https://chaircircles.com";

  useEffect(() => {
    window.location.replace(HOME_URL);
  }, []);

  return null;
};

export default HomePage;

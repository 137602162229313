import { useState } from "react";
import { useNavigate, useLoaderData } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import { getAllQuestionsOfTherapist } from "../../../services/api.js";
import QuestionList from "../../../components/admin/QuestionList.js";
import QuestionModal from "../../../components/admin/QuestionModal.js";

export async function loader() {
  let questions = [];
  try {
    questions = await getAllQuestionsOfTherapist();
  } catch (error) {
    console.log(
      "An error occurred while fetching the questions of the therapist",
    );
  }

  return { questions };
}

const SetQuestionsPage = () => {
  const { questions } = useLoaderData();
  const navigate = useNavigate();
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState();

  const handleBackButton = () => {
    navigate("/admin/settings");
  };

  const handleCreateQuestionButton = () => {
    setSelectedQuestion(null);
    setShowQuestionModal(true);
  };

  const handleEditButton = (question) => {
    setSelectedQuestion(question);
    setShowQuestionModal(true);
  };

  const handleCloseQuestionModal = () => {
    setShowQuestionModal(false);
  };

  return (
    <Container className="settings-header-container" fluid>
      <Row
        className="d-flex justify-content-between align-items-center px-4"
        style={{ height: "7rem" }}
      >
        <Col xs={8}>
          <h4 style={{ marginBottom: "0px" }}>Questions</h4>
          <p style={{ marginBottom: "0px" }}>
            Questions to build a pre-screening questionnaire
          </p>
        </Col>
        <Col
          className="d-flex align-items-center justify-content-end"
          style={{ paddingRight: "0" }}
        >
          <Button
            className="secondary-button pill-button"
            onClick={handleBackButton}
            style={{ width: "100px" }}
          >
            Back
          </Button>
          <Button
            className="primary-button pill-button"
            onClick={handleCreateQuestionButton}
            style={{ marginLeft: "10px" }}
          >
            <span className="d-none d-lg-inline">Create Question</span>
            <span className="d-inline d-lg-none">Create</span>
          </Button>
        </Col>
      </Row>
      <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
      <QuestionList questions={questions} onEditButton={handleEditButton} />
      {showQuestionModal && (
        <QuestionModal
          show={showQuestionModal}
          onHide={handleCloseQuestionModal}
          question={selectedQuestion}
        />
      )}
    </Container>
  );
};

export default SetQuestionsPage;

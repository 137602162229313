import { Row, Col, Badge } from "react-bootstrap";
import { formatPhoneNumberIntl } from "react-phone-number-input";

const TherapistDetails = ({ therapist }) => {
  const order = { Title: 1, License: 2, Education: 3 };
  const sortedQualifications = therapist.qualifications.sort((a, b) => {
    return order[a.type] - order[b.type];
  });
  const qualificationsString = sortedQualifications
    .map((q) => q.value)
    .join(", ");

  return (
    <Row>
      <Col xs={10} style={{ marginLeft: "1rem" }}>
        <h5 style={{ marginBottom: "0px" }}>
          {therapist.firstName} {therapist.lastName}
        </h5>
        <h6>{qualificationsString}</h6>
        <div className="d-flex align-items-center">
          <p style={{ marginBottom: "0px" }} className="text-secondary">
            {therapist.emailAddress}
          </p>
          {!therapist.emailVerified && (
            <Badge style={{ marginLeft: "10px" }} bg="warning">
              Not Verified
            </Badge>
          )}
        </div>
        <p style={{ marginBottom: "0px" }} className="text-secondary">
          {formatPhoneNumberIntl(therapist.phoneNumber)}
        </p>
      </Col>
    </Row>
  );
};

export default TherapistDetails;

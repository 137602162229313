import { Row, Col } from "react-bootstrap";
import LocationCard from "./LocationCard";

const LocationList = ({ locations, onEdit, onDelete }) => {
  if (locations.length === 0) {
    return (
      <Row className="px-3 flex-column">
        You have not registered any locations yet. Please use the "Create
        Location" button to register.
      </Row>
    );
  }

  if (onEdit) {
    return (
      <Row xs={1}>
        {locations.map((location, idx) => (
          <Col key={idx} className="mb-3">
            <LocationCard
              location={location}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          </Col>
        ))}
      </Row>
    );
  }

  return (
    <Row xs={1} sm={2} md={3}>
      {locations.map((location, idx) => (
        <Col key={idx} className="mb-3">
          <LocationCard location={location} compactView={true} />
        </Col>
      ))}
    </Row>
  );
};

export default LocationList;

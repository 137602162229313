import { useLoaderData } from "react-router-dom";
import AvailabilityForm from "../../../components/admin/AvailabilityForm.js";
import { getTherapistDetails } from "../../../services/api.js";

export async function loader({ request }) {
  const url = new URL(request.url);
  const backTo = url.searchParams.get("backTo") || "settings";

  let therapistAvailability;
  let therapistDailyConsultationLimit;

  try {
    const therapist = await getTherapistDetails();
    therapistAvailability = therapist.availability;
    therapistDailyConsultationLimit = therapist.dailyConsultationLimit;
  } catch (error) {
    console.log(
      "An error occurred while fetching the therapist availability and groups for availability setup",
    );
  }

  return {
    therapistAvailability,
    therapistDailyConsultationLimit,
    backTo,
  };
}

const SetAvailabilityPage = () => {
  const { therapistAvailability, therapistDailyConsultationLimit, backTo } =
    useLoaderData();

  return (
    <AvailabilityForm
      currentAvailability={therapistAvailability}
      currentDailyLimit={therapistDailyConsultationLimit}
      backTo={backTo}
    />
  );
};

export default SetAvailabilityPage;

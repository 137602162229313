import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button, Modal, Spinner } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import { DateTime } from "luxon";
import { deleteTimeSlot, deleteMultipleTimeSlots } from "../../services/api";

const Calendar = ({ therapistAvailability, loaderEvents, startStrQuery }) => {
  const navigate = useNavigate();
  const calendarRef = useRef(null);
  const calendarView =
    window.innerWidth >= 768 ? "timeGridWeek" : "timeGridDay";
  const therapistHasAvailability =
    therapistAvailability.length === 0 ? false : true;
  const deleteText =
    window.innerWidth <= 993 && window.innerWidth >= 768 ? "X" : "Delete";

  // Set to the start date of the calendar view
  const [startStr, setStartStr] = useState(startStrQuery);

  const [isProcessing, setIsProcessing] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  function handleDatesSet(info) {
    if (calendarRef.current) {
      if (info.view.type !== calendarView) {
        //Something is very wrong
        navigate("/admin");
      }
      setStartStr(info.startStr);
    }
  }

  const handleRemoveIconClick = async (eventId) => {
    try {
      await deleteTimeSlot(eventId);
      let calendarApi = calendarRef.current.getApi();
      let event = calendarApi.getEventById(eventId);
      if (event) {
        event.remove();
      }
    } catch {
      console.log(
        "Something went wrong removing your availability, please try again later",
      );
    }
  };

  const handleCloseRemoveModal = () => {
    setShowRemoveModal(false);
  };

  const handleRemove = async () => {
    setIsProcessing(true);
    try {
      const removalType = calendarView === "timeGridWeek" ? "weekly" : "daily";
      await deleteMultipleTimeSlots(removalType, startStr);
    } catch (error) {
      console.log("error deleting timeslots");
    } finally {
      const currentUrl = new URL(window.location);
      const queryParams = new URLSearchParams(currentUrl.search);
      queryParams.set("startStr", startStr);
      const newUrl = `${currentUrl.pathname}?${queryParams.toString()}`;
      window.location.href = newUrl;
    }
  };

  const generateModalText = () => {
    if (calendarView === "timeGridWeek") {
      return `the week of ${DateTime.fromISO(startStr).startOf("week").toFormat("MMMM d")}`;
    } else {
      return DateTime.fromISO(startStr).toFormat("MMMM d");
    }
  };

  return (
    <Row className="px-3 h-100">
      <div className="vertical-spacer-1" />
      <Modal show={showRemoveModal} onHide={handleCloseRemoveModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Delete all availability for {generateModalText()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Clicking the remove button will delete all your available consultation
          time slots for {generateModalText()}.
          <br />
          <br />
          Do you want to proceed?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={handleCloseRemoveModal}>
            Cancel
          </Button>
          <Button variant="dark" onClick={handleRemove}>
            {isProcessing ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                {" Deleting"}
              </>
            ) : (
              "Delete"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      {calendarView === "timeGridDay" && (
        <div className="d-flex justify-content-between">
          <Col xs={5}>
            <Button
              variant="outline-dark"
              className="w-100"
              onClick={() =>
                navigate("/admin/settings/availability?backTo=calendar")
              }
            >
              Set Availability
            </Button>
          </Col>
          <Col xs={5}>
            <Button
              variant="outline-dark"
              className="w-100"
              onClick={() => setShowRemoveModal(true)}
            >
              Clear Day
            </Button>
          </Col>
        </div>
      )}
      <FullCalendar
        ref={calendarRef}
        height="89%"
        plugins={[timeGridPlugin, dayGridPlugin, bootstrap5Plugin]}
        themeSystem="bootstrap5"
        initialView={calendarView}
        initialDate={startStr}
        firstDay={1}
        titleFormat={
          calendarView === "timeGridWeek"
            ? { month: "long", year: "numeric" }
            : { month: "short", year: "numeric" }
        }
        dayHeaderFormat={{ weekday: "short", day: "2-digit" }}
        headerToolbar={
          calendarView === "timeGridWeek"
            ? {
                left: therapistHasAvailability ? "set remove" : "set",
                center: "title",
                right: "prev next",
              }
            : { left: "title", right: "prev next" }
        }
        customButtons={{
          remove: {
            text: "Clear Week",
            click: function () {
              setShowRemoveModal(true);
            },
          },
          set: {
            text: "Set Availability",
            click: function () {
              navigate("/admin/settings/availability?backTo=calendar");
            },
          },
        }}
        slotDuration="00:15:00"
        slotLabelInterval="01:00"
        slotLabelFormat={{
          hour: "numeric",
          hour12: true,
        }}
        slotMinTime="08:00:00"
        slotMaxTime="20:00:00"
        allDaySlot={false}
        nowIndicator={true}
        events={loaderEvents}
        datesSet={handleDatesSet}
        eventContent={({ event }) => {
          const handleDeleteClick = (e) => {
            e.stopPropagation();
            handleRemoveIconClick(event.id);
          };

          return (
            <div className="fc-event-title-container">
              <div
                className={`fc-event-title fc-sticky ${event.extendedProps.type === "consultation" ? "fc-custom-consultation" : "fc-custom-group"}`}
                style={event.title === "" ? { color: "var(--bs-dark)" } : {}}
              >
                {event.title === "" ? "Available" : event.title}
              </div>
              {event.title === "" && (
                <button
                  onClick={handleDeleteClick}
                  className="fc-custom-delete-button"
                >
                  {deleteText}
                </button>
              )}
            </div>
          );
        }}
      />
    </Row>
  );
};

export default Calendar;

import { Button, Form, Col } from "react-bootstrap";

const QuestionListItem = ({
  questionText,
  questionType,
  questionOptions,
  questionInUse,
  onClick,
  editAllowed = true,
}) => {
  const renderQuestionReview = () => {
    switch (questionType) {
      case "LONG_TEXT":
        return (
          <Form.Group as={Col}>
            <Form.Label>{questionText}</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Your answer here..."
              rows={3}
            />
          </Form.Group>
        );
      case "SHORT_TEXT":
        return (
          <Form.Group as={Col}>
            <Form.Label>{questionText}</Form.Label>
            <Form.Control type="text" placeholder="Your answer here..." />
          </Form.Group>
        );
      case "YES_NO":
        return (
          <Form.Group as={Col}>
            <Form.Label>{questionText}</Form.Label>
            <div>
              <Form.Check type="radio" label="Yes" name={questionText} />
              <Form.Check type="radio" label="No" name={questionText} />
            </div>
          </Form.Group>
        );
      case "NUMBER":
        return (
          <Form.Group as={Col}>
            <Form.Label>{questionText}</Form.Label>
            <Form.Control type="number" placeholder="0" />
          </Form.Group>
        );
      case "CONSENT":
        return (
          <Form.Group as={Col}>
            <Form.Check type="checkbox" label={questionText} />
          </Form.Group>
        );
      case "SINGLE_CHOICE":
        return (
          <Form.Group as={Col}>
            <Form.Label>{questionText}</Form.Label>
            <div>
              {questionOptions.map((option, index) => (
                <Form.Check
                  key={index}
                  type="radio"
                  label={option}
                  name={questionText}
                />
              ))}
            </div>
          </Form.Group>
        );
      case "MULTI_CHOICE":
        return (
          <Form.Group as={Col}>
            <Form.Label>{questionText}</Form.Label>
            <div>
              {questionOptions.map((option, index) => (
                <Form.Check key={index} type="checkbox" label={option} />
              ))}
            </div>
          </Form.Group>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {renderQuestionReview()}
      {editAllowed && (
        <>
          {questionInUse ? (
            <p className="align-self-end mt-2">
              <small>Question in use, can't edit</small>
            </p>
          ) : (
            <Button
              variant="secondary"
              className="align-self-end mt-2"
              onClick={onClick}
            >
              Edit
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default QuestionListItem;

import { useState } from "react";
import { Card, Button, Row } from "react-bootstrap";
import QuestionListItem from "./QuestionListItem";

const QuestionSummary = ({ questions }) => {
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll((prevState) => !prevState);
  };

  const questionCount = questions.length;

  if (questionCount === 0) {
    return (
      <Row className="px-3 flex-column">
        You have not created any questions yet. Please use the Manage button to
        create.
      </Row>
    );
  }

  // Determine which questions to display based on showAll state
  const questionsToDisplay = showAll ? questions : questions.slice(0, 1);

  return (
    <>
      {questionsToDisplay.map((question, index) => (
        <Card key={index} className="mt-3">
          <Card.Body className="d-flex flex-column">
            <QuestionListItem
              questionText={question.text}
              questionType={question.type}
              questionOptions={question.options}
              questionInUse={question.inUse}
              editAllowed={false}
            />
          </Card.Body>
        </Card>
      ))}
      <div className="d-flex justify-content-center mt-3">
        <Button
          className="secondary-button pill-button"
          onClick={toggleShowAll}
        >
          {showAll ? "Show Less" : "Show More"}
        </Button>
      </div>
    </>
  );
};

export default QuestionSummary;

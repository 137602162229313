import { useState } from "react";
import { Navbar, Button, Container } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import CreateGroupModal from "./modals/CreateGroupModal";
import { createGroup } from "../../services/api";

const AdminTopNav = ({ toggleSideNav }) => {
  const navigate = useNavigate();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [createError, setCreateError] = useState(null);

  const handleCreateNewButton = () => {
    setShowCreateModal(true);
  };

  const handleHideModal = () => {
    setShowCreateModal(false);
    setCreateError(null); // Reset the error when hiding the modal
  };

  const handleCreateGroup = async (group) => {
    try {
      const response = await createGroup(group);
      navigate(`/admin/groups/${response.groupId}`);
      setShowCreateModal(false);
    } catch (error) {
      setCreateError(error.message);
    }
  };

  return (
    <>
      <Navbar expand="lg" id="admin-top-navbar">
        <Container fluid>
          <Button
            variant="outline-secondary"
            className="d-lg-none me-2"
            onClick={toggleSideNav}
          >
            <span className="navbar-toggler-icon"></span>
          </Button>
          <Navbar.Brand as={NavLink} to={`/admin`} id="admin-logo">
            chaircircles
          </Navbar.Brand>
          <Button
            onClick={handleCreateNewButton}
            className="primary-button pill-button"
          >
            <span className="d-none d-lg-inline">Create Group</span>
            <span className="d-inline d-lg-none">+ Group</span>
          </Button>
        </Container>
      </Navbar>

      <CreateGroupModal
        show={showCreateModal}
        onHide={handleHideModal}
        onCreate={handleCreateGroup}
        error={createError}
      />
    </>
  );
};

export default AdminTopNav;

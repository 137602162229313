import { useState, useCallback } from "react";
import { useNavigate, useLoaderData } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Spinner,
  Badge,
} from "react-bootstrap";
import {
  getTherapistDetails,
  upsertTherapistQualifications,
  sendVerificationEmail,
} from "../../../services/api.js";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import QualificationsForm from "../../../components/admin/QualificationsForm.js";

export async function loader() {
  let therapist = {};

  try {
    therapist = await getTherapistDetails({ includeQuestions: true });
  } catch (error) {
    console.log("An error occurred while fetching therapist details");
  }

  return { therapist };
}

const SetProfilePage = () => {
  const { therapist } = useLoaderData();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState("");
  const [updatedQualifications, setUpdatedQualifications] = useState([]);
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);

  const order = { Title: 1, License: 2, Education: 3 };
  const sortedQualifications = therapist.qualifications.sort((a, b) => {
    return order[a.type] - order[b.type];
  });

  const handleVerficiationButton = async () => {
    setIsProcessing(true);
    setError("");

    try {
      await sendVerificationEmail();
      setVerificationEmailSent(true);
    } catch (error) {
      console.error("Error sending verification email:", error);
      setError("An error occurred while sending the verification email.");
    } finally {
      setIsProcessing(false);
    }
  };

  const handleBackButton = () => {
    navigate("/admin/settings");
  };

  const handleEditButton = () => {
    setIsEditing(true);
  };

  const handleCancelButton = () => {
    setIsEditing(false);
  };

  const handleSaveButton = async () => {
    for (const qualification of updatedQualifications) {
      if (qualification.value === "") {
        setError("Please enter a value for all qualifications.");
        return;
      }
    }

    setIsProcessing(true);
    try {
      await upsertTherapistQualifications(updatedQualifications);
    } catch (error) {
      console.log(error);
      setError(error.response.data.error);
      setIsProcessing(false);
      return;
    }
    navigate(0);
  };

  const handleQualificationsChange = useCallback((newQualifications) => {
    setUpdatedQualifications(newQualifications);
  }, []);

  return (
    <>
      <Container className="settings-header-container" fluid>
        <Row
          className="d-flex justify-content-between align-items-center px-4"
          style={{ height: "7rem" }}
        >
          <Col xs={8}>
            <h4 style={{ marginBottom: "0px" }}>Profile</h4>
            <p style={{ marginBottom: "0px" }}>
              Manage your qualifications and profile information
            </p>
          </Col>
          <Col
            className="d-flex align-items-center justify-content-end"
            style={{ paddingRight: "0" }}
          >
            <Button
              className="secondary-button pill-button"
              onClick={handleBackButton}
              style={{ width: "100px" }}
            >
              Back
            </Button>
          </Col>
        </Row>
        <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
      </Container>
      <Container className="main-container">
        <Card className="mb-5">
          <Card.Header
            className="d-flex align-items-center justify-content-between"
            style={{ minHeight: "54px" }}
          >
            <Card.Title style={{ marginBottom: "0px" }}>
              Basic Information
            </Card.Title>
            {!therapist.emailVerified &&
              (!verificationEmailSent ? (
                <Button
                  className="secondary-button"
                  onClick={handleVerficiationButton}
                  disabled={isProcessing}
                >
                  {isProcessing && (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                    </>
                  )}
                  Get Verification Email
                </Button>
              ) : (
                <p style={{ marginBottom: "0px" }}>
                  Verification email sent. Please check your inbox.
                </p>
              ))}
          </Card.Header>
          <Card.Body>
            <Row>
              <Col xs={10} style={{ marginLeft: "1rem" }}>
                <h5 style={{ marginBottom: "0px" }}>
                  {therapist.firstName} {therapist.lastName}
                </h5>
                <div className="d-flex align-items-center">
                  <p style={{ marginBottom: "0px" }} className="text-secondary">
                    {therapist.emailAddress}
                  </p>
                  {!therapist.emailVerified && (
                    <Badge style={{ marginLeft: "10px" }} bg="warning">
                      Not Verified
                    </Badge>
                  )}
                  {therapist.emailVerified && (
                    <Badge style={{ marginLeft: "10px" }} bg="success">
                      Verified
                    </Badge>
                  )}
                </div>
                <div className="d-flex align-items-center">
                  <p style={{ marginBottom: "0px" }} className="text-secondary">
                    {formatPhoneNumberIntl(therapist.phoneNumber)}
                    <Badge style={{ marginLeft: "10px" }} bg="success">
                      Verified
                    </Badge>
                  </p>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className="mb-5">
          <Card.Header className="d-flex align-items-center justify-content-between">
            <Card.Title style={{ marginBottom: "0px" }}>
              Qualifications
            </Card.Title>
            {isEditing ? (
              <div>
                <Button
                  className="secondary-button"
                  onClick={handleCancelButton}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </Button>
                <Button className="primary-button" onClick={handleSaveButton}>
                  {isProcessing && (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                    </>
                  )}
                  Save
                </Button>
              </div>
            ) : (
              <Button className="secondary-button" onClick={handleEditButton}>
                Edit
              </Button>
            )}
          </Card.Header>
          <Card.Body>
            {isEditing ? (
              <QualificationsForm
                qualifications={sortedQualifications}
                onChange={handleQualificationsChange}
                error={error}
              />
            ) : (
              <>
                {sortedQualifications.length === 0 ? (
                  <Row className="px-3 mb-3">
                    You have not yet set your qualifications. Please use the
                    "Edit" button to set it up.
                  </Row>
                ) : (
                  <Row className="px-3 flex-column">
                    {sortedQualifications.map((qualification, index) => (
                      <Row key={index} style={{ maxWidth: "500px" }}>
                        <Col xs={4}>
                          <p>{qualification.type}</p>
                        </Col>
                        <Col xs={1}>
                          <p>| </p>
                        </Col>
                        <Col xs={7}>
                          <p>{qualification.value}</p>
                        </Col>
                      </Row>
                    ))}
                  </Row>
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default SetProfilePage;

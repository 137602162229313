import { useLoaderData } from "react-router-dom";
import Calendar from "../../../components/admin/Calendar.js";
import {
  getTherapistDetails,
  getTimeSlots,
  getGroupEventsForCalendar,
} from "../../../services/api.js";

export async function loader({ request }) {
  let therapistAvailability = [];
  let timeSlots;
  let groupEvents;
  let events = [];

  const url = new URL(request.url);
  const queryParams = new URLSearchParams(url.search);
  const startStrQuery = queryParams.get("startStr");

  try {
    const therapist = await getTherapistDetails();
    if (therapist.availability && therapist.availability.length > 0) {
      therapistAvailability = therapist.availability;
    }
    timeSlots = await getTimeSlots();
    groupEvents = await getGroupEventsForCalendar();

    // process timeSlots
    timeSlots.forEach((slot) => {
      if (slot.status === "AVAILABLE" || slot.client) {
        events.push({
          id: slot._id,
          start: slot.startTime,
          end: slot.endTime,
          title: slot.client ? `${slot.client} Consultation` : "",
          backgroundColor:
            slot.status === "AVAILABLE"
              ? "var(--bs-white)"
              : "var(--bs-gray-600)",
          display: "background",
          type: "consultation",
        });
      }
    });

    if (groupEvents.length > 0) {
      events.push(...groupEvents);
    }
  } catch (error) {
    console.log(
      "An error occurred while fetching the time slots and groups for calendar view",
    );
  }

  return { events, therapistAvailability, startStrQuery };
}

const CalendarPage = () => {
  const { events, therapistAvailability, startStrQuery } = useLoaderData();

  return (
    <Calendar
      loaderEvents={events}
      therapistAvailability={therapistAvailability}
      startStrQuery={startStrQuery}
    />
  );
};

export default CalendarPage;

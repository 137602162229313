import { useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import {
  getTherapistDetails,
  addLocation,
  removeLocation,
  initiateZoomOAuth,
  deleteZoomConnection,
  updateLocation,
} from "../../../services/api.js";
import LocationModal from "../../../components/admin/modals/LocationModal.js";
import LocationList from "../../../components/admin/LocationList.js";

export async function loader() {
  let therapist = {};

  try {
    therapist = await getTherapistDetails();
  } catch (error) {
    console.log(
      "An error occurred while fetching the details of the therapist",
    );
  }

  return { therapist };
}

// POST-MVP-TODO: Add logic to check if Zoom is still connected and show a "reconnect" button if needed
// location.address Pending or Not Connected -> shows that zoom is disconnected

const SetLocationsPage = () => {
  const navigate = useNavigate();
  const { therapist } = useLoaderData();
  const [showModal, setShowModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [error, setError] = useState(null);

  const hasZoomConnected = therapist.location.some(
    (loc) =>
      loc.type === "Virtual" &&
      loc.label === "Zoom" &&
      loc.address === "Connected",
  );

  const handleCreateLocationButton = () => {
    setSelectedLocation(null);
    setSelectedAction("Create");
    setShowModal(true);
  };

  const handleDeleteButton = async (location) => {
    if (location.label === "Zoom") {
      await deleteZoomConnection(location._id);
    }

    await removeLocation(location._id);
    navigate(0);
  };

  const handleEditButton = (location) => {
    setSelectedLocation(location);
    setSelectedAction("Edit");
    setShowModal(true);
  };

  const handleBackButton = () => {
    navigate("/admin/settings");
  };

  const handleModalHide = () => {
    setShowModal(false);
    setError(null);
  };

  const handleSaveButton = async (location) => {
    try {
      if (selectedAction === "Create") {
        await addLocation(location.type, location.label, location.address);
      } else if (selectedAction === "Edit") {
        await updateLocation(location._id, location.label, location.address);
      }
      navigate(0);
    } catch (error) {
      console.log(error);
      const errorMessage =
        error.response.data.error || error.response.data.errors[0];
      setError(errorMessage);
    }
  };

  const handleZoomButton = async () => {
    try {
      const response = await initiateZoomOAuth();
      const zoomAuthUrl = response.zoomAuthUrl;

      const type = "Virtual";
      const label = "ZoomInternal"; //Will be converted to Zoom in the backend
      const address = "Pending";
      await addLocation(type, label, address);

      window.location.href = zoomAuthUrl;
    } catch (error) {
      setError(error);
      console.error("Failed to initiate Zoom Ouath: ", error);
    }
  };

  return (
    <Container className="settings-header-container" fluid>
      <Row
        className="d-flex justify-content-between align-items-center px-4"
        style={{ height: "7rem" }}
      >
        <Col xs={8}>
          <h4 style={{ marginBottom: "0px" }}>Locations</h4>
          <p style={{ marginBottom: "0px" }}>
            Locations where you conduct your sessions
          </p>
        </Col>
        <Col
          className="d-flex align-items-center justify-content-end"
          style={{ paddingRight: "0" }}
        >
          <Button
            className="secondary-button pill-button"
            onClick={handleBackButton}
            style={{ width: "100px" }}
          >
            Back
          </Button>
          <Button
            className="primary-button pill-button"
            onClick={handleCreateLocationButton}
            style={{ marginLeft: "10px" }}
          >
            <span className="d-none d-lg-inline">Create Location</span>
            <span className="d-inline d-lg-none">Create</span>
          </Button>
        </Col>
      </Row>
      <hr style={{ marginTop: "0px" }} />
      <Container className="main-container">
        <LocationList
          locations={therapist.location}
          onEdit={handleEditButton}
          onDelete={handleDeleteButton}
        />
      </Container>
      <LocationModal
        show={showModal}
        location={selectedLocation}
        onHide={handleModalHide}
        onSave={handleSaveButton}
        action={selectedAction}
        {...(!hasZoomConnected && { onConnectZoom: handleZoomButton })}
        error={error}
      />
    </Container>
  );
};

export default SetLocationsPage;

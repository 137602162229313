import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Dropdown,
  DropdownButton,
  Alert,
  Button,
  Form,
  Container,
} from "react-bootstrap";
import { DashCircle } from "react-bootstrap-icons";

const QualificationsForm = ({ qualifications, onChange, error }) => {
  const getPlaceholder = (type) => {
    switch (type) {
      case "Title":
        return "E.g. Counselor, Psychotherapist";
      case "Education":
        return "E.g. MEd, MA, MBA";
      case "License":
        return "E.g. LHMC, LSW";
      default:
        return "";
    }
  };

  const [qualificationsArray, setQualificationsArray] = useState(() => {
    if (!qualifications || qualifications.length === 0) {
      return [
        {
          type: "Title",
          value: "",
          placeholder: "E.g. Psychotherapist",
        },
      ];
    }
    return qualifications.map((q) => ({
      ...q,
      placeholder: getPlaceholder(q.type),
    }));
  });

  useEffect(() => {
    const filteredQualifications = qualificationsArray.map(
      ({ type, value }) => ({ type, value }),
    );
    onChange(filteredQualifications);
  }, [qualificationsArray, onChange]);

  const handleTypeChange = (eventKey, index) => {
    const updatedQualifications = [...qualificationsArray];
    updatedQualifications[index].type = eventKey;
    updatedQualifications[index].placeholder = getPlaceholder(eventKey);
    setQualificationsArray(updatedQualifications);
  };

  const createDefaultQualification = () => ({
    type: "Title",
    value: "",
    placeholder: "E.g. Psychotherapist",
  });

  const setQualificationValue = (value, index) => {
    const updatedQualifications = [...qualificationsArray];
    updatedQualifications[index].value = value;
    setQualificationsArray(updatedQualifications);
  };

  const handleAddQualificationButton = () => {
    setQualificationsArray([
      ...qualificationsArray,
      createDefaultQualification(),
    ]);
  };

  const handleRemoveQualificationButton = (index) => {
    setQualificationsArray(
      qualificationsArray.filter((_, idx) => idx !== index),
    );
  };

  return (
    <>
      <Container className="main-container" style={{ marginTop: "0px" }}>
        <Row className="flex-column">
          {qualificationsArray.length === 0 ? (
            <Row className="mb-3">
              You have not yet set your qualifications. Please use the "Add
              qualification" button to set it up.
            </Row>
          ) : (
            <>
              {qualificationsArray.map((qualification, index) => (
                <React.Fragment key={index}>
                  <Row className="align-items-center mb-3">
                    <Col xs={4}>
                      <DropdownButton
                        variant="outline-secondary"
                        id={`type-dropdown-${index}`}
                        title={qualification.type || "Select Type"}
                        onSelect={(eventKey) =>
                          handleTypeChange(eventKey, index)
                        }
                      >
                        <Dropdown.Item eventKey="Title">Title</Dropdown.Item>
                        <Dropdown.Item eventKey="License">
                          License
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="Education">
                          Education
                        </Dropdown.Item>
                      </DropdownButton>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        style={{ width: "100%" }}
                        value={qualification.value}
                        type="text"
                        placeholder={qualification.placeholder}
                        onChange={(e) =>
                          setQualificationValue(e.target.value, index)
                        }
                      />
                    </Col>
                    <Col xs={4}>
                      {qualificationsArray.length >= 1 && (
                        <DashCircle
                          size={24}
                          className="remove-icon"
                          onClick={() => handleRemoveQualificationButton(index)}
                        />
                      )}
                    </Col>
                  </Row>
                </React.Fragment>
              ))}
            </>
          )}
          <hr />
          {error && (
            <Alert className="mt-3" variant="danger">
              {error}
            </Alert>
          )}
          <Row style={{ maxWidth: "200px" }}>
            <Button
              variant="link"
              className="add-slot-btn"
              onClick={handleAddQualificationButton}
            >
              Add {qualificationsArray.length !== 0 && "another"} qualification
            </Button>
          </Row>
        </Row>
      </Container>
    </>
  );
};

export default QualificationsForm;

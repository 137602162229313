import React from "react";
import { Badge } from "react-bootstrap";

const StatusLabel = ({ status }) => {
  let statusClass = "";
  switch (status) {
    case "DRAFT":
      statusClass = "danger";
      break;
    case "OPEN_FOR_ENROLLMENT":
      statusClass = "warning";
      status = "OPEN FOR ENROLLMENT";
      break;
    case "ENROLLMENT_COMPLETE":
      statusClass = "success";
      status = "ENROLLMENT COMPLETE";
      break;
    case "IN_PROGRESS":
      statusClass = "success";
      status = "IN PROGRESS";
      break;
    case "COMPLETED":
      statusClass = "success";
      break;
    case "APPROVED":
      statusClass = "success";
      break;
    case "REJECTED":
      statusClass = "danger";
      break;
    case "PENDING":
      statusClass = "warning";
      break;
    case "AWAITING_THERAPIST_RESPONSE":
      statusClass = "dark";
      status = "AWAITING YOUR RESPONSE";
      break;
    case "AWAITING_CONSULTATION_RESPONSE":
      statusClass = "dark";
      status = "AWAITING YOUR RESPONSE";
      break;
    case "AWAITING_THERAPIST_DECISION":
      statusClass = "secondary";
      status = "AWAITING YOUR DECISION";
      break;
    case "AWAITING_CLIENT_RESPONSE":
      status = "AWAITING CLIENT RESPONSE";
      statusClass = "warning";
      break;
    case "CONSULTATION_SCHEDULED":
      status = "CONSULTATION SCHEDULED";
      statusClass = "success";
      break;
    case "AWAITING_CLIENT_DECISION":
      status = "AWAITING CLIENT DECISION";
      statusClass = "info";
      break;
    case "In-Person":
      status = "IN PERSON";
      statusClass = "info";
      break;
    case "Virtual":
      status = "VIRTUAL";
      statusClass = "info";
      break;
    default:
      statusClass = "white";
      status = "N/A";
  }

  return (
    <Badge
      bg={`${statusClass}`}
      className="ms-auto"
      style={{
        marginTop: "auto",
        marginBottom: "auto",
        height: "20px",
      }}
    >
      {status}
    </Badge>
  );
};

export default StatusLabel;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { verifyEmail } from "../../services/api";

export async function loader({ request }) {
  const url = new URL(request.url);

  const searchParams = url.searchParams;
  const token = searchParams.get("token");

  try {
    await verifyEmail(token);
  } catch {
    console.log("An error occurred while completing zoom oauth flow");
  }

  return null;
}

const VerifyEmailPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/admin/settings/profile");
  }, [navigate]);

  return null;
};

export default VerifyEmailPage;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Modal,
  Button,
  Form,
  Spinner,
  Alert,
  Card,
  Row,
  Col,
} from "react-bootstrap";
import CheckboxIcon from "../../assets/svg/CONSENT.svg";
import LongTextIcon from "../../assets/svg/LONG_TEXT.svg";
import MultiChoiceIcon from "../../assets/svg/MULTI_CHOICE.svg";
import NumberIcon from "../../assets/svg/NUMBER.svg";
import ShortTextIcon from "../../assets/svg/SHORT_TEXT.svg";
import SingleChoiceIcon from "../../assets/svg/SINGLE_CHOICE.svg";
import YesNoIcon from "../../assets/svg/YES_NO.svg";
import { DashCircle } from "react-bootstrap-icons";
import { saveQuestion } from "../../services/api";

const QuestionModal = ({ show, onHide, question = null }) => {
  const navigate = useNavigate();
  // 2 modal steps: TYPE_SELECTION & INFORMATION_COLLECTION

  const isEditing = question !== null;
  const [modalStep, setModalStep] = useState(
    isEditing ? "INFORMATION_COLLECTION" : "TYPE_SELECTION",
  );

  const questionTypes = [
    "LONG_TEXT",
    "SHORT_TEXT",
    "NUMBER",
    "YES_NO",
    "SINGLE_CHOICE",
    "MULTI_CHOICE",
    "CONSENT",
  ];

  const typeToIcon = {
    CONSENT: CheckboxIcon,
    LONG_TEXT: LongTextIcon,
    MULTI_CHOICE: MultiChoiceIcon,
    NUMBER: NumberIcon,
    SHORT_TEXT: ShortTextIcon,
    SINGLE_CHOICE: SingleChoiceIcon,
    YES_NO: YesNoIcon,
  };

  const needOptions = (type) => {
    return ["SINGLE_CHOICE", "MULTI_CHOICE"].includes(type);
  };

  const [questionType, setQuestionType] = useState(
    isEditing ? question.type : "",
  );
  const [questionText, setQuestionText] = useState(
    isEditing ? question.text : "",
  );
  const [questionOptions, setQuestionOptions] = useState(
    isEditing ? question.options : ["Option 1"],
  );
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState("");

  const handleBackButton = (step) => () => {
    setError("");
    setModalStep(step);
  };

  const handleTypeSelect = (type) => {
    setQuestionType(type);
    setModalStep("INFORMATION_COLLECTION");
  };

  const handleAddOptionButton = () => {
    if (questionOptions.some((option) => option.trim() === "")) {
      setError("Options cannot be blank.");
      return;
    }

    const uniqueOptions = new Set(
      questionOptions.map((option) => option.trim()),
    );
    if (uniqueOptions.size !== questionOptions.length) {
      setError("Duplicate options are not allowed.");
      return;
    }

    setError("");
    setQuestionOptions([
      ...questionOptions,
      `Option ${questionOptions.length + 1}`,
    ]);
  };

  const handleRemoveOptionButton = (index) => {
    setError("");
    setQuestionOptions(questionOptions.filter((_, idx) => idx !== index));
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = questionOptions.map((option, idx) =>
      idx === index ? value : option,
    );
    setQuestionOptions(updatedOptions);
  };

  const isValid = () => {
    if (questionText === "") {
      setError("Please provide a question text");
      return false;
    }

    if (needOptions(questionType)) {
      if (questionOptions.some((option) => option.trim() === "")) {
        setError("Options cannot be blank.");
        return false;
      }

      const uniqueOptions = new Set(
        questionOptions.map((option) => option.trim()),
      );
      if (uniqueOptions.size !== questionOptions.length) {
        setError("Duplicate options are not allowed.");
        return false;
      }

      if (questionOptions.length < 2) {
        setError("Please provide at least 2 options");
        return false;
      }
    }

    setError("");
    return true;
  };

  const handleSaveButton = async () => {
    if (!isValid()) {
      return;
    }
    setIsProcessing(true);
    await saveQuestion(
      questionType,
      questionText,
      needOptions(questionType) ? questionOptions : [],
      ...(isEditing ? [question._id] : []),
    );
    setIsProcessing(false);
    navigate(0);
  };

  const renderTypeCard = (type) => (
    <Col xs={12} sm={6} lg={3} className="mb-3" key={type}>
      <Card
        style={{ cursor: "pointer" }}
        onClick={() => handleTypeSelect(type)}
      >
        <Card.Body className="d-flex flex-column">
          <Card.Subtitle className="mb-3">
            {type === "YES_NO" ? "YES/NO" : type.replace(/_/g, " ")}
          </Card.Subtitle>
          <img
            src={typeToIcon[type]}
            alt={`${type} icon`}
            className="mb-2 question-type-svg-icon"
          />
        </Card.Body>
      </Card>
    </Col>
  );

  return (
    <Modal size="lg" show={show} onHide={onHide} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>
          {isEditing ? "Editing a " : "Creating a "}{" "}
          {questionType === ""
            ? "new"
            : questionType === "YES_NO"
              ? "Yes / No"
              : questionType.replace(/_/g, " ").toLowerCase()}{" "}
          question
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {modalStep === "TYPE_SELECTION" && (
          <Row>{questionTypes.map(renderTypeCard)}</Row>
        )}
        {modalStep === "INFORMATION_COLLECTION" && (
          <>
            <Form.Label>Question Text</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              rows={5}
              placeholder="Type your question here..."
              value={questionText}
              onChange={(e) => setQuestionText(e.target.value)}
            />
            {needOptions(questionType) && (
              <>
                <Form.Label className="mt-4">Question Options</Form.Label>
                {questionOptions.map((option, index) => (
                  <React.Fragment key={index}>
                    <Row
                      className="align-items-center mt-2"
                      style={{ maxWidth: "500px" }}
                    >
                      <Col xs={10}>
                        <Form.Control
                          type="text"
                          placeholder="Enter option"
                          value={option}
                          onChange={(e) =>
                            handleOptionChange(index, e.target.value)
                          }
                        />
                      </Col>
                      <Col xs={1}>
                        {questionOptions.length > 1 && (
                          <DashCircle
                            size={24}
                            className="remove-icon"
                            onClick={() => handleRemoveOptionButton(index)}
                          />
                        )}
                      </Col>
                    </Row>
                  </React.Fragment>
                ))}
                <Row style={{ maxWidth: "141px" }} className="mt-3">
                  <Button
                    variant="link"
                    className="add-slot-btn"
                    onClick={handleAddOptionButton}
                  >
                    Add another option
                  </Button>
                </Row>
              </>
            )}
          </>
        )}
        {error && (
          <Alert className="mt-2" variant="danger">
            {error}
          </Alert>
        )}
      </Modal.Body>
      {modalStep !== "TYPE_SELECTION" && (
        <Modal.Footer>
          <Button
            variant="outline-dark"
            onClick={handleBackButton("TYPE_SELECTION")}
          >
            Change Question Type
          </Button>
          <Button variant="dark" onClick={() => handleSaveButton()}>
            {isProcessing ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                {" Saving"}
              </>
            ) : (
              "Save"
            )}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default QuestionModal;
